import { makeObservable, observable, runInAction } from "mobx"
import { LoginState } from "./LoginState"
import { SignupState } from "./SignupState"
import { ResetState } from "./ResetState"
import { MainState } from "./MainState"
import { ChatState } from "./ChatState"
import { ImageState } from "./ImageState"
import { VoiceChatState } from "./VoiceChatState"
import { CheckpointState } from "./CheckpointState"
import Cookie from "js-cookie"
import { ACCESS } from "../Client"
import "../proto/chat_grpc_web_pb"
import grpcWeb from "grpc-web"
import { CheckState } from "./CheckState"

interface Metadata {
    account_id: number,
    session_token: string,
    session_timestamp: number,
}

function account2creator(account: proto.abaoai.AccountPayload): proto.abaoai.CreatorPayload {
    let creator: proto.abaoai.CreatorPayload = new proto.abaoai.CreatorPayload();
    creator.setAccountId(account.getAccountId())
    creator.setEmail(account.getEmail())
    creator.setName(account.getName())
    creator.setCountry(account.getCountry())
    return creator
}

class AppState {
    page: string = ""
    metadata: Metadata = { account_id: 0, session_token: "", session_timestamp: 0 }
    login: LoginState
    signup: SignupState
    reset: ResetState
    main: MainState
    chat: ChatState
    image: ImageState
    voice_chat: VoiceChatState
    checkpoint: CheckpointState
    check: CheckState
    is_mobile: boolean = false
    error_field: string = ""
    error_message: string = ""

    constructor() {
        makeObservable(this, {
            page: observable,
            login: observable,
            signup: observable,
            is_mobile: observable,
            error_field: observable,
            error_message: observable,
        })
        this.page = "MAIN"
        this.login = new LoginState()
        this.signup = new SignupState()
        this.reset = new ResetState()
        this.main = new MainState()
        this.chat = new ChatState()
        this.image = new ImageState()
        this.voice_chat = new VoiceChatState()
        this.checkpoint = new CheckpointState()
        this.check = new CheckState()
        let account_id = Cookie.get("account_id")
        this.metadata.account_id = account_id ? +account_id : 0
        let session_token = Cookie.get("session_token")
        this.metadata.session_token = session_token ? session_token : ""
        let session_timestamp = Cookie.get("session_timestamp")
        this.metadata.session_timestamp = session_timestamp ? +session_timestamp : 0
    }

    onClickPage(page: string) {
        runInAction(() => {
            this.page = page
            this.login = new LoginState()
            this.signup = new SignupState()
            this.reset = new ResetState()
            this.main = new MainState()
            this.error_field = ""
            this.error_message = ""
        })
    }

    clearError() {
        runInAction(() => {
            this.error_field = ""
            this.error_message = ""
        })
    }

    getTimestamp() {
        return Date.now() / 1000 | 0;
    }

    getMetadataMap() {
        let res: {
            [x: string]: string;
        } = {}
        let metadata = this.getMetadata()
        res["account_id"] = metadata.account_id.toString()
        res["session_token"] = metadata.session_token
        res["session_timestamp"] = this.getTimestamp().toString()
        return res
    }

    getMetadata(): Metadata {
        let metadata: Metadata = { account_id: 0, session_token: "", session_timestamp: 0 }
        let account_id = Cookie.get("account_id")
        metadata.account_id = account_id ? +account_id : 0
        let session_token = Cookie.get("session_token")
        metadata.session_token = session_token ? session_token : ""
        let session_timestamp = Cookie.get("session_timestamp")
        metadata.session_timestamp = session_timestamp ? +session_timestamp : 0
        return metadata
    }

    setMetadata(metadata: Metadata) {
        this.metadata = metadata
        Cookie.set("account_id", metadata.account_id.toString(), { domain: window.location.hostname })
        Cookie.set("session_token", metadata.session_token, { domain: window.location.hostname })
        Cookie.set("session_timestamp", metadata.session_timestamp.toString(), { domain: window.location.hostname })
    }

    async onPageLoad() {
        try {
            let req = new proto.abaoai.AccountPayload()
            req.setAccountId(this.getMetadata().account_id);
            runInAction(() => {
                this.page = "MAIN"
            })
            let account: proto.abaoai.AccountPayload = await ACCESS.getAccount(req, this.getMetadataMap());
            let creator = account2creator(account)
            let latest = new proto.abaoai.CreatorPayload()
            latest.setName("Latest")
            this.image.creators[0] = latest
            this.image.creators[creator.getAccountId()] = creator;
            this.main.onAccountLoad(account)
            this.main.loadHistory()
            this.chat.loadModels()
            this.image.load_models()
            this.image.list_more_images(true)
            this.image.listen_images(account.getAccountId())
            this.checkpoint.loadModels()
            this.check.loadChecks()
            if (!this.is_mobile) {
                runInAction(() => {
                    this.main.left_drawer_is_open = true
                })
            }
        } catch (e) {
            this.onError(e as grpcWeb.RpcError)
        }
    }

    onError(e: grpcWeb.RpcError) {
        runInAction(() => {
            this.error_field = ""
            this.error_message = ""
            switch (e.code) {
                case grpcWeb.StatusCode.UNAUTHENTICATED:
                    this.page = "AUTH"
                    break;
                case grpcWeb.StatusCode.INVALID_ARGUMENT:
                    let parts = e.message.split(':')
                    if (parts.length === 2) {
                        this.error_field = parts[0]
                        this.error_message = parts[1]
                    } else {
                        this.error_field = e.message
                        this.error_message = "Invalid Argument"
                    }
                    break;
                case grpcWeb.StatusCode.ALREADY_EXISTS:
                    this.error_field = e.message
                    this.error_message = "Already Exists"
                    break;
                case grpcWeb.StatusCode.NOT_FOUND:
                    this.error_field = e.message
                    this.error_message = "Not Found"
                    break;
                case grpcWeb.StatusCode.RESOURCE_EXHAUSTED:
                    this.main.view = "check"
                    break;
                case grpcWeb.StatusCode.DEADLINE_EXCEEDED:
                    console.log(e.message)
                    break;
                case grpcWeb.StatusCode.UNAVAILABLE:
                    console.log(e.message)
                    break;
                default:
                    alert(e.message)
            }
        })
        console.log(e)
    }
}

export { AppState }