import { computed, makeObservable, observable, runInAction, } from "mobx"
import { S } from "../State"
import { CHAT, ACCESS } from "../Client"
import grpcWeb from "grpc-web"
import { Checkpoint } from "../data/Checkpoint"


class CheckpointState {
    right_title: string = ""
    models: Record<string, proto.abaoai.ModelPayload> = {}
    checkpoints: Checkpoint[] = []
    selected_model_id: string = ""
    selected_sort: string = "DOWNLOAD"
    selected_period: string = "ALL_TIME"
    query: string = ""
    start: number = 0
    total: number = 0
    selected_checkpoint_id: number = 0
    selected_version_id: number = 0

    sort_options = [
        { key: "", text: "-" },
        { key: "DOWNLOAD", text: "Download" },
        { key: "RATING", text: "Rating" },
        { key: "FAVORITE", text: "Favorite" },
        { key: "TIPPED_AMOUNT", text: "Tipped Amount" },
        { key: "TIMESTAMP", text: "Timestamp" },
    ]

    period_options = [
        { key: "", text: "-" },
        { key: "ALL_TIME", text: "All Time" },
        { key: "YEAR", text: "Year" },
        { key: "MONTH", text: "Month" },
        { key: "WEEK", text: "Week" },
        { key: "DAY", text: "Day" },
    ]

    constructor() {
        makeObservable(this, {
            right_title: observable,
            models: observable,
            selected_model_id: observable,
            selected_period: observable,
            selected_sort: observable,
            query: observable,
            checkpoints: observable,
            start: observable,
            total: observable,
            has_more: computed,
            selected_checkpoint_id: observable,
            selected_checkpoint: computed,
            selected_version_id: observable,
            loader: computed,
            end: computed,
        })
    }

    get selected_checkpoint() {
        let checkpoint = this.checkpoints.find((checkpoint) => checkpoint.checkpoint_id === this.selected_checkpoint_id)!
        return checkpoint
    }

    get has_more() {
        return this.start === 0 || this.start < this.total
    }

    get loader() {
        return "Loading..." + this.start + "/" + this.total
    }

    get end() {
        return "End..." + this.start + "/" + this.total
    }

    get model_options() {
        let res = [{ key: "", text: "-" }]
        for (let model_id in this.models) {
            let model = this.models[model_id]
            if (model.getFunctionId() < 4) {
                continue
            }
            let option = { key: model_id, text: model.getModelName() }
            res.push(option)
        }
        return res
    }

    async loadModels() {
        let req = new proto.abaoai.ModelsPayload()
        let res: proto.abaoai.ModelsPayload = await ACCESS.getImageModels(req, S.getMetadataMap());
        runInAction(() => {
            for (let model of res.getModelsList()) {
                this.models[model.getModelId()] = model;
            }
        })
    }

    onChangeModel(model_id: string) {
        runInAction(() => {
            this.selected_model_id = model_id
        })
    }

    onChangeSort(value: string) {
        runInAction(() => {
            this.selected_sort = value
        })
    }

    onChangePeriod(value: string) {
        runInAction(() => {
            this.selected_period = value
        })
    }

    onChangeQuery(value: string) {
        runInAction(() => {
            this.query = value
        })
    }

    onClickInfo(checkpoint_id: number) {
        console.log(checkpoint_id)
        runInAction(() => {
            this.selected_checkpoint_id = checkpoint_id
            S.main.right_drawer_is_open = true
            this.right_title = "Description"
        })
    }

    async onClickSearchButton() {
        runInAction(() => {
            this.selected_checkpoint_id = 0
            this.selected_version_id = 0
            if (S.is_mobile) {
                S.main.right_drawer_is_open = false
            }
        })
        await this.fetchMoreCheckpoints(true)
    }


    async fetchMoreCheckpoints(empty: boolean) {
        if (empty) {
            runInAction(() => {
                this.checkpoints = []
                this.start = 0;
                this.total = 0;
            })
            window.document.getElementById("checkpoints_slider")?.scrollTo({ top: 0, behavior: 'smooth' })
        }
        let req = new proto.abaoai.SearchCheckpointsRequest()
        req.setStart(this.start)
        req.setBaseModelId(this.selected_model_id)
        req.setPeriod(this.selected_period)
        req.setSort(this.selected_sort)
        req.setQuery(this.query)
        req.setNsfw(S.main.nsfw)
        console.log(req.toObject())
        try {
            let res: proto.abaoai.SearchCheckpointsResponse = await CHAT.searchCheckpoints(req, S.getMetadataMap())
            runInAction(() => {
                this.start = res.getNextStart()
                this.total = res.getTotal()
                if (empty) {
                    this.checkpoints = []
                }
                for (let checkpoint of res.getCheckpointsList()) {
                    this.checkpoints.push(new Checkpoint(checkpoint))
                }
            })
        } catch (e) {
            S.onError(e as grpcWeb.RpcError)
        }
    }

}

export { CheckpointState }