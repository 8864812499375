import { observer } from "mobx-react-lite"
import { Select, Field } from "@fluentui/react-components"
import { S } from "../../State"
import { Dalle3Config } from "./config/Dalle3Config"
import { SDXLConfig } from "./config/SDXLConfig"
import { CivitaiConfig } from "./config/CivitaiConfig"
import { SD3Config } from "./config/SD3Config"

const ImageConfig = observer(() =>
    <>
        <div className="model_row">
            <Field
                className="model"
                label="Model"
                required
                validationMessage={S.image.model_id_error_message}
            >
                <Select
                    value={S.image.selected_model_id}
                    onChange={(_, { value }) => S.image.onChangeModel(value!)}
                >
                    {S.image.model_options.map((option) => (
                        <option key={option.key} value={option.key} >
                            {option.text}
                        </option>
                    ))}
                </Select>
            </Field>
        </div>
        {
            S.image.selected_model_id === "openai/dall-e-3" ? <Dalle3Config /> : null
        }
        {
            S.image.selected_model_id === "stabilityai/stable-diffusion-xl-1024-v1-0" ? <SDXLConfig /> : null
        }
        {
            S.image.selected_model_id === "stabilityai/sd3" ? <SD3Config /> : null
        }
        {
            S.image.selected_model_id === "runwayml/stable-diffusion-v1-5" || S.image.selected_model_id === "stabilityai/stable-diffusion-xl-base-1.0" ? <CivitaiConfig /> : null
        }
    </>
)

export { ImageConfig }