import { observer } from "mobx-react-lite"
import {
    Field,
    Input,
    Button,
} from "@fluentui/react-components"
import { S } from "../../State"
import {
    SendRegular,
} from "@fluentui/react-icons";

const CheckPanel = observer(() =>
    <div className="check_panel">
        <div className="drawer_name_row">
            <Field
                className="drawer_name"
                label="Drawer name"
                required={true}
                validationMessage={S.check.drawer_name_error_message}
            >
                <Input
                    value={S.check.drawer_name}
                    onChange={(_, { value }) => S.check.onChangeDrawerName(value!)}
                />
            </Field>
        </div>

        <div className="drawer_email_row">
            <Field
                className="drawer_email"
                label="Drawer email"
                required={true}
                validationMessage={S.check.drawer_email_error_message}
            >
                <Input
                    type="email"
                    value={S.check.drawer_email}
                    onChange={(_, { value }) => S.check.onChangeDrawerEmail(value!)}
                />
            </Field>
        </div>

        <div className="security_code_row">
            <Field
                className="code"
                label="Security code"
                required={true}
                validationMessage={S.check.code_error_message}
            >
                <Input
                    type="number"
                    onChange={(_, { value }) => S.check.onChangeCode(value)}
                    required={true}
                    contentAfter={
                        S.check.email_countdown === 0 ?
                            <Button
                                icon={<SendRegular />}
                                appearance="transparent"
                                onClick={() => S.check.onClickSendCode()}
                            />
                            :
                            S.check.email_countdown + "s"
                    }
                />
            </Field>
        </div>

        <div className="recipient_name_row">
            <Field
                className="recipient_name"
                label="Recipient name"
                required={true}
                validationMessage={S.check.recipient_name_error_message}
            >
                <Input
                    value={S.check.recipient_name}
                    onChange={(_, { value }) => S.check.onChangeRecipientrName(value!)}
                />
            </Field>
        </div>

        <div className="recipient_email_row">
            <Field
                className="recipient_email"
                label="Recipient email"
                required={true}
                validationMessage={S.check.recipient_email_error_message}
            >
                <Input
                    type="email"
                    value={S.check.recipient_email}
                    onChange={(_, { value }) => S.check.onChangeRecipientEmail(value!)}
                />
            </Field>
        </div>

        <div className="amount_row">
            <Field
                className="amount"
                label="Amount"
                required={true}
                validationMessage={S.check.amount_error_message}
            >
                <Input
                    type="number"
                    onChange={(_, { value }) => S.check.onChangeAmount(parseInt(value!) || 0)}
                />
            </Field>
        </div>
        <div className="description_row">
            <Field
                className="description"
                label="Description"
                required={true}
                validationMessage={S.check.description_error_message}
            >
                <Input
                    value={S.check.description}
                    onChange={(_, { value }) => S.check.onChangeDescirption(value!)}
                />
            </Field>
        </div>

        <div className="blank_row"></div>

        <div className="check_action_row">
            <Button
                appearance="primary"
                className="pay_button"
                onClick={() => S.check.onClickPay()}
            >
                Pay
            </Button>
        </div>
    </div>
)

export { CheckPanel }