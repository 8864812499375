import { observer } from "mobx-react-lite"
import { S } from "../../../State"
import { Textarea, Field, Select } from "@fluentui/react-components"

const Dalle3Config = observer(() =>
    <>
        <div className="quality_row">
            <Field
                className="quality"
                label="Quality"
                required
                validationMessage={S.image.quality_error_message}
            >
                <Select
                    value={S.image.selected_quality}
                    onChange={(_, { value }) => S.image.onChangeQuality(value)}
                >
                    {S.image.quality_options.map((option) => (
                        <option key={option.key} value={option.key} >
                            {option.text}
                        </option>
                    ))}
                </Select>
            </Field>
        </div >
        <div className="size_row">
            <Field
                className="size"
                label="Size"
                required
                validationMessage={S.image.size_error_message}
            >
                <Select
                    value={S.image.selected_size}
                    onChange={(_, { value }) => S.image.onChangeSize(value)}
                >
                    {S.image.size_options.map((option) => (
                        <option key={option.key} value={option.key}>
                            {option.text}
                        </option>
                    ))}
                </Select>
            </Field>
        </div>
        <div className="style_row">
            <Field
                className="style"
                label="Style"
                required
                validationMessage={S.image.style_error_message}
            >
                <Select
                    value={S.image.selected_style}
                    onChange={(_, { value }) => S.image.onChangeStyle(value)}
                >
                    {S.image.style_options.map((option) => (
                        <option key={option.key} value={option.key}>
                            {option.text}
                        </option>
                    ))}
                </Select>
            </Field>
        </div>
        <div className="prompt_row">
            <Field
                className="prompt"
                label="Prompt"
                required
                validationMessage={S.image.prompt_error_message}
            >
                <Textarea
                    className="prompt"
                    value={S.image.prompt}
                    onChange={(_, { value }) => S.image.onChangePrompt(value!)}
                    rows={12}
                    required
                >
                </Textarea>
            </Field>
        </div>
        {
            S.image.revised_prompt !== "" ?
                <div className="revised_prompt_row">
                    <Field
                        className="revised_prompt"
                        label="Revised Prompt"
                    >
                        <Textarea
                            readOnly
                            className="revised_prompt"
                            value={S.image.revised_prompt}
                            rows={12}
                        >
                        </Textarea>
                    </Field>
                </div>
                :
                null
        }
    </>
)
export { Dalle3Config }