import { observer } from "mobx-react-lite"
import { TopBar } from "../TopBar"
import { S } from "../../State"
import ImageGallery from "react-image-gallery";
import { GalleryItem } from "../../data/GalleryItem"
import {
    Card,
    CardHeader,
    Spinner,
    CardPreview,
    Subtitle2Stronger,
    Button,
    Link,
    Avatar,
} from "@fluentui/react-components";
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    ArrowDownloadRegular,
} from "@fluentui/react-icons";

function items(image: proto.abaoai.ImagePayload): GalleryItem[] {
    if (image.getUrl()) {
        let res: GalleryItem[] = [{ original: image.getUrl() }]
        return res
    } else {
        let res: GalleryItem[] = [{ original: "https://placehold.co/" + image.getSize() }]
        return res
    }
}


const ImageView = observer(() =>
    <div className="main_image">
        <TopBar />
        <div id="images_gallery_slider" className="images_row">
            <InfiniteScroll
                scrollableTarget="images_gallery_slider"
                dataLength={Object.keys(S.image.images).length}
                next={() => S.image.fetchMoreImages(false)}
                hasMore={S.image.has_more}
                loader={<h5>{S.image.loader}</h5>}
                endMessage={<h5>{S.image.end}</h5>}
            >
                <div className="images_gallery">
                    {
                        S.image.images.map((image) =>
                            <div
                                key={image.getImageId()}
                                className="image_frame"
                            >
                                <Card
                                    appearance="filled-alternative"
                                    className="image_card"
                                    key={image.getImageId()}
                                >
                                    <CardHeader className="image_header"
                                        image={
                                            image.getAccountId() ?
                                                <Link
                                                    onClick={() => S.main.onClickCreator(S.image.creators[image.getAccountId()])}
                                                >
                                                    <Avatar
                                                    // name={S.image.creators[image.getAccountId()].getName()}
                                                    />
                                                </Link>
                                                :
                                                null
                                        }
                                        header={
                                            <div className="header">
                                                {image.getImageId() + "." + image.getExtension$()}
                                            </div>
                                        }
                                        description={
                                            new Date(image.getTimestamp() * 1000).toLocaleString()
                                        }
                                        action={
                                            <Button
                                                appearance="transparent"
                                                icon={<ArrowDownloadRegular />}
                                                onClick={() => S.image.onClickDownload(image.getUrl())}
                                            />
                                        }
                                    />
                                    <CardPreview className="image_preview">
                                        <ImageGallery
                                            showPlayButton={false}
                                            items={items(image)}
                                            useBrowserFullscreen={false}
                                        />
                                        {
                                            <Link
                                                className="overlay"
                                                onClick={() => S.image.onClickImage(image)}
                                            >
                                                {
                                                    image.getStatus() === 0 ?
                                                        <div className="overlay" >
                                                            <Spinner size="huge" />
                                                        </div>
                                                        :
                                                        null
                                                }
                                                {
                                                    image.getStatus() === 400 ?
                                                        <Subtitle2Stronger>
                                                            {image.getMessage()}
                                                        </Subtitle2Stronger>
                                                        :
                                                        null
                                                }
                                            </Link>
                                        }
                                    </CardPreview>
                                </Card>
                            </div>
                        )
                    }
                </div>
            </InfiniteScroll>
        </div>
    </div >
)

export { ImageView }


