import { observer } from "mobx-react-lite"
import { Link, Input, Button, Field } from "@fluentui/react-components"
import "../proto/chat_pb"
import { S } from "../State"

const LoginPage = observer(() =>
    <div className="login_page">
        <div className="hero">
            <h1 className="title">abao.ai</h1>
            <h1 className="title">{S.login.title}</h1>
            <Field
                className="email_textfield"
                label="Email"
                validationMessage={S.login.email_error_message}
                required
            >
                <Input
                    type="email"
                    autoComplete="username"
                    onChange={(_, { value }) => S.login.onChangeEmail(value)}
                />
            </Field>
            <Field
                className="password_textfield"
                label="Password"
                validationMessage={S.login.password_error_message}
                required
            >
                <Input
                    type="password"
                    autoComplete="current-password"
                    onChange={(_, { value }) => S.login.onChangePassword(value)}
                />
            </Field>

            <div className="reset_row">
                <Link className="reset_link" onClick={() => S.onClickPage("RESET")}>Forgot password?</Link>
            </div>

            <div className="continue_row">
                <Button
                    className="continue_button"
                    appearance="primary"
                    onClick={() => S.login.onClickContinue()}
                >
                    Continue
                </Button>
            </div>
            <div className="signup_row">
                <div className="question">Don't have an account?</div>
                <Link className="signup_link" onClick={() => S.onClickPage("SIGNUP")}>Sign up</Link>
            </div>
        </div>
    </div >
)


export { LoginPage };
