import { observer } from "mobx-react-lite"
import { S } from "../../State"
import { CheckpointSearchConfig } from "./CheckpointSearchConfig";
import { CheckpointDescription } from "./CheckpointDescription";

const CheckpointPanel = observer(() =>
    <div className="checkpoint_panel">
        {
            S.checkpoint.selected_checkpoint_id === 0 ? <CheckpointSearchConfig /> : <CheckpointDescription />
        }
    </div>
)

export { CheckpointPanel }