import { observer } from "mobx-react-lite"
import { S } from "../../../State"
import {
    Textarea, Field, Select,
} from "@fluentui/react-components"

const CivitaiConfig = observer(() =>
    <>
        <div className="checkpoint_row">
            <Field
                className="checkpoint"
                label="Checkpoint"
                required
                validationMessage={S.image.checkpoint_id_error_message}
            >
                <Select
                    className="checkpoint_select"
                    value={S.image.selected_checkpoint_id}
                    onChange={(_, { value }) => S.image.onChangeCheckpoint(parseInt(value) || 0)}
                >
                    {S.image.checkpoint_options.map((option) => (
                        <option key={String(option.getCheckpointId())} value={String(option.getCheckpointId())} >
                            {option.getCheckpointName()}
                        </option>
                    ))}
                </Select>
            </Field>
        </div >

        <div className="version_row">
            <Field
                className="version"
                label="Version"
                required
                validationMessage={S.image.version_id_error_message}
            >
                <Select
                    className="version_select"
                    value={S.image.selected_version_id}
                    onChange={(_, { value }) => S.image.onChangeVersion(parseInt(value) || 0)}
                >
                    {S.image.version_options.map((option) => (
                        <option key={String(option.getVersionId())} value={String(option.getVersionId())} >
                            {option.getVersionName()}
                        </option>
                    ))}
                </Select>
            </Field>
        </div >


        <div className="size_row">
            <Field
                className="size"
                label="Size"
                required
                validationMessage={S.image.size_error_message}
            >
                <Select
                    value={S.image.selected_size}
                    onChange={(_, { value }) => S.image.onChangeSize(value!)}
                >
                    {S.image.size_options.map((option) => (
                        <option key={option.key} value={option.key} >
                            {option.text}
                        </option>
                    ))}
                </Select>
            </Field>
        </div>
        <div className="prompt_row">
            <Field
                className="prompt"
                label="Prompt"
                required
                validationMessage={S.image.prompt_error_message}
            >
                <Textarea
                    className="prompt"
                    value={S.image.prompt}
                    onChange={(_, { value }) => S.image.onChangePrompt(value!)}
                    rows={12}
                    required
                >
                </Textarea>
            </Field>
        </div>
        <div className="negative_prompt_row">
            <Field
                className="negative_prompt"
                label="Negative Prompt"
                validationMessage={S.image.negative_prompt_error_message}
            >
                <Textarea
                    className="negative_prompt"
                    value={S.image.negative_prompt}
                    onChange={(_, { value }) => S.image.onChangeNegativePrompt(value!)}
                    rows={12}
                >
                </Textarea>
            </Field>
        </div>
        <div className="sampler_row">
            <Field
                className="sampler"
                label="Sampler"
                required
                validationMessage={S.image.sampler_error_message}
            >
                <Select
                    value={S.image.selected_sampler}
                    onChange={(_, { value }) => S.image.onChangeSampler(value!)}
                >
                    {S.image.sampler_options.map((option) => (
                        <option key={option.key} value={option.key} >
                            {option.text}
                        </option>
                    ))}
                </Select>
            </Field>
        </div>
        <div className="steps_row">
            <Field
                className="steps"
                label="Steps"
                required
                validationMessage={S.image.steps_error_message}
            >
                <Select
                    value={S.image.selected_steps}
                    onChange={(_, { value }) => S.image.onChangeSteps(parseInt(value) || 25)}
                >
                    {S.image.steps_options.map((option) => (
                        <option key={option.key} value={option.key} >
                            {option.text}
                        </option>
                    ))}
                </Select>
            </Field>
        </div>
        <div className="cfg_scale_row">
            <Field
                className="cfg_scale"
                label="Cfg Scale"
                required
                validationMessage={S.image.cfg_scale_error_message}
            >
                <Select
                    value={S.image.selected_cfg_scale}
                    onChange={(_, { value }) => S.image.onChangeCfgScale(parseInt(value) || 7)}
                >
                    {S.image.cfg_scale_options.map((option) => (
                        <option key={option.key} value={option.key} >
                            {option.text}
                        </option>
                    ))}
                </Select>
            </Field>
        </div>
    </>
)
export { CivitaiConfig }