import { observer } from "mobx-react-lite"
import { Select, Field } from "@fluentui/react-components"
import { S } from "../../State"

const VoiceChatConfig = observer(() =>
    <>
        <div className="model_row">
            <Field
                className="model"
                label="Model"
                required
            >
                <Select
                    value={S.voice_chat.selected_model_id}
                    onChange={(_, { value }) => S.voice_chat.onChangeModel(value!)}
                >
                    {S.voice_chat.model_options.map((option) => (
                        <option key={option.key} value={option.key} >
                            {option.text}
                        </option>
                    ))}
                </Select>
            </Field>
        </div>
        <div className="language_row">
            <Field
                className="language"
                label="Language"
                required
            >
                <Select
                    value={S.voice_chat.selected_language}
                    onChange={(_, { value }) => S.voice_chat.onChangeLanguage(value!)}
                >
                    {S.voice_chat.language_options.map((option) => (
                        <option key={option.key} value={option.key} >
                            {option.text}
                        </option>
                    ))}
                </Select>
            </Field>
        </div>

        <div className="voice_row">
            <Field
                className="voice"
                label="Model"
                required
            >
                <Select
                    value={S.voice_chat.selected_voice}
                    onChange={(_, { value }) => S.voice_chat.onChangeVoice(value!)}
                >
                    {S.voice_chat.voice_options.map((option) => (
                        <option key={option.key} value={option.key} >
                            {option.text}
                        </option>
                    ))}
                </Select>
            </Field>
        </div>

    </>
)

export { VoiceChatConfig }