import { observer } from "mobx-react-lite"
import { ProductDialog } from "./ProductDialog"
import { LeftPanel } from "./LeftPanel"
import { ChatView } from "./chat/ChatView"
import { ImageView } from "./image/ImageView"
import { CheckpointView } from "./checkpoint/CheckpointView"
import { VoiceChatView } from "./voice_chat/VoiceChatView"
import { RightPanel } from "./RightPanel"
import { S } from "../State";
import { PricingDialog } from "./PricingDialog"
import { CheckView } from "./check/CheckView"

const MainPage = observer(() =>
    <div className="main_page">
        <LeftPanel />
        <div className="main_content">
            {
                S.main.view === "chat" ? <ChatView /> : null
            }
            {
                S.main.view === "image" ? <ImageView /> : null
            }
            {
                S.main.view === "voice_chat" ? <VoiceChatView /> : null
            }
            {
                S.main.view === "checkpoint" ? <CheckpointView /> : null
            }
            {
                S.main.view === "check" ? <CheckView /> : null
            }
        </div>
        <RightPanel />
        <PricingDialog />
        <ProductDialog />
    </div >
)

export { MainPage }
