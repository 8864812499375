// source: image.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.abaoai.ImagePayload', null, global);
goog.exportSymbol('proto.abaoai.Tensor', null, global);
goog.exportSymbol('proto.abaoai.Tensor.Dtype', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.ImagePayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.abaoai.ImagePayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.ImagePayload.displayName = 'proto.abaoai.ImagePayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.Tensor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.abaoai.Tensor.repeatedFields_, null);
};
goog.inherits(proto.abaoai.Tensor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.Tensor.displayName = 'proto.abaoai.Tensor';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.ImagePayload.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.ImagePayload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.ImagePayload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.ImagePayload.toObject = function(includeInstance, msg) {
  var f, obj = {
    imageId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 3, 0),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    message: jspb.Message.getFieldWithDefault(msg, 5, ""),
    modelId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    quality: jspb.Message.getFieldWithDefault(msg, 7, ""),
    size: jspb.Message.getFieldWithDefault(msg, 8, ""),
    style: jspb.Message.getFieldWithDefault(msg, 9, ""),
    prompt: jspb.Message.getFieldWithDefault(msg, 10, ""),
    revisedPrompt: jspb.Message.getFieldWithDefault(msg, 11, ""),
    url: jspb.Message.getFieldWithDefault(msg, 12, ""),
    mimeType: jspb.Message.getFieldWithDefault(msg, 13, ""),
    width: jspb.Message.getFieldWithDefault(msg, 14, 0),
    height: jspb.Message.getFieldWithDefault(msg, 15, 0),
    negativePrompt: jspb.Message.getFieldWithDefault(msg, 16, ""),
    data: msg.getData_asB64(),
    nsfw: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    checkpointId: jspb.Message.getFieldWithDefault(msg, 19, 0),
    versionId: jspb.Message.getFieldWithDefault(msg, 20, 0),
    extension: jspb.Message.getFieldWithDefault(msg, 21, ""),
    cfgScale: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    seed: jspb.Message.getFieldWithDefault(msg, 23, 0),
    steps: jspb.Message.getFieldWithDefault(msg, 24, 0),
    sampler: jspb.Message.getFieldWithDefault(msg, 25, ""),
    score: jspb.Message.getFieldWithDefault(msg, 26, 0),
    clipSkip: jspb.Message.getFieldWithDefault(msg, 27, 0),
    nsfwLevel: jspb.Message.getFieldWithDefault(msg, 28, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.ImagePayload}
 */
proto.abaoai.ImagePayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.ImagePayload;
  return proto.abaoai.ImagePayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.ImagePayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.ImagePayload}
 */
proto.abaoai.ImagePayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setImageId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAccountId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuality(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSize(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStyle(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrompt(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setRevisedPrompt(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setMimeType(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWidth(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHeight(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setNegativePrompt(value);
      break;
    case 17:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNsfw(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCheckpointId(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVersionId(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtension$(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCfgScale(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSeed(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSteps(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setSampler(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScore(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClipSkip(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNsfwLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.ImagePayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.ImagePayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.ImagePayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.ImagePayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImageId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getQuality();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSize();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStyle();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPrompt();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getRevisedPrompt();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getMimeType();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getNegativePrompt();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      17,
      f
    );
  }
  f = message.getNsfw();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getCheckpointId();
  if (f !== 0) {
    writer.writeUint64(
      19,
      f
    );
  }
  f = message.getVersionId();
  if (f !== 0) {
    writer.writeUint64(
      20,
      f
    );
  }
  f = message.getExtension$();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getCfgScale();
  if (f !== 0.0) {
    writer.writeDouble(
      22,
      f
    );
  }
  f = message.getSeed();
  if (f !== 0) {
    writer.writeUint64(
      23,
      f
    );
  }
  f = message.getSteps();
  if (f !== 0) {
    writer.writeUint32(
      24,
      f
    );
  }
  f = message.getSampler();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getScore();
  if (f !== 0) {
    writer.writeInt64(
      26,
      f
    );
  }
  f = message.getClipSkip();
  if (f !== 0) {
    writer.writeInt64(
      27,
      f
    );
  }
  f = message.getNsfwLevel();
  if (f !== 0) {
    writer.writeInt64(
      28,
      f
    );
  }
};


/**
 * optional uint64 image_id = 1;
 * @return {number}
 */
proto.abaoai.ImagePayload.prototype.getImageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setImageId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 account_id = 2;
 * @return {number}
 */
proto.abaoai.ImagePayload.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 timestamp = 3;
 * @return {number}
 */
proto.abaoai.ImagePayload.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 status = 4;
 * @return {number}
 */
proto.abaoai.ImagePayload.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string message = 5;
 * @return {string}
 */
proto.abaoai.ImagePayload.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string model_id = 6;
 * @return {string}
 */
proto.abaoai.ImagePayload.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string quality = 7;
 * @return {string}
 */
proto.abaoai.ImagePayload.prototype.getQuality = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setQuality = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string size = 8;
 * @return {string}
 */
proto.abaoai.ImagePayload.prototype.getSize = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setSize = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string style = 9;
 * @return {string}
 */
proto.abaoai.ImagePayload.prototype.getStyle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setStyle = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string prompt = 10;
 * @return {string}
 */
proto.abaoai.ImagePayload.prototype.getPrompt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setPrompt = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string revised_prompt = 11;
 * @return {string}
 */
proto.abaoai.ImagePayload.prototype.getRevisedPrompt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setRevisedPrompt = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string url = 12;
 * @return {string}
 */
proto.abaoai.ImagePayload.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string mime_type = 13;
 * @return {string}
 */
proto.abaoai.ImagePayload.prototype.getMimeType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setMimeType = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional uint32 width = 14;
 * @return {number}
 */
proto.abaoai.ImagePayload.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional uint32 height = 15;
 * @return {number}
 */
proto.abaoai.ImagePayload.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string negative_prompt = 16;
 * @return {string}
 */
proto.abaoai.ImagePayload.prototype.getNegativePrompt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setNegativePrompt = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional bytes data = 17;
 * @return {string}
 */
proto.abaoai.ImagePayload.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * optional bytes data = 17;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.abaoai.ImagePayload.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 17;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.abaoai.ImagePayload.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 17, value);
};


/**
 * optional bool nsfw = 18;
 * @return {boolean}
 */
proto.abaoai.ImagePayload.prototype.getNsfw = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setNsfw = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional uint64 checkpoint_id = 19;
 * @return {number}
 */
proto.abaoai.ImagePayload.prototype.getCheckpointId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setCheckpointId = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional uint64 version_id = 20;
 * @return {number}
 */
proto.abaoai.ImagePayload.prototype.getVersionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setVersionId = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional string extension = 21;
 * @return {string}
 */
proto.abaoai.ImagePayload.prototype.getExtension$ = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setExtension$ = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional double cfg_scale = 22;
 * @return {number}
 */
proto.abaoai.ImagePayload.prototype.getCfgScale = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setCfgScale = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional uint64 seed = 23;
 * @return {number}
 */
proto.abaoai.ImagePayload.prototype.getSeed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setSeed = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional uint32 steps = 24;
 * @return {number}
 */
proto.abaoai.ImagePayload.prototype.getSteps = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setSteps = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional string sampler = 25;
 * @return {string}
 */
proto.abaoai.ImagePayload.prototype.getSampler = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setSampler = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional int64 score = 26;
 * @return {number}
 */
proto.abaoai.ImagePayload.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setScore = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional int64 clip_skip = 27;
 * @return {number}
 */
proto.abaoai.ImagePayload.prototype.getClipSkip = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setClipSkip = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional int64 nsfw_level = 28;
 * @return {number}
 */
proto.abaoai.ImagePayload.prototype.getNsfwLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ImagePayload} returns this
 */
proto.abaoai.ImagePayload.prototype.setNsfwLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.abaoai.Tensor.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.Tensor.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.Tensor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.Tensor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.Tensor.toObject = function(includeInstance, msg) {
  var f, obj = {
    dtype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    shapeList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.Tensor}
 */
proto.abaoai.Tensor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.Tensor;
  return proto.abaoai.Tensor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.Tensor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.Tensor}
 */
proto.abaoai.Tensor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.abaoai.Tensor.Dtype} */ (reader.readEnum());
      msg.setDtype(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addShape(values[i]);
      }
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.Tensor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.Tensor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.Tensor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.Tensor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDtype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getShapeList();
  if (f.length > 0) {
    writer.writePackedUint32(
      2,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.abaoai.Tensor.Dtype = {
  NONE: 0,
  FLOAT32: 1,
  FLOAT16: 2,
  BFLOAT16: 3
};

/**
 * optional Dtype dtype = 1;
 * @return {!proto.abaoai.Tensor.Dtype}
 */
proto.abaoai.Tensor.prototype.getDtype = function() {
  return /** @type {!proto.abaoai.Tensor.Dtype} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.abaoai.Tensor.Dtype} value
 * @return {!proto.abaoai.Tensor} returns this
 */
proto.abaoai.Tensor.prototype.setDtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated uint32 shape = 2;
 * @return {!Array<number>}
 */
proto.abaoai.Tensor.prototype.getShapeList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.abaoai.Tensor} returns this
 */
proto.abaoai.Tensor.prototype.setShapeList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.abaoai.Tensor} returns this
 */
proto.abaoai.Tensor.prototype.addShape = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.abaoai.Tensor} returns this
 */
proto.abaoai.Tensor.prototype.clearShapeList = function() {
  return this.setShapeList([]);
};


/**
 * optional bytes data = 3;
 * @return {string}
 */
proto.abaoai.Tensor.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes data = 3;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.abaoai.Tensor.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.abaoai.Tensor.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.abaoai.Tensor} returns this
 */
proto.abaoai.Tensor.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


goog.object.extend(exports, proto.abaoai);
