import { observer } from "mobx-react-lite"
import { S } from "../../State"
import SimpleBar from "simplebar-react"

const CheckpointDescription = observer(() =>
    <SimpleBar className="checkpoint_description_row">
        <div dangerouslySetInnerHTML={{ __html: S.checkpoint.selected_checkpoint.checkpoint_description }} />
    </SimpleBar>
)

export { CheckpointDescription }