import { observer } from "mobx-react-lite"
import { S } from "../State"
import {
    Subtitle1,
    Button,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Persona,
    Tooltip,
} from "@fluentui/react-components"
import {
    ChatSparkleRegular,
    EyeOffRegular,
    EyeRegular,
    ImageRegular,
    MoneyRegular,
    PanelLeftTextFilled,
    PersonStarRegular,
    SignOutRegular,
    ImageSearchRegular,
} from "@fluentui/react-icons";


const TopBar = observer(() =>
    <div className="domain_row">
        <Button
            appearance="transparent"
            size="large"
            icon={<PanelLeftTextFilled />}
            onClick={() => S.main.onClickMenu()}
        />
        <div className="domain" >
            {
                S.main.view === "chat" ?
                    S.main.chat_id === 0 ?
                        <Subtitle1>Chat</Subtitle1>
                        :
                        <Subtitle1>{S.chat.models[S.chat.selected_model_id].getModelName()}</Subtitle1>
                    :
                    null
            }
            {
                S.main.view === "image" ?
                    <Tooltip content={S.main.file_url} relationship="description">
                        <Subtitle1>{S.image.title}</Subtitle1>
                    </Tooltip>
                    :
                    null
            }
            {
                S.main.view === "voice_chat" ? <Subtitle1>Voice Chat</Subtitle1> : null
            }
            {
                S.main.view === "checkpoint" ? <Subtitle1>Checkpoints</Subtitle1> : null
            }
            {
                S.main.view === "check" ? <Subtitle1>Credits</Subtitle1> : null
            }
        </div>
        <Menu>
            <MenuTrigger disableButtonEnhancement>
                <Persona name={S.main.name} primaryText="" size="large" />
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    <MenuItem
                        icon={<ChatSparkleRegular />}
                        onClick={() => S.main.onClickNewChat()}
                    >
                        New Chat
                    </MenuItem>
                    <MenuItem
                        icon={<ImageRegular />}
                        onClick={() => S.main.onClickNewImage()}
                    >
                        New Image
                    </MenuItem>
                    <MenuItem
                        icon={<ImageSearchRegular />}
                        onClick={() => S.main.onClickSearchCheckpoint()}
                    >
                        Search Checkpoint
                    </MenuItem>
                    <MenuItem
                        icon={<PersonStarRegular />}
                        onClick={() => S.main.onClickMyPlan()}
                    >
                        My Unlimited Plan
                    </MenuItem>
                    <MenuItem
                        icon={<MoneyRegular />}
                        onClick={() => S.check.onClickNewCheck()}
                        secondaryContent={"$" + S.main.credits}
                    >
                        My Credits
                    </MenuItem>
                    <MenuItem
                        icon={S.main.nsfw ? <EyeRegular /> : <EyeOffRegular />}
                        onClick={() => S.main.onClickNSFW()}
                    >
                        NSFW
                    </MenuItem>
                    <MenuItem
                        icon={<SignOutRegular />}
                        onClick={() => S.main.onClickLogOut()}
                    >
                        Log out
                    </MenuItem>
                </MenuList>
            </MenuPopover>
        </Menu>
    </div>
)

export { TopBar }
