import { observer } from "mobx-react-lite"
import { S } from "../../State"
import { TopBar } from "../TopBar"
import {
    TableBody,
    TableCell,
    TableRow,
    Table,
    TableHeader,
    TableHeaderCell,
    TableCellLayout,
    Link,
} from "@fluentui/react-components";

const CheckView = observer(() =>
    <div className="main_check">
        <TopBar />
        <div className="checks_row">
            <div className="pricing_table_row">
                <stripe-pricing-table
                    pricing-table-id={S.main.pricing_tables[2].pricing_table_id}
                    publishable-key={S.main.pricing_tables[2].publishable_key}
                    client-reference-id={S.main.account_id}
                    customer-email={S.main.email}
                >
                </stripe-pricing-table>
            </div>
            <div>
                <Table
                    noNativeElements={false}
                >
                    <TableHeader>
                        <TableRow>
                            {S.check.columns.map((column) => (
                                <TableHeaderCell key={column.key}>
                                    {column.label}
                                </TableHeaderCell>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {S.check.checks.slice().reverse().map((check) => (
                            <TableRow key={check.getCheckId()}>
                                <TableCell>
                                    <TableCellLayout truncate={true}>
                                        {new Date(check.getTimestamp() * 1000).toLocaleDateString()}
                                    </TableCellLayout>
                                </TableCell>
                                <TableCell>
                                    <TableCellLayout truncate={true}>
                                        <Link href={"mailto:" + check.getDrawerEmail()}>{check.getDrawerEmail()}</Link>
                                    </TableCellLayout>
                                </TableCell>
                                <TableCell>
                                    <TableCellLayout truncate={true}>
                                        <Link href={"mailto:" + check.getRecipientEmail()}>{check.getRecipientEmail()}</Link>
                                    </TableCellLayout>
                                </TableCell>
                                <TableCell>
                                    <TableCellLayout truncate={true}>
                                        {check.getDescription()}
                                    </TableCellLayout>
                                </TableCell>
                                <TableCell>
                                    ${check.getAmount()}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    </div >
)

export { CheckView }