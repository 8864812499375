import { observer } from "mobx-react-lite"
import {
    DrawerBody,
    Drawer,
} from "@fluentui/react-components"
import { Navigation } from "./Navigation"
import { S } from "../State"


const LeftPanel = observer(() =>
    <Drawer
        type={S.is_mobile ? "overlay" : "inline"}
        separator
        position="start"
        open={S.main.left_drawer_is_open}
        onOpenChange={(_, { open }) => S.main.onOpenChangeLeft(open)}
    >
        <DrawerBody>
            <Navigation />
        </DrawerBody>
    </Drawer>
)
export { LeftPanel }