import { observer } from "mobx-react-lite"
import {
    Button,
} from "@fluentui/react-components";
import { S } from "../../State"
import { Select, Field, Input } from "@fluentui/react-components"
import SimpleBar from "simplebar-react";

const CheckpointSearchConfig = observer(() =>
    <>
        <SimpleBar className="checkpoint_config_row">
            <div className="model_row">
                <Field
                    className="model"
                    label="Model"
                    required
                >
                    <Select
                        value={S.checkpoint.selected_model_id}
                        onChange={(_, { value }) => S.checkpoint.onChangeModel(value!)}
                    >
                        {S.checkpoint.model_options.map((option) => (
                            <option key={option.key} value={option.key} >
                                {option.text}
                            </option>
                        ))}
                    </Select>
                </Field>
            </div>
            <div className="sort_row">
                <Field
                    className="sort"
                    label="Sort"
                    required
                >
                    <Select
                        value={S.checkpoint.selected_sort}
                        onChange={(_, { value }) => S.checkpoint.onChangeSort(value)}
                    >
                        {S.checkpoint.sort_options.map((option) => (
                            <option key={option.key} value={option.key} >
                                {option.text}
                            </option>
                        ))}
                    </Select>
                </Field>
            </div >
            <div className="period_row">
                <Field
                    className="period"
                    label="Period"
                    required
                >
                    <Select
                        value={S.checkpoint.selected_period}
                        onChange={(_, { value }) => S.checkpoint.onChangePeriod(value)}
                    >
                        {S.checkpoint.period_options.map((option) => (
                            <option key={option.key} value={option.key} >
                                {option.text}
                            </option>
                        ))}
                    </Select>
                </Field>
            </div >
            <div className="period_row">
                <Field
                    className="textfield"
                    label="Query"
                >
                    <Input
                        value={S.checkpoint.query}
                        onChange={(_, { value }) => S.checkpoint.onChangeQuery(value)}
                    />
                </Field>
            </div >
        </SimpleBar>
        <div className="checkpoint_action_row">
            <Button
                className="checkpoint_action_button"
                appearance="primary"
                onClick={() => S.checkpoint.onClickSearchButton()}
            >
                Search
            </Button>
        </div>
    </>
)

export { CheckpointSearchConfig }