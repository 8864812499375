import { makeObservable, observable, computed } from "mobx"
import { ACCESS } from "../Client"
import { S } from "../State"
import grpcWeb from "grpc-web"

class LoginState {
    title: string = "Welcome back"
    email: string = ""
    password: string = ""

    constructor() {
        makeObservable(this, {
            title: observable,
            email: observable,
            password: observable,
            email_error_message: computed,
            password_error_message: computed,
        })
    }

    get email_error_message() {
        if (S.error_field === "email") {
            return S.error_message
        }
        return ""
    }

    get password_error_message() {
        if (S.error_field === "password") {
            return S.error_message
        }
        return ""
    }

    async onClickContinue() {
        S.clearError()
        try {
            let req = new proto.abaoai.LoginRequest()
            req.setEmail(this.email.toLowerCase())
            req.setPassword(this.password)
            let res: proto.abaoai.LoginResponse = await ACCESS.login(req)
            let account_id = res.getAccountId()
            let session_token = res.getSessionToken()
            let session_timestamp = res.getSessionTimestamp()
            S.setMetadata({ account_id, session_timestamp, session_token })
            await S.onPageLoad()
        } catch (e) {
            S.onError(e as grpcWeb.RpcError)
        }
    }

    onChangeEmail(value: string) {
        this.email = value
    }

    onChangePassword(value: string) {
        this.password = value
    }
}
export { LoginState };
