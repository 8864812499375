import { makeObservable, observable, runInAction, computed } from "mobx"
import { ACCESS } from "../Client"
import { S } from "../State"
import grpcWeb from "grpc-web"

class ResetState {
    name: string = ""
    email: string = ""
    password: string = ""
    code: string = ""
    email_countdown: number = 0

    constructor() {
        makeObservable(this, {
            name_error_message: computed,
            email_error_message: computed,
            password_error_message: computed,
            code_error_message: computed,
            email_countdown: observable,
        })
    }
    get name_error_message() {
        if (S.error_field === "name") {
            return S.error_message
        }
        return ""
    }

    get email_error_message() {
        if (S.error_field === "email") {
            return S.error_message
        }
        return ""
    }

    get password_error_message() {
        if (S.error_field === "password") {
            return S.error_message
        }
        return ""
    }

    get code_error_message() {
        if (S.error_field === "code") {
            return S.error_message
        }
        return ""
    }

    onChangeName(value: string) {
        this.name = value
    }

    onChangeEmail(value: string) {
        this.email = value
    }

    onChangePassword(value: string) {
        this.password = value
    }

    onChangeCode(value: string) {
        this.code = value
    }

    async onClickSendCode() {
        S.clearError()
        try {
            let req = new proto.abaoai.SendCodeRequest()
            req.setUsage(2);
            req.setName(this.name)
            req.setEmail(this.email.toLowerCase())
            let res: proto.abaoai.SendCodeResponse = await ACCESS.sendCode(req)
            console.log(res.getTimestamp())
            this.start_email_countdown()
        } catch (e) {
            S.onError(e as grpcWeb.RpcError)
        }
    }

    start_email_countdown() {
        if (this.email_countdown !== 0) {
            return
        }
        runInAction(() => {
            this.email_countdown = 60
        })
        let email_interval = setInterval(() => {
            runInAction(() => {
                this.email_countdown -= 1
            })
            if (this.email_countdown === 0) {
                clearInterval(email_interval)
            }
        }, 1000)
    }

    async onClickContinue() {
        S.clearError()
        try {
            let req = new proto.abaoai.ResetRequest()
            req.setName(this.name)
            req.setEmail(this.email.toLowerCase())
            req.setPassword(this.password)
            req.setCode(this.code)
            let res: proto.abaoai.ResetResponse = await ACCESS.reset(req)
            console.log(res.getAccountId())
            S.onClickPage("LOGIN")
        } catch (e) {
            S.onError(e as grpcWeb.RpcError)
        }
    }

}

export { ResetState }
