import { makeObservable, observable } from "mobx"

class MainChatSummaryData {
    chat_id: number = 0
    summary: string = ""
    timestamp: number = 0
    constructor(chat_id: number, summary: string, timestamp: number) {
        makeObservable(this, {
            summary: observable,
        })
        this.chat_id = chat_id
        this.summary = summary
        this.timestamp = timestamp
    }
}

export { MainChatSummaryData }