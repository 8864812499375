import { makeObservable, observable, runInAction } from "mobx"
import { S } from "../State"
import { CHAT, ACCESS } from "../Client"
import grpcWeb from "grpc-web"
import { MainChatSummaryData } from "../data/MainChatSummaryData"
import { MainDialogData } from "../data/MainDialogData"
import { Checkpoint } from "../data/Checkpoint"

const MAX_NEW_TOKENS = 1024 * 2
const TEMPERATURE = 0.7
const TOP_P = 0.1
const TOP_K = 40

function wget_url(checkpoint_id: number, version_id: number): string {
    return `http://abao.ai/models/checkpoints/${checkpoint_id}/${version_id}.safetensors`
}

class MainState {
    chat_id: number = 0
    view = "image"
    name: string = ""
    account_id: number = 0
    email: string = ""
    pricing_dialog_is_open: boolean = false
    stripe_dialog_is_open: boolean = false
    is_testing: boolean = false
    stripe_customer_id: string = ""
    credits: number = 0
    subscription_id: string = ""
    product_name: string = ""
    product_id: string = ""
    left_drawer_is_open: boolean = false
    right_drawer_is_open: boolean = false
    summaries: MainChatSummaryData[] = []
    nsfw: boolean = false
    file_url: string = ""

    products: { [key: string]: number } = {
        prod_OcdDBYnJjWzKDR: 1,
        prod_OceGfUzjwOsht4: 5,
        prod_OcBB417oZq7hpx: 1,
        prod_OcBE2gUEnHDZBw: 5,
    }

    pricing_tables = [
        {
            pricing_table_id: "prctbl_1NpP1SECZD2DYopxWahZNeGI",
            publishable_key: "pk_test_51Nk8MVECZD2DYopxF6van0LAIWvfSrXGC22ue7mCHvKfx05vaiyQvl3Z7HdEWmsrQGjh8J6HJnNEZhjRsijMFWNJ00JNcHUDau",
        },
        {
            pricing_table_id: "prctbl_1ONt6CECZD2DYopxVSChZkZE",
            publishable_key: "pk_live_51Nk8MVECZD2DYopxXfqoOYvumPTpmzJ8YsFHcgsjb8I8YFMSZyAZXiADLYMC8J29btdEK7oNmsAxSB8tPutFV1YR00TkZXd5D8",
        },
        {
            pricing_table_id: "prctbl_1P0vBKECZD2DYopxIAE6NsCn",
            publishable_key: "pk_live_51Nk8MVECZD2DYopxXfqoOYvumPTpmzJ8YsFHcgsjb8I8YFMSZyAZXiADLYMC8J29btdEK7oNmsAxSB8tPutFV1YR00TkZXd5D8",
        }
    ]

    constructor() {
        makeObservable(this, {
            chat_id: observable,
            view: observable,
            name: observable,
            pricing_dialog_is_open: observable,
            stripe_dialog_is_open: observable,
            email: observable,
            account_id: observable,
            product_name: observable,
            credits: observable,
            left_drawer_is_open: observable,
            right_drawer_is_open: observable,
            summaries: observable,
            nsfw: observable,
            file_url: observable,
        })
    }

    pricing_table_id(): string {
        let testing = this.is_testing ? 0 : 1
        return this.pricing_tables[testing].pricing_table_id
    }

    publishable_key(): string {
        let testing = this.is_testing ? 0 : 1
        return this.pricing_tables[testing].publishable_key
    }


    onOpenChangeLeft(open: boolean) {
        runInAction(() => {
            this.left_drawer_is_open = open
        })
    }

    onOpenChangeRight(open: boolean) {
        runInAction(() => {
            this.right_drawer_is_open = open
        })
    }

    onDismissProductDialog() {
        runInAction(() => {
            this.stripe_dialog_is_open = false
        })
    }

    onDismissPricingDialog() {
        runInAction(() => {
            this.pricing_dialog_is_open = false
        })
    }

    onDismissRightDrawer() {
        runInAction(() => {
            this.right_drawer_is_open = false
        })
    }

    onDismissLeftDrawer() {
        runInAction(() => {
            this.left_drawer_is_open = false
        })
    }

    onClickNSFW() {
        runInAction(() => {
            this.nsfw = !this.nsfw
        })
        if (this.nsfw) {
            alert("NSFW is On")
        } else {
            alert("NSFW is Off")
        }
    }

    async onClickDeleteConfirm() {
        try {
            let req = new proto.abaoai.ChatPayload()
            req.setChatId(this.chat_id)
            req.setAccountId(S.main.account_id)
            let res: proto.abaoai.ChatHistoryPayload = await CHAT.hideChat(req, S.getMetadataMap());
            runInAction(() => {
                S.chat.delete_dialog_is_open = false
                S.chat.delete_dialog_summary = ""
                this.chat_id = 0
                this.summaries = []
                S.chat.dialogs = []
                for (let chat_summary of res.getChatSummariesList()) {
                    let chat_id = chat_summary.getChatId()
                    let summary = chat_summary.getSummary()
                    let timestamp = chat_summary.getTimestamp()
                    this.summaries.push(new MainChatSummaryData(chat_id, summary, timestamp))
                }
            })
        } catch (e) {
            S.onError(e as grpcWeb.RpcError)
        }
    }

    async loadHistory() {
        let req = new proto.abaoai.ChatHistoryPayload()
        req.setAccountId(S.main.account_id)
        let res: proto.abaoai.ChatHistoryPayload = await CHAT.loadChatHistory(req, S.getMetadataMap());
        runInAction(() => {
            for (let chat_summary of res.getChatSummariesList()) {
                let chat_id = chat_summary.getChatId()
                let summary = chat_summary.getSummary()
                let timestamp = chat_summary.getTimestamp()
                this.summaries.push(new MainChatSummaryData(chat_id, summary, timestamp))
            }
        })
    }

    async onClickUpgrade() {
        try {
            let req = new proto.abaoai.BillingPortalSessionPayload()
            req.setAccountId(this.account_id)
            req.setSubscriptionId(this.subscription_id)
            req.setReturnUrl(window.location.href)
            let res: proto.abaoai.BillingPortalSessionPayload = await ACCESS.createBillingPortalSession(req, S.getMetadataMap())
            let url = res.getUrl()
            window.location.assign(url)
        } catch (e) {
            S.onError(e as grpcWeb.RpcError)
        }
    }

    async onClickManageLink() {
        try {
            let req = new proto.abaoai.BillingPortalSessionPayload()
            req.setAccountId(this.account_id)
            req.setReturnUrl(window.location.href)
            let res: proto.abaoai.BillingPortalSessionPayload = await ACCESS.createBillingPortalSession(req, S.getMetadataMap())
            let url = res.getUrl()
            window.location.assign(url)
        } catch (e) {
            S.onError(e as grpcWeb.RpcError)
        }
    }

    onClickLogOut() {
        S.setMetadata({ account_id: 0, session_token: "", session_timestamp: 0 })
        runInAction(() => {
            S.page = "AUTH"
        })
    }

    onClickMyPlan() {
        runInAction(() => {
            if (this.stripe_customer_id === "") {
                this.pricing_dialog_is_open = true
            } else {
                this.stripe_dialog_is_open = true
            }
        })
    }

    onClickMenu() {
        runInAction(() => {
            this.left_drawer_is_open = !this.left_drawer_is_open
        })
    }

    async loadProduct(account: proto.abaoai.AccountPayload) {
        let stripe_customer_id = account.getStripeCustomerId()
        if (stripe_customer_id.length !== 0) {
            let req = new proto.abaoai.ActiveSubscriptionPayload();
            req.setAccountId(this.account_id);
            let res: proto.abaoai.ActiveSubscriptionPayload = await ACCESS.getActiveSubscription(req, S.getMetadataMap());
            if (res.getSubscription()) {
                let subscription: proto.abaoai.SubscriptionPayload = res.getSubscription()!;
                runInAction(() => {
                    this.product_id = subscription.getProductId()
                    this.product_name = subscription.getProductName()
                    this.subscription_id = subscription.getSubscriptionId()
                })
            }
        }
    }

    async onAccountLoad(account: proto.abaoai.AccountPayload) {
        console.log(account.toObject())
        runInAction(() => {
            this.name = account.getName()
            this.email = account.getEmail()
            this.account_id = account.getAccountId()
            this.is_testing = account.getIsTesting()
            this.stripe_customer_id = account.getStripeCustomerId()
            this.credits = account.getCredits()
        })
        this.loadProduct(account)
    }

    async create_chat(account_id: number, model_id: string, system_prompt: string) {
        let req = new proto.abaoai.ChatPayload()
        req.setAccountId(account_id)
        req.setModelId(model_id)
        req.setSystemPrompt(system_prompt)
        req.setMaxNewTokens(MAX_NEW_TOKENS)
        req.setTemperature(TEMPERATURE)
        req.setTopP(TOP_P)
        req.setTopK(TOP_K)
        let chat: proto.abaoai.ChatPayload = await CHAT.createChat(req, S.getMetadataMap())
        return chat;
    }

    async create_dialog(chat_id: number, input: string) {
        let req = new proto.abaoai.DialogPayload()
        req.setChatId(chat_id)
        req.setInput(input)
        let dialog: proto.abaoai.DialogPayload = await CHAT.createDialog(req, S.getMetadataMap())
        return dialog
    }

    onClickNewChat() {
        runInAction(() => {
            S.main.view = "chat"
            S.chat.right_title = "New Chat"
            S.chat.dialogs = []
            S.chat.prompt = ""
            S.main.chat_id = 0
            S.main.right_drawer_is_open = true
            if (S.is_mobile && S.main.left_drawer_is_open) {
                S.main.left_drawer_is_open = false
            }
        })
    }

    onClickNewVoiceChat() {
        runInAction(() => {
            S.main.view = "voice_chat"
            S.chat.dialogs = []
            S.chat.prompt = ""
            S.main.chat_id = 0
            S.main.right_drawer_is_open = true
            if (S.is_mobile && S.main.left_drawer_is_open) {
                S.main.left_drawer_is_open = false
            }
        })
    }

    onClickNewImage() {
        runInAction(() => {
            S.main.view = "image"
            S.image.right_title = "New Image"
            S.image.selected_model_id = ""
            S.image.selected_creator_id = 0
            S.image.prompt = ""
            S.image.revised_prompt = ""
            S.image.url = ""
            S.image.is_civitai = false
            S.main.right_drawer_is_open = true
            if (S.is_mobile && S.main.left_drawer_is_open) {
                S.main.left_drawer_is_open = false
            }
        })
        S.image.list_more_images(true)
    }

    onClickSearchCheckpoint() {
        runInAction(() => {
            S.main.view = "checkpoint"
            S.checkpoint.right_title = "Search Checkpoint"
            S.checkpoint.selected_checkpoint_id = 0
            S.checkpoint.selected_version_id = 0
            if (S.checkpoint.selected_model_id.length === 0) {
                S.checkpoint.selected_model_id = "stabilityai/stable-diffusion-xl-base-1.0"
            }
            S.main.right_drawer_is_open = true
            if (S.is_mobile && S.main.left_drawer_is_open) {
                S.main.left_drawer_is_open = false
            }
        })
        S.checkpoint.fetchMoreCheckpoints(true)
    }


    async onClickSummary(chat_id: number) {
        runInAction(() => {
            this.view = "chat"
            this.right_drawer_is_open = false
            if (S.is_mobile && S.main.left_drawer_is_open) {
                S.main.left_drawer_is_open = false
            }
        })

        try {
            let req = new proto.abaoai.ChatPayload()
            req.setChatId(chat_id)
            let res: proto.abaoai.ChatPayload = await CHAT.getChat(req, S.getMetadataMap())
            runInAction(() => {
                S.main.view = "chat"
                S.chat.selected_model_id = res.getModelId()
                S.main.chat_id = chat_id
                S.chat.system_prompt = res.getSystemPrompt()
                S.chat.dialogs = []
            })
            let dialog_ids = res.getDialogIdsList()
            for (let dialog_id of dialog_ids) {
                let req = new proto.abaoai.DialogPayload()
                req.setDialogId(dialog_id);
                let res: proto.abaoai.DialogPayload = await CHAT.getDialog(req, S.getMetadataMap())
                let dialog_data = new MainDialogData(dialog_id, res.getInput(), res.getOutput())
                runInAction(() => {
                    S.chat.prompt = ""
                    S.chat.dialogs.unshift(dialog_data)
                })
            }
        } catch (e) {
            S.onError(e as grpcWeb.RpcError)
        }
    }

    async onClickCreator(creator: proto.abaoai.CreatorPayload) {
        runInAction(() => {
            S.main.view = "image"
            S.main.file_url = ""
            S.main.right_drawer_is_open = false;
            S.image.is_civitai = false
            S.image.selected_creator_id = creator.getAccountId()
            S.image.creator_name = creator.getName()
            if (S.is_mobile && S.main.left_drawer_is_open) {
                S.main.left_drawer_is_open = false
            }
        })
        S.image.list_more_images(true)
    }

    onClickModel(model_id: string) {
        runInAction(() => {
            S.main.view = "checkpoint"
            S.checkpoint.selected_model_id = model_id
            S.checkpoint.selected_checkpoint_id = 0
            S.checkpoint.selected_version_id = 0
            S.checkpoint.query = ""
            if (S.is_mobile && S.main.left_drawer_is_open) {
                S.main.left_drawer_is_open = false
            }
        })
        S.checkpoint.fetchMoreCheckpoints(true)
    }

    onClickCheckpointVersion(checkpoint: Checkpoint, version: proto.abaoai.CheckpointVersionPayload) {
        runInAction(() => {
            S.checkpoint.selected_checkpoint_id = checkpoint.checkpoint_id
            S.checkpoint.selected_version_id = version.getVersionId()
            S.main.view = "image"
            S.main.file_url = wget_url(checkpoint.checkpoint_id, version.getVersionId())
            S.image.is_civitai = true
            S.image.selected_model_id = version.getBaseModelId()
            S.image.checkpoint_id = checkpoint.checkpoint_id
            S.image.version_id = version.getVersionId()
            S.image.checkpoint_name = checkpoint.checkpoint_name
            S.image.version_name = version.getVersionName()
            S.image.title = checkpoint.checkpoint_name + " " + version.getVersionName()
            S.main.right_drawer_is_open = false
        })
        S.image.search_more_images(true)
    }

}
export { MainState }
