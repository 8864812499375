import { makeObservable, observable } from "mobx"

class MainDialogData {
    dialog_id: number = 0
    input: string = ""
    output: string = ""
    constructor(dialog_id: number, input: string, output: string) {
        makeObservable(this, {
            input: observable,
            output: observable,
        })
        this.dialog_id = dialog_id
        this.input = input
        this.output = output
    }
}

export { MainDialogData }