import { observer } from "mobx-react-lite"
import {
    Button,
} from "@fluentui/react-components";
import { S } from "../../State"
import { VoiceChatConfig } from "./VoiceChatConfig";

const VoiceChatPanel = observer(() =>
    <div className="voice_chat_panel">
        <div className="voice_chat_config_row">
            <VoiceChatConfig />
        </div>

        <div className="voice_chat_action_row">
            <Button
                className="voice_chat_action_button"
                appearance="primary"
                onClick={() => S.voice_chat.onClickCallButton()}
            >
                Call
            </Button>
        </div>
    </div>
)

export { VoiceChatPanel }