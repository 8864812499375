import React from "react"
import { observer } from "mobx-react-lite"
import { MainPage } from "./MainPage"
import { AuthPage } from "./AuthPage"
import { LoginPage } from "./LoginPage"
import { SignupPage } from "./SignupPage"
import { ResetPage } from "./ResetPage"
import { S } from "../State"

const App = observer(() => {
    switch (S.page) {
        case "MAIN":
            return <MainPage />
        case "AUTH":
            return <AuthPage />
        case "LOGIN":
            return <LoginPage />
        case "RESET":
            return <ResetPage />
        case "SIGNUP":
            return <SignupPage />
        default:
            return <div> page error </div>
    }
})

export { App }
