import { observer } from "mobx-react-lite"
import { S } from "../State"
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Button,
} from "@fluentui/react-components";

const PricingDialog = observer(() =>
    <Dialog
        open={S.main.pricing_dialog_is_open}
        onOpenChange={() => S.main.onDismissPricingDialog()}
    >
        <DialogSurface>
            <DialogBody>
                <DialogTitle>Plans</DialogTitle>
                <DialogContent>
                    <stripe-pricing-table
                        pricing-table-id={S.main.pricing_table_id()}
                        publishable-key={S.main.publishable_key()}
                        client-reference-id={S.main.account_id}
                        customer-email={S.main.email}
                    >
                    </stripe-pricing-table>
                </DialogContent>
                <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary">Close</Button>
                    </DialogTrigger>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog>
)

export { PricingDialog }

