import { observer } from "mobx-react-lite"
import { S } from "../State"
import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    Body1,
    Button,
    Link,
} from "@fluentui/react-components";
import {
    ChatSparkleRegular,
    ImageRegular,
    StarRegular,
    DeleteRegular,
    SearchRegular,
    ImageSearchRegular
} from "@fluentui/react-icons";
import SimpleBar from 'simplebar-react';

const Navigation = observer(() =>
    <div className="navigation">
        <div className="menu_actions">
            <div className="menu_row">
                <Button
                    className="menu_button"
                    appearance="transparent"
                    icon={<ChatSparkleRegular />}
                    onClick={() => S.main.onClickNewChat()}
                >
                    New Chat
                </Button>
            </div>
            <div className="menu_row">
                <Button
                    appearance="transparent"
                    icon={<ImageRegular />}
                    onClick={() => S.main.onClickNewImage()}
                >
                    New Image
                </Button>
            </div>
            <div className="menu_row">
                <Button
                    icon={<ImageSearchRegular />}
                    appearance="transparent"
                    onClick={() => S.main.onClickSearchCheckpoint()}
                >
                    Search Checkpoint
                </Button>

            </div>
        </div>
        <Accordion
            className="accordion"
            collapsible>
            {
                S.checkpoint.selected_checkpoint ?
                    <AccordionItem
                        className="versions"
                        value="4"
                    >
                        <AccordionHeader className="versions_header">
                            {S.checkpoint.selected_checkpoint.checkpoint_name}
                        </AccordionHeader>
                        <AccordionPanel className="versions_panel">
                            <SimpleBar>
                                {
                                    Object.values(S.checkpoint.selected_checkpoint.versions).map((version) =>
                                        <div
                                            className="version_row"
                                            key={version.getVersionId()}
                                        >
                                            <SearchRegular className="search" />
                                            <Link
                                                appearance={version.getVersionId() === S.checkpoint.selected_version_id ? "default" : "subtle"}
                                                className="version"
                                                onClick={() => S.main.onClickCheckpointVersion(S.checkpoint.selected_checkpoint, version)}
                                            >
                                                <Body1 truncate={true} block={true} wrap={false}>
                                                    {version.getVersionName()}
                                                </Body1>
                                            </Link>
                                        </div>
                                    )
                                }
                            </SimpleBar>
                        </AccordionPanel>
                    </AccordionItem>
                    :
                    null
            }
            <AccordionItem
                className="chats"
                value="1"
            >
                <AccordionHeader className="chats_header">
                    Chats
                </AccordionHeader>
                <AccordionPanel  >
                    <SimpleBar className="chats_panel">
                        {
                            S.main.summaries.map((chat_summary) =>
                                <div
                                    className="summary_row"
                                    key={chat_summary.chat_id}
                                >
                                    <ChatSparkleRegular
                                        className="chat"
                                    />
                                    <Link
                                        appearance={chat_summary.chat_id === S.main.chat_id ? "default" : "subtle"}
                                        className="summary"
                                        onClick={() => S.main.onClickSummary(chat_summary.chat_id)}
                                    >
                                        <Body1 truncate={true} block={true} wrap={false} >
                                            {chat_summary.summary}
                                        </Body1>
                                    </Link>
                                    <Button
                                        className="delete"
                                        appearance="transparent"
                                        icon={<DeleteRegular />}
                                        style={{ visibility: chat_summary.chat_id === S.main.chat_id ? "visible" : "hidden" }}
                                        onClick={() => S.chat.onClickDelete(chat_summary.summary)}
                                    />
                                </div>
                            )
                        }
                    </SimpleBar>
                </AccordionPanel>
            </AccordionItem>
            <AccordionItem
                className="images"
                value="2"
            >
                <AccordionHeader className="images_header">
                    Images
                </AccordionHeader>
                <AccordionPanel className="images_panel">
                    <SimpleBar>
                        {
                            Object.values(S.image.creators).map((creator) =>
                                <div
                                    className="creator_row"
                                    key={creator.getAccountId()}
                                >
                                    <StarRegular className="star" />
                                    <Link
                                        appearance={creator.getAccountId() === S.image.selected_creator_id ? "default" : "subtle"}
                                        className="creator"
                                        onClick={() => S.main.onClickCreator(creator)}
                                    >
                                        <Body1 truncate={true} block={true} wrap={false}>
                                            {creator.getName()}
                                        </Body1>
                                    </Link>
                                </div>
                            )
                        }
                    </SimpleBar>
                </AccordionPanel>
            </AccordionItem>
            <AccordionItem
                className="checkpoints"
                value="3"
            >
                <AccordionHeader className="checkpoints_header">
                    Checkpoints
                </AccordionHeader>
                <AccordionPanel className="checkpoints_panel">
                    <SimpleBar>
                        {
                            Object.values(S.image.models).filter((model) => model.getFunctionId() > 3).map((model) =>
                                <div
                                    className="checkpoint_row"
                                    key={model.getModelId()}
                                >
                                    <SearchRegular className="search" />
                                    <Link
                                        appearance={model.getModelId() === S.checkpoint.selected_model_id ? "default" : "subtle"}
                                        className="checkpoint"
                                        onClick={() => S.main.onClickModel(model.getModelId())}
                                    >
                                        <Body1 truncate={true} block={true} wrap={false}>
                                            {model.getModelName()}
                                        </Body1>
                                    </Link>
                                </div>
                            )
                        }
                    </SimpleBar>

                </AccordionPanel>
            </AccordionItem>
        </Accordion >
    </div >
)

export { Navigation }