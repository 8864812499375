
import { observer } from "mobx-react-lite"
import { TopBar } from "../TopBar"
import { S } from "../../State"
import { Button, Spinner, Body2 } from "@fluentui/react-components";
import { CallRegular, CallEndRegular } from "@fluentui/react-icons"

const VoiceChatView = observer(() =>
    <div className="main_voice_chat">
        <TopBar />
        <div className="spinner_row">
            <Spinner
                className="spinner"
                labelPosition="below"
                label={S.voice_chat.status}
            />
            <Body2>{S.voice_chat.prompt}</Body2>
        </div>
        <div className="icon_row">
            {
                !S.voice_chat.is_recording && !S.voice_chat.is_generating && !S.voice_chat.is_speaking ?
                    <div className="icon_col">
                        <Button
                            className="icon_button"
                            appearance="subtle"
                            size="large"
                            icon={<CallRegular />}
                            onClick={() => S.voice_chat.onClickCallIcon()}
                        />
                    </div>
                    :
                    null
            }
            {
                S.voice_chat.is_recording ?
                    <div className="icon_col">
                        <Button
                            className="icon_button"
                            appearance="subtle"
                            size="large"
                            icon={<CallEndRegular />}
                            onClick={() => S.voice_chat.onClickStopIcon()}
                        />
                    </div>
                    :
                    null
            }
        </div>
    </div>
)

export { VoiceChatView }