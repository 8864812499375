import { computed, makeObservable, observable, runInAction } from "mobx"

class Checkpoint {
    checkpoint_id: number
    checkpoint_name: string
    checkpoint_description: string
    nsfw: boolean
    tags: string[]
    versions: proto.abaoai.CheckpointVersionPayload[]
    selected_version_id: number
    constructor(checkpoint: proto.abaoai.CheckpointPayload) {
        this.checkpoint_id = checkpoint.getCheckpointId()
        this.checkpoint_name = checkpoint.getCheckpointName()
        this.checkpoint_description = checkpoint.getCheckpointDescription()
        this.nsfw = checkpoint.getNsfw()
        this.tags = checkpoint.getTagsList()
        this.versions = checkpoint.getVersionsList()
        this.selected_version_id = this.versions[0].getVersionId()
        makeObservable(this, {
            selected_version_id: observable,
            selected_version: computed,
        })
    }

    get selected_version() {
        let version = this.versions.find((version) => version.getVersionId() === this.selected_version_id)
        return version
    }

    onTabSelect(version_id: number) {
        runInAction(() => {
            this.selected_version_id = version_id
        })
    }

}

export { Checkpoint }