import data from "./country_codes.json"

interface Item {
  code: string,
  name: string,
}

let COUNTRY_CODES: Item[] = []

for (let item of data) {
  if (item.code.includes(" ")) {
    continue;
  }
  COUNTRY_CODES.push(item)
}

COUNTRY_CODES.sort((a, b) => +a.code < +b.code ? -1 : 1)

export { COUNTRY_CODES }