import { observer } from "mobx-react-lite"
import { TopBar } from "../TopBar"
import { S } from "../../State"
import {
    Card,
    CardHeader,
    Button,
    CardPreview,
    CardFooter,
    Tab,
    TabList,
    Link,
} from "@fluentui/react-components";
import {
    InfoRegular,
} from "@fluentui/react-icons";
import ImageGallery from "react-image-gallery";
import { GalleryItem } from "../../data/GalleryItem"
import { Checkpoint } from "../../data/Checkpoint";
import InfiniteScroll from 'react-infinite-scroll-component';
import SimpleBar from "simplebar-react";

function items(checkpoint: Checkpoint): GalleryItem[] {
    let res: GalleryItem[] = []
    for (let version of checkpoint.versions) {
        if (version.getVersionId() !== checkpoint.selected_version_id) {
            continue;
        }
        for (let image of version.getVersionImagesList()) {
            res.push({ original: image.getUrl() })
        }
    }
    return res
}


const CheckpointView = observer(() =>
    <div className="main_checkpoint">
        <TopBar />
        <div id="checkpoints_slider" className="checkpoints_row">
            <InfiniteScroll
                scrollableTarget="checkpoints_slider"
                dataLength={S.checkpoint.checkpoints.length}
                next={() => S.checkpoint.fetchMoreCheckpoints(false)}
                hasMore={S.checkpoint.has_more}
                loader={<h5>{S.checkpoint.loader}</h5>}
                endMessage={<h5>{S.checkpoint.end}</h5>}
            >
                <div className="checkpoints_gallery">
                    {
                        S.checkpoint.checkpoints.map((checkpoint) =>
                            <div className="checkpoint_frame">
                                <Card
                                    className="checkpoint_card"
                                    key={checkpoint.checkpoint_id}
                                    appearance="filled-alternative"
                                >
                                    <CardHeader className="checkpoint_header"
                                        header={checkpoint.checkpoint_name}
                                        description={""}
                                        action={
                                            <Button
                                                appearance="transparent"
                                                icon={<InfoRegular />}
                                                onClick={() => S.checkpoint.onClickInfo(checkpoint.checkpoint_id)}
                                            />
                                        }
                                    />
                                    <CardPreview className="checkpoint_preview">
                                        <Link>
                                            <ImageGallery
                                                infinite={true}
                                                useBrowserFullscreen={false}
                                                showPlayButton={false}
                                                showIndex={true}
                                                items={items(checkpoint)}
                                                onClick={() => { S.main.onClickCheckpointVersion(checkpoint, checkpoint.selected_version!) }}
                                            />
                                        </Link>
                                    </CardPreview>
                                    <CardFooter
                                        className="checkpoint_footer"
                                    >
                                        <SimpleBar className="checkpoint_versions"
                                        >
                                            <TabList
                                                appearance="subtle"
                                                vertical={true}
                                                selectedValue={checkpoint.selected_version_id}
                                                onTabSelect={(_, { value }) => checkpoint.onTabSelect(value as number)}
                                            >
                                                {
                                                    checkpoint.versions.map(version =>
                                                        <Tab
                                                            className="checkpoint_version"
                                                            key={version.getVersionId()}
                                                            value={version.getVersionId()}
                                                        >
                                                            {version.getVersionName()}
                                                        </Tab>
                                                    )
                                                }
                                            </TabList>
                                        </SimpleBar>
                                    </CardFooter>
                                </Card>
                            </div>
                        )
                    }
                </div>
            </InfiniteScroll>
        </div>
    </div >
)



export { CheckpointView }