import { observer } from "mobx-react-lite"
import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    Drawer,
    Button,
} from "@fluentui/react-components"
import { DismissRegular } from "@fluentui/react-icons"
import { S } from "../State"
import { ChatPanel } from "./chat/ChatPanel"
import { ImagePanel } from "./image/ImagePanel"
import { VoiceChatPanel } from "./voice_chat/VoiceChatPanel"
import { CheckpointPanel } from "./checkpoint/CheckpointPanel"
import { CheckPanel } from "./check/CheckPanel"

const RightPanel = observer(() =>
    <Drawer
        type={S.is_mobile ? "overlay" : "inline"}
        position="end"
        size={S.is_mobile ? "full" : "small"}
        open={S.main.right_drawer_is_open}
        onOpenChange={(_, { open }) => S.main.onOpenChangeRight(open)}
        defaultOpen
        separator
    >
        <DrawerHeader>
            <DrawerHeaderTitle
                className="panel_header"
                action={
                    <Button
                        appearance="subtle"
                        aria-label="Close"
                        icon={<DismissRegular />}
                        onClick={() => S.main.onDismissRightDrawer()}
                    />
                }
            >
                {
                    S.main.view === "chat" ? S.chat.right_title : null
                }
                {
                    S.main.view === "image" ? S.image.right_title : null
                }
                {
                    S.main.view === "voice_chat" ? S.voice_chat.right_title : null
                }
                {
                    S.main.view === "checkpoint" ? S.checkpoint.right_title : null
                }
                {
                    S.main.view === "check" ? S.check.right_title : null
                }

            </DrawerHeaderTitle>
        </DrawerHeader>
        <DrawerBody>
            {
                S.main.view === "chat" ? <ChatPanel /> : null
            }
            {
                S.main.view === "image" ? <ImagePanel /> : null
            }
            {
                S.main.view === "voice_chat" ? <VoiceChatPanel /> : null
            }
            {
                S.main.view === "checkpoint" ? <CheckpointPanel /> : null
            }
            {
                S.main.view === "check" ? <CheckPanel /> : null
            }

        </DrawerBody>
    </Drawer >
)
export { RightPanel }