/**
 * @fileoverview gRPC-Web generated client stub for abaoai
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: chat.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var image_pb = require('./image_pb.js')
const proto = {};
proto.abaoai = require('./chat_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.abaoai.ChatServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.abaoai.ChatServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.ChatPayload,
 *   !proto.abaoai.ChatPayload>}
 */
const methodDescriptor_ChatService_CreateChat = new grpc.web.MethodDescriptor(
  '/abaoai.ChatService/CreateChat',
  grpc.web.MethodType.UNARY,
  proto.abaoai.ChatPayload,
  proto.abaoai.ChatPayload,
  /**
   * @param {!proto.abaoai.ChatPayload} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.ChatPayload.deserializeBinary
);


/**
 * @param {!proto.abaoai.ChatPayload} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.ChatPayload)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.ChatPayload>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.ChatServiceClient.prototype.createChat =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.ChatService/CreateChat',
      request,
      metadata || {},
      methodDescriptor_ChatService_CreateChat,
      callback);
};


/**
 * @param {!proto.abaoai.ChatPayload} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.ChatPayload>}
 *     Promise that resolves to the response
 */
proto.abaoai.ChatServicePromiseClient.prototype.createChat =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.ChatService/CreateChat',
      request,
      metadata || {},
      methodDescriptor_ChatService_CreateChat);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.ChatPayload,
 *   !proto.abaoai.ChatPayload>}
 */
const methodDescriptor_ChatService_GetChat = new grpc.web.MethodDescriptor(
  '/abaoai.ChatService/GetChat',
  grpc.web.MethodType.UNARY,
  proto.abaoai.ChatPayload,
  proto.abaoai.ChatPayload,
  /**
   * @param {!proto.abaoai.ChatPayload} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.ChatPayload.deserializeBinary
);


/**
 * @param {!proto.abaoai.ChatPayload} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.ChatPayload)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.ChatPayload>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.ChatServiceClient.prototype.getChat =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.ChatService/GetChat',
      request,
      metadata || {},
      methodDescriptor_ChatService_GetChat,
      callback);
};


/**
 * @param {!proto.abaoai.ChatPayload} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.ChatPayload>}
 *     Promise that resolves to the response
 */
proto.abaoai.ChatServicePromiseClient.prototype.getChat =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.ChatService/GetChat',
      request,
      metadata || {},
      methodDescriptor_ChatService_GetChat);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.ChatPayload,
 *   !proto.abaoai.ChatHistoryPayload>}
 */
const methodDescriptor_ChatService_HideChat = new grpc.web.MethodDescriptor(
  '/abaoai.ChatService/HideChat',
  grpc.web.MethodType.UNARY,
  proto.abaoai.ChatPayload,
  proto.abaoai.ChatHistoryPayload,
  /**
   * @param {!proto.abaoai.ChatPayload} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.ChatHistoryPayload.deserializeBinary
);


/**
 * @param {!proto.abaoai.ChatPayload} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.ChatHistoryPayload)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.ChatHistoryPayload>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.ChatServiceClient.prototype.hideChat =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.ChatService/HideChat',
      request,
      metadata || {},
      methodDescriptor_ChatService_HideChat,
      callback);
};


/**
 * @param {!proto.abaoai.ChatPayload} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.ChatHistoryPayload>}
 *     Promise that resolves to the response
 */
proto.abaoai.ChatServicePromiseClient.prototype.hideChat =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.ChatService/HideChat',
      request,
      metadata || {},
      methodDescriptor_ChatService_HideChat);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.DialogPayload,
 *   !proto.abaoai.DialogPayload>}
 */
const methodDescriptor_ChatService_CreateDialog = new grpc.web.MethodDescriptor(
  '/abaoai.ChatService/CreateDialog',
  grpc.web.MethodType.UNARY,
  proto.abaoai.DialogPayload,
  proto.abaoai.DialogPayload,
  /**
   * @param {!proto.abaoai.DialogPayload} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.DialogPayload.deserializeBinary
);


/**
 * @param {!proto.abaoai.DialogPayload} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.DialogPayload)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.DialogPayload>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.ChatServiceClient.prototype.createDialog =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.ChatService/CreateDialog',
      request,
      metadata || {},
      methodDescriptor_ChatService_CreateDialog,
      callback);
};


/**
 * @param {!proto.abaoai.DialogPayload} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.DialogPayload>}
 *     Promise that resolves to the response
 */
proto.abaoai.ChatServicePromiseClient.prototype.createDialog =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.ChatService/CreateDialog',
      request,
      metadata || {},
      methodDescriptor_ChatService_CreateDialog);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.DialogPayload,
 *   !proto.abaoai.DialogPayload>}
 */
const methodDescriptor_ChatService_GetDialog = new grpc.web.MethodDescriptor(
  '/abaoai.ChatService/GetDialog',
  grpc.web.MethodType.UNARY,
  proto.abaoai.DialogPayload,
  proto.abaoai.DialogPayload,
  /**
   * @param {!proto.abaoai.DialogPayload} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.DialogPayload.deserializeBinary
);


/**
 * @param {!proto.abaoai.DialogPayload} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.DialogPayload)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.DialogPayload>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.ChatServiceClient.prototype.getDialog =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.ChatService/GetDialog',
      request,
      metadata || {},
      methodDescriptor_ChatService_GetDialog,
      callback);
};


/**
 * @param {!proto.abaoai.DialogPayload} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.DialogPayload>}
 *     Promise that resolves to the response
 */
proto.abaoai.ChatServicePromiseClient.prototype.getDialog =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.ChatService/GetDialog',
      request,
      metadata || {},
      methodDescriptor_ChatService_GetDialog);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.DialogPayload,
 *   !proto.abaoai.DialogPayload>}
 */
const methodDescriptor_ChatService_ListenDialogToken = new grpc.web.MethodDescriptor(
  '/abaoai.ChatService/ListenDialogToken',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.abaoai.DialogPayload,
  proto.abaoai.DialogPayload,
  /**
   * @param {!proto.abaoai.DialogPayload} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.DialogPayload.deserializeBinary
);


/**
 * @param {!proto.abaoai.DialogPayload} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.DialogPayload>}
 *     The XHR Node Readable Stream
 */
proto.abaoai.ChatServiceClient.prototype.listenDialogToken =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/abaoai.ChatService/ListenDialogToken',
      request,
      metadata || {},
      methodDescriptor_ChatService_ListenDialogToken);
};


/**
 * @param {!proto.abaoai.DialogPayload} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.DialogPayload>}
 *     The XHR Node Readable Stream
 */
proto.abaoai.ChatServicePromiseClient.prototype.listenDialogToken =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/abaoai.ChatService/ListenDialogToken',
      request,
      metadata || {},
      methodDescriptor_ChatService_ListenDialogToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.ChatSummaryPayload,
 *   !proto.abaoai.ChatSummaryPayload>}
 */
const methodDescriptor_ChatService_GetChatSummary = new grpc.web.MethodDescriptor(
  '/abaoai.ChatService/GetChatSummary',
  grpc.web.MethodType.UNARY,
  proto.abaoai.ChatSummaryPayload,
  proto.abaoai.ChatSummaryPayload,
  /**
   * @param {!proto.abaoai.ChatSummaryPayload} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.ChatSummaryPayload.deserializeBinary
);


/**
 * @param {!proto.abaoai.ChatSummaryPayload} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.ChatSummaryPayload)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.ChatSummaryPayload>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.ChatServiceClient.prototype.getChatSummary =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.ChatService/GetChatSummary',
      request,
      metadata || {},
      methodDescriptor_ChatService_GetChatSummary,
      callback);
};


/**
 * @param {!proto.abaoai.ChatSummaryPayload} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.ChatSummaryPayload>}
 *     Promise that resolves to the response
 */
proto.abaoai.ChatServicePromiseClient.prototype.getChatSummary =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.ChatService/GetChatSummary',
      request,
      metadata || {},
      methodDescriptor_ChatService_GetChatSummary);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.ChatHistoryPayload,
 *   !proto.abaoai.ChatHistoryPayload>}
 */
const methodDescriptor_ChatService_LoadChatHistory = new grpc.web.MethodDescriptor(
  '/abaoai.ChatService/LoadChatHistory',
  grpc.web.MethodType.UNARY,
  proto.abaoai.ChatHistoryPayload,
  proto.abaoai.ChatHistoryPayload,
  /**
   * @param {!proto.abaoai.ChatHistoryPayload} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.ChatHistoryPayload.deserializeBinary
);


/**
 * @param {!proto.abaoai.ChatHistoryPayload} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.ChatHistoryPayload)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.ChatHistoryPayload>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.ChatServiceClient.prototype.loadChatHistory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.ChatService/LoadChatHistory',
      request,
      metadata || {},
      methodDescriptor_ChatService_LoadChatHistory,
      callback);
};


/**
 * @param {!proto.abaoai.ChatHistoryPayload} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.ChatHistoryPayload>}
 *     Promise that resolves to the response
 */
proto.abaoai.ChatServicePromiseClient.prototype.loadChatHistory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.ChatService/LoadChatHistory',
      request,
      metadata || {},
      methodDescriptor_ChatService_LoadChatHistory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.AudioPayload,
 *   !proto.abaoai.AudioPayload>}
 */
const methodDescriptor_ChatService_CreateAudio = new grpc.web.MethodDescriptor(
  '/abaoai.ChatService/CreateAudio',
  grpc.web.MethodType.UNARY,
  proto.abaoai.AudioPayload,
  proto.abaoai.AudioPayload,
  /**
   * @param {!proto.abaoai.AudioPayload} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.AudioPayload.deserializeBinary
);


/**
 * @param {!proto.abaoai.AudioPayload} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.AudioPayload)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.AudioPayload>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.ChatServiceClient.prototype.createAudio =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.ChatService/CreateAudio',
      request,
      metadata || {},
      methodDescriptor_ChatService_CreateAudio,
      callback);
};


/**
 * @param {!proto.abaoai.AudioPayload} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.AudioPayload>}
 *     Promise that resolves to the response
 */
proto.abaoai.ChatServicePromiseClient.prototype.createAudio =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.ChatService/CreateAudio',
      request,
      metadata || {},
      methodDescriptor_ChatService_CreateAudio);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.ListImagesRequest,
 *   !proto.abaoai.ListImagesResponse>}
 */
const methodDescriptor_ChatService_ListImages = new grpc.web.MethodDescriptor(
  '/abaoai.ChatService/ListImages',
  grpc.web.MethodType.UNARY,
  proto.abaoai.ListImagesRequest,
  proto.abaoai.ListImagesResponse,
  /**
   * @param {!proto.abaoai.ListImagesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.ListImagesResponse.deserializeBinary
);


/**
 * @param {!proto.abaoai.ListImagesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.ListImagesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.ListImagesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.ChatServiceClient.prototype.listImages =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.ChatService/ListImages',
      request,
      metadata || {},
      methodDescriptor_ChatService_ListImages,
      callback);
};


/**
 * @param {!proto.abaoai.ListImagesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.ListImagesResponse>}
 *     Promise that resolves to the response
 */
proto.abaoai.ChatServicePromiseClient.prototype.listImages =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.ChatService/ListImages',
      request,
      metadata || {},
      methodDescriptor_ChatService_ListImages);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.ImagePayload,
 *   !proto.abaoai.ImagePayload>}
 */
const methodDescriptor_ChatService_CreateImage = new grpc.web.MethodDescriptor(
  '/abaoai.ChatService/CreateImage',
  grpc.web.MethodType.UNARY,
  image_pb.ImagePayload,
  image_pb.ImagePayload,
  /**
   * @param {!proto.abaoai.ImagePayload} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  image_pb.ImagePayload.deserializeBinary
);


/**
 * @param {!proto.abaoai.ImagePayload} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.ImagePayload)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.ImagePayload>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.ChatServiceClient.prototype.createImage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.ChatService/CreateImage',
      request,
      metadata || {},
      methodDescriptor_ChatService_CreateImage,
      callback);
};


/**
 * @param {!proto.abaoai.ImagePayload} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.ImagePayload>}
 *     Promise that resolves to the response
 */
proto.abaoai.ChatServicePromiseClient.prototype.createImage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.ChatService/CreateImage',
      request,
      metadata || {},
      methodDescriptor_ChatService_CreateImage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.ImagePayload,
 *   !proto.abaoai.ImagePayload>}
 */
const methodDescriptor_ChatService_ListenImage = new grpc.web.MethodDescriptor(
  '/abaoai.ChatService/ListenImage',
  grpc.web.MethodType.UNARY,
  image_pb.ImagePayload,
  image_pb.ImagePayload,
  /**
   * @param {!proto.abaoai.ImagePayload} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  image_pb.ImagePayload.deserializeBinary
);


/**
 * @param {!proto.abaoai.ImagePayload} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.ImagePayload)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.ImagePayload>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.ChatServiceClient.prototype.listenImage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.ChatService/ListenImage',
      request,
      metadata || {},
      methodDescriptor_ChatService_ListenImage,
      callback);
};


/**
 * @param {!proto.abaoai.ImagePayload} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.ImagePayload>}
 *     Promise that resolves to the response
 */
proto.abaoai.ChatServicePromiseClient.prototype.listenImage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.ChatService/ListenImage',
      request,
      metadata || {},
      methodDescriptor_ChatService_ListenImage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.ImagePayload,
 *   !proto.abaoai.ImagePayload>}
 */
const methodDescriptor_ChatService_HideImage = new grpc.web.MethodDescriptor(
  '/abaoai.ChatService/HideImage',
  grpc.web.MethodType.UNARY,
  image_pb.ImagePayload,
  image_pb.ImagePayload,
  /**
   * @param {!proto.abaoai.ImagePayload} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  image_pb.ImagePayload.deserializeBinary
);


/**
 * @param {!proto.abaoai.ImagePayload} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.ImagePayload)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.ImagePayload>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.ChatServiceClient.prototype.hideImage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.ChatService/HideImage',
      request,
      metadata || {},
      methodDescriptor_ChatService_HideImage,
      callback);
};


/**
 * @param {!proto.abaoai.ImagePayload} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.ImagePayload>}
 *     Promise that resolves to the response
 */
proto.abaoai.ChatServicePromiseClient.prototype.hideImage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.ChatService/HideImage',
      request,
      metadata || {},
      methodDescriptor_ChatService_HideImage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.GetCheckpointOptionsRequest,
 *   !proto.abaoai.GetCheckpointOptionsResponse>}
 */
const methodDescriptor_ChatService_GetCheckpointOptions = new grpc.web.MethodDescriptor(
  '/abaoai.ChatService/GetCheckpointOptions',
  grpc.web.MethodType.UNARY,
  proto.abaoai.GetCheckpointOptionsRequest,
  proto.abaoai.GetCheckpointOptionsResponse,
  /**
   * @param {!proto.abaoai.GetCheckpointOptionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.GetCheckpointOptionsResponse.deserializeBinary
);


/**
 * @param {!proto.abaoai.GetCheckpointOptionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.GetCheckpointOptionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.GetCheckpointOptionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.ChatServiceClient.prototype.getCheckpointOptions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.ChatService/GetCheckpointOptions',
      request,
      metadata || {},
      methodDescriptor_ChatService_GetCheckpointOptions,
      callback);
};


/**
 * @param {!proto.abaoai.GetCheckpointOptionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.GetCheckpointOptionsResponse>}
 *     Promise that resolves to the response
 */
proto.abaoai.ChatServicePromiseClient.prototype.getCheckpointOptions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.ChatService/GetCheckpointOptions',
      request,
      metadata || {},
      methodDescriptor_ChatService_GetCheckpointOptions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.GetCheckpointVersionOptionsRequest,
 *   !proto.abaoai.GetCheckpointVersionOptionsResponse>}
 */
const methodDescriptor_ChatService_GetCheckpointVersionOptions = new grpc.web.MethodDescriptor(
  '/abaoai.ChatService/GetCheckpointVersionOptions',
  grpc.web.MethodType.UNARY,
  proto.abaoai.GetCheckpointVersionOptionsRequest,
  proto.abaoai.GetCheckpointVersionOptionsResponse,
  /**
   * @param {!proto.abaoai.GetCheckpointVersionOptionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.GetCheckpointVersionOptionsResponse.deserializeBinary
);


/**
 * @param {!proto.abaoai.GetCheckpointVersionOptionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.GetCheckpointVersionOptionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.GetCheckpointVersionOptionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.ChatServiceClient.prototype.getCheckpointVersionOptions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.ChatService/GetCheckpointVersionOptions',
      request,
      metadata || {},
      methodDescriptor_ChatService_GetCheckpointVersionOptions,
      callback);
};


/**
 * @param {!proto.abaoai.GetCheckpointVersionOptionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.GetCheckpointVersionOptionsResponse>}
 *     Promise that resolves to the response
 */
proto.abaoai.ChatServicePromiseClient.prototype.getCheckpointVersionOptions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.ChatService/GetCheckpointVersionOptions',
      request,
      metadata || {},
      methodDescriptor_ChatService_GetCheckpointVersionOptions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.GetCheckpointVersionFileRequest,
 *   !proto.abaoai.GetCheckpointVersionFileResponse>}
 */
const methodDescriptor_ChatService_GetCheckpointVersionFile = new grpc.web.MethodDescriptor(
  '/abaoai.ChatService/GetCheckpointVersionFile',
  grpc.web.MethodType.UNARY,
  proto.abaoai.GetCheckpointVersionFileRequest,
  proto.abaoai.GetCheckpointVersionFileResponse,
  /**
   * @param {!proto.abaoai.GetCheckpointVersionFileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.GetCheckpointVersionFileResponse.deserializeBinary
);


/**
 * @param {!proto.abaoai.GetCheckpointVersionFileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.GetCheckpointVersionFileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.GetCheckpointVersionFileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.ChatServiceClient.prototype.getCheckpointVersionFile =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.ChatService/GetCheckpointVersionFile',
      request,
      metadata || {},
      methodDescriptor_ChatService_GetCheckpointVersionFile,
      callback);
};


/**
 * @param {!proto.abaoai.GetCheckpointVersionFileRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.GetCheckpointVersionFileResponse>}
 *     Promise that resolves to the response
 */
proto.abaoai.ChatServicePromiseClient.prototype.getCheckpointVersionFile =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.ChatService/GetCheckpointVersionFile',
      request,
      metadata || {},
      methodDescriptor_ChatService_GetCheckpointVersionFile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.SearchCheckpointsRequest,
 *   !proto.abaoai.SearchCheckpointsResponse>}
 */
const methodDescriptor_ChatService_SearchCheckpoints = new grpc.web.MethodDescriptor(
  '/abaoai.ChatService/SearchCheckpoints',
  grpc.web.MethodType.UNARY,
  proto.abaoai.SearchCheckpointsRequest,
  proto.abaoai.SearchCheckpointsResponse,
  /**
   * @param {!proto.abaoai.SearchCheckpointsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.SearchCheckpointsResponse.deserializeBinary
);


/**
 * @param {!proto.abaoai.SearchCheckpointsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.SearchCheckpointsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.SearchCheckpointsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.ChatServiceClient.prototype.searchCheckpoints =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.ChatService/SearchCheckpoints',
      request,
      metadata || {},
      methodDescriptor_ChatService_SearchCheckpoints,
      callback);
};


/**
 * @param {!proto.abaoai.SearchCheckpointsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.SearchCheckpointsResponse>}
 *     Promise that resolves to the response
 */
proto.abaoai.ChatServicePromiseClient.prototype.searchCheckpoints =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.ChatService/SearchCheckpoints',
      request,
      metadata || {},
      methodDescriptor_ChatService_SearchCheckpoints);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.SearchCheckpointImagesRequest,
 *   !proto.abaoai.SearchCheckpointImagesResponse>}
 */
const methodDescriptor_ChatService_SearchCheckpointImages = new grpc.web.MethodDescriptor(
  '/abaoai.ChatService/SearchCheckpointImages',
  grpc.web.MethodType.UNARY,
  proto.abaoai.SearchCheckpointImagesRequest,
  proto.abaoai.SearchCheckpointImagesResponse,
  /**
   * @param {!proto.abaoai.SearchCheckpointImagesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.SearchCheckpointImagesResponse.deserializeBinary
);


/**
 * @param {!proto.abaoai.SearchCheckpointImagesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.SearchCheckpointImagesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.SearchCheckpointImagesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.ChatServiceClient.prototype.searchCheckpointImages =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.ChatService/SearchCheckpointImages',
      request,
      metadata || {},
      methodDescriptor_ChatService_SearchCheckpointImages,
      callback);
};


/**
 * @param {!proto.abaoai.SearchCheckpointImagesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.SearchCheckpointImagesResponse>}
 *     Promise that resolves to the response
 */
proto.abaoai.ChatServicePromiseClient.prototype.searchCheckpointImages =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.ChatService/SearchCheckpointImages',
      request,
      metadata || {},
      methodDescriptor_ChatService_SearchCheckpointImages);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.CheckPayload,
 *   !proto.abaoai.CheckPayload>}
 */
const methodDescriptor_ChatService_CreateCheck = new grpc.web.MethodDescriptor(
  '/abaoai.ChatService/CreateCheck',
  grpc.web.MethodType.UNARY,
  proto.abaoai.CheckPayload,
  proto.abaoai.CheckPayload,
  /**
   * @param {!proto.abaoai.CheckPayload} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.CheckPayload.deserializeBinary
);


/**
 * @param {!proto.abaoai.CheckPayload} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.CheckPayload)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.CheckPayload>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.ChatServiceClient.prototype.createCheck =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.ChatService/CreateCheck',
      request,
      metadata || {},
      methodDescriptor_ChatService_CreateCheck,
      callback);
};


/**
 * @param {!proto.abaoai.CheckPayload} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.CheckPayload>}
 *     Promise that resolves to the response
 */
proto.abaoai.ChatServicePromiseClient.prototype.createCheck =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.ChatService/CreateCheck',
      request,
      metadata || {},
      methodDescriptor_ChatService_CreateCheck);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.ListChecksRequest,
 *   !proto.abaoai.ListChecksResponse>}
 */
const methodDescriptor_ChatService_ListChecks = new grpc.web.MethodDescriptor(
  '/abaoai.ChatService/ListChecks',
  grpc.web.MethodType.UNARY,
  proto.abaoai.ListChecksRequest,
  proto.abaoai.ListChecksResponse,
  /**
   * @param {!proto.abaoai.ListChecksRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.ListChecksResponse.deserializeBinary
);


/**
 * @param {!proto.abaoai.ListChecksRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.ListChecksResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.ListChecksResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.ChatServiceClient.prototype.listChecks =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.ChatService/ListChecks',
      request,
      metadata || {},
      methodDescriptor_ChatService_ListChecks,
      callback);
};


/**
 * @param {!proto.abaoai.ListChecksRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.ListChecksResponse>}
 *     Promise that resolves to the response
 */
proto.abaoai.ChatServicePromiseClient.prototype.listChecks =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.ChatService/ListChecks',
      request,
      metadata || {},
      methodDescriptor_ChatService_ListChecks);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.PromptPayload,
 *   !proto.abaoai.PromptPayload>}
 */
const methodDescriptor_ChatService_AssignPrompt = new grpc.web.MethodDescriptor(
  '/abaoai.ChatService/AssignPrompt',
  grpc.web.MethodType.UNARY,
  proto.abaoai.PromptPayload,
  proto.abaoai.PromptPayload,
  /**
   * @param {!proto.abaoai.PromptPayload} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.PromptPayload.deserializeBinary
);


/**
 * @param {!proto.abaoai.PromptPayload} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.PromptPayload)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.PromptPayload>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.ChatServiceClient.prototype.assignPrompt =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.ChatService/AssignPrompt',
      request,
      metadata || {},
      methodDescriptor_ChatService_AssignPrompt,
      callback);
};


/**
 * @param {!proto.abaoai.PromptPayload} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.PromptPayload>}
 *     Promise that resolves to the response
 */
proto.abaoai.ChatServicePromiseClient.prototype.assignPrompt =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.ChatService/AssignPrompt',
      request,
      metadata || {},
      methodDescriptor_ChatService_AssignPrompt);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.DialogPayload,
 *   !proto.abaoai.DialogPayload>}
 */
const methodDescriptor_ChatService_UpdateDialogOutput = new grpc.web.MethodDescriptor(
  '/abaoai.ChatService/UpdateDialogOutput',
  grpc.web.MethodType.UNARY,
  proto.abaoai.DialogPayload,
  proto.abaoai.DialogPayload,
  /**
   * @param {!proto.abaoai.DialogPayload} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.DialogPayload.deserializeBinary
);


/**
 * @param {!proto.abaoai.DialogPayload} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.DialogPayload)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.DialogPayload>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.ChatServiceClient.prototype.updateDialogOutput =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.ChatService/UpdateDialogOutput',
      request,
      metadata || {},
      methodDescriptor_ChatService_UpdateDialogOutput,
      callback);
};


/**
 * @param {!proto.abaoai.DialogPayload} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.DialogPayload>}
 *     Promise that resolves to the response
 */
proto.abaoai.ChatServicePromiseClient.prototype.updateDialogOutput =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.ChatService/UpdateDialogOutput',
      request,
      metadata || {},
      methodDescriptor_ChatService_UpdateDialogOutput);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.ImagePayload,
 *   !proto.abaoai.ImagePayload>}
 */
const methodDescriptor_ChatService_AssignImage = new grpc.web.MethodDescriptor(
  '/abaoai.ChatService/AssignImage',
  grpc.web.MethodType.UNARY,
  image_pb.ImagePayload,
  image_pb.ImagePayload,
  /**
   * @param {!proto.abaoai.ImagePayload} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  image_pb.ImagePayload.deserializeBinary
);


/**
 * @param {!proto.abaoai.ImagePayload} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.ImagePayload)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.ImagePayload>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.ChatServiceClient.prototype.assignImage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.ChatService/AssignImage',
      request,
      metadata || {},
      methodDescriptor_ChatService_AssignImage,
      callback);
};


/**
 * @param {!proto.abaoai.ImagePayload} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.ImagePayload>}
 *     Promise that resolves to the response
 */
proto.abaoai.ChatServicePromiseClient.prototype.assignImage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.ChatService/AssignImage',
      request,
      metadata || {},
      methodDescriptor_ChatService_AssignImage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.ImagePayload,
 *   !proto.abaoai.ImagePayload>}
 */
const methodDescriptor_ChatService_UpdateImage = new grpc.web.MethodDescriptor(
  '/abaoai.ChatService/UpdateImage',
  grpc.web.MethodType.UNARY,
  image_pb.ImagePayload,
  image_pb.ImagePayload,
  /**
   * @param {!proto.abaoai.ImagePayload} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  image_pb.ImagePayload.deserializeBinary
);


/**
 * @param {!proto.abaoai.ImagePayload} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.ImagePayload)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.ImagePayload>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.ChatServiceClient.prototype.updateImage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.ChatService/UpdateImage',
      request,
      metadata || {},
      methodDescriptor_ChatService_UpdateImage,
      callback);
};


/**
 * @param {!proto.abaoai.ImagePayload} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.ImagePayload>}
 *     Promise that resolves to the response
 */
proto.abaoai.ChatServicePromiseClient.prototype.updateImage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.ChatService/UpdateImage',
      request,
      metadata || {},
      methodDescriptor_ChatService_UpdateImage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.ChatPayload,
 *   !proto.abaoai.ChatPayload>}
 */
const methodDescriptor_ChatService_DeleteChat = new grpc.web.MethodDescriptor(
  '/abaoai.ChatService/DeleteChat',
  grpc.web.MethodType.UNARY,
  proto.abaoai.ChatPayload,
  proto.abaoai.ChatPayload,
  /**
   * @param {!proto.abaoai.ChatPayload} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.ChatPayload.deserializeBinary
);


/**
 * @param {!proto.abaoai.ChatPayload} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.ChatPayload)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.ChatPayload>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.ChatServiceClient.prototype.deleteChat =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.ChatService/DeleteChat',
      request,
      metadata || {},
      methodDescriptor_ChatService_DeleteChat,
      callback);
};


/**
 * @param {!proto.abaoai.ChatPayload} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.ChatPayload>}
 *     Promise that resolves to the response
 */
proto.abaoai.ChatServicePromiseClient.prototype.deleteChat =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.ChatService/DeleteChat',
      request,
      metadata || {},
      methodDescriptor_ChatService_DeleteChat);
};


module.exports = proto.abaoai;

