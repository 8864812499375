import { observer } from "mobx-react-lite"
import "../../proto/chat_pb"
import { MainDialogData } from "../../data/MainDialogData"
import { S } from "../../State"
import ReactMarkdown from 'react-markdown'
import { SettingsRegular, PersonChatRegular, BotSparkleRegular } from "@fluentui/react-icons"

type ChatDialogProps = {
  dialog: MainDialogData;
};

const ChatSystem = observer(() =>
  <div
    className="dialog_item"
  >
    <div className="input_row">
      <div className="role">
        <SettingsRegular />
      </div>
      <ReactMarkdown className="content">
        {S.chat.system_prompt}
      </ReactMarkdown>
    </div>
  </div>
)

const ChatDialog = observer(({ dialog }: ChatDialogProps) =>
  <div
    className="dialog_item"
  >
    <div className="input_row">
      <div className="role">
        <PersonChatRegular />
      </div>
      <ReactMarkdown className="content">
        {dialog.input}
      </ReactMarkdown>
    </div>
    <div className="output_row">
      <div className="role">
        <BotSparkleRegular />
      </div>
      <ReactMarkdown className="content">
        {dialog.output + (S.chat.streaming_dialog_id === dialog.dialog_id ? "●" : "")}
      </ReactMarkdown>
    </div>
  </div>
)

export { ChatDialog, ChatSystem }