import { observer } from "mobx-react-lite"
import { S } from "../State"
import {
    Link,
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Button,
} from "@fluentui/react-components";

const ProductDialog = observer(() =>
    <Dialog
        open={S.main.stripe_dialog_is_open}
        onOpenChange={() => S.main.onDismissProductDialog()}
    >
        <DialogSurface>
            <DialogBody>
                <DialogTitle>{S.main.product_name}</DialogTitle>
                <DialogContent>
                    <p>
                        <Link onClick={() => S.main.onClickManageLink()}>Manage my subscription</Link>
                    </p>
                    <p>
                        <Link href="mailto:sales@pytorchsoft.com">Customer service</Link>
                    </p>
                </DialogContent>

                <DialogActions>
                    {/* DialogTrigger inside of a Dialog still works properly */}
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary">Close</Button>
                    </DialogTrigger>
                    <Button
                        appearance="primary"
                        onClick={() => S.main.onClickUpgrade()}
                    >
                        Update your plan
                    </Button>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog>
)

export { ProductDialog }