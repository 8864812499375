import { observer } from "mobx-react-lite"
import {
    Textarea,
    Button,
} from "@fluentui/react-components"
import { S } from "../../State"
import { ChatConfig } from "./ChatConfig";

const ChatPanel = observer(() =>
    <div className="chat_panel">
        <div className="chat_config_row">
            {S.main.chat_id === 0 ? <ChatConfig /> : null}
            <div className="blank_row"></div>
            <div className="prompt_row">
                <Textarea
                    rows={12}
                    className="prompt"
                    value={S.chat.prompt}
                    onChange={(_, { value }) => S.chat.onChangePrompt(value!)}
                >
                </Textarea>
            </div>
        </div>

        <div className="send_row">
            <Button
                appearance="primary"
                disabled={S.chat.prompt.length === 0 || S.chat.streaming}
                className="send"
                onClick={() => S.chat.onClickSend()}
            >
                Send
            </Button>
        </div>
    </div>
)

export { ChatPanel }