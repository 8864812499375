import { observer } from "mobx-react-lite"
import { Link, Button, Input, Field } from "@fluentui/react-components"
import { S } from "../State"
import {
    SendRegular,
} from "@fluentui/react-icons";

const ResetPage = observer(() =>
    <div className="reset_page">
        <div className="hero">

            <h2 className="title">abao.ai</h2>

            <h1 className="title">Reset your password</h1>

            <Field
                className="textfield"
                label="Name"
                required={true}
                validationMessage={S.reset.name_error_message}
            >
                <Input
                    onChange={(_, { value }) => S.reset.onChangeName(value)}
                />
            </Field>

            <Field
                className="textfield"
                label="Email"
                validationMessage={S.reset.email_error_message}
                required
            >
                <Input
                    autoComplete="username"
                    onChange={(_, { value }) => S.reset.onChangeEmail(value)}
                />
            </Field>

            <div className="email_code_row">
                <Field
                    className="code"
                    label="Email verification code"
                    required={true}
                    validationMessage={S.reset.code_error_message}
                >
                    <Input
                        type="number"
                        onChange={(_, { value }) => S.reset.onChangeCode(value)}
                        required={true}
                        contentAfter={
                            S.reset.email_countdown === 0 ?
                                <Button
                                    icon={<SendRegular />}
                                    appearance="transparent"
                                    onClick={() => S.reset.onClickSendCode()}
                                />
                                :
                                S.reset.email_countdown + "s"
                        }
                    />
                </Field>
            </div>

            <Field
                className="textfield"
                label="New Password"
                validationMessage={S.reset.password_error_message}
                required
            >
                <Input
                    type="password"
                    autoComplete="current-password"
                    onChange={(_, { value }) => S.reset.onChangePassword(value)}
                />
            </Field>

            <div className="continue_row">
                <Button
                    appearance="primary"
                    className="continue_button"
                    onClick={() => S.reset.onClickContinue()}
                >
                    Continue
                </Button>

            </div>

            <div className="auth_row">
                <Link className="login_link" onClick={() => S.onClickPage("AUTH")}>Back to Apps Client</Link>
            </div>

        </div>
    </div >
)


export { ResetPage }
