import { computed, makeObservable, observable, runInAction } from "mobx"
import { S } from "../State"
import { CHAT, ACCESS } from "../Client"
import { MainDialogData } from "../data/MainDialogData"
import grpcWeb from "grpc-web"
import { MainChatSummaryData } from "../data/MainChatSummaryData"

class ChatState {
    models: Record<string, proto.abaoai.ModelPayload> = {}
    selected_model_id: string = ""
    prompt: string = ""
    system_prompt: string = ""
    dialogs: MainDialogData[] = []
    delete_dialog_is_open: boolean = false
    delete_dialog_summary: string = ""
    streaming: boolean = false
    streaming_dialog_id: number = 0
    right_title: string = ""
    bottom_drawer_is_open: boolean = false

    constructor() {
        makeObservable(this, {
            models: observable,
            selected_model_id: observable,
            prompt: observable,
            system_prompt: observable,
            delete_dialog_is_open: observable,
            delete_dialog_summary: observable,
            streaming: observable,
            streaming_dialog_id: observable,
            dialogs: observable,
            model_options: computed,
            has_system_prompt: computed,
            right_title: observable,
            bottom_drawer_is_open: observable,
        })
    }

    get model_options() {
        let res = [{ key: "", text: "-" }]
        let active_models = []
        for (let model_id in this.models) {
            let model = this.models[model_id]
            if (model.getInactive()) {
                continue
            }
            active_models.push(model)
        }
        active_models.sort((a, b) => a.getFunctionId() - b.getFunctionId())
        for (let model of active_models) {
            let option = { key: model.getModelId(), text: model.getModelName() }
            res.push(option)
        }
        return res
    }

    get has_system_prompt() {
        let selected_model_id = this.selected_model_id
        if (selected_model_id === "") {
            return false
        }
        let model = this.models[this.selected_model_id]
        if (!model) {
            return false
        }
        let function_id = model.getFunctionId()
        if (function_id === 3) {
            return false
        }
        return true
    }

    onOpenChangeBottom(open: boolean) {
        runInAction(() => {
            this.bottom_drawer_is_open = open
        })
    }

    onDismissBottomDrawer() {
        runInAction(() => {
            this.bottom_drawer_is_open = false
        })
    }

    onClickDelete(summary: string) {
        runInAction(() => {
            this.delete_dialog_is_open = true
            this.delete_dialog_summary = summary
        })
    }

    onDismissDeleteDialog() {
        runInAction(() => {
            this.delete_dialog_is_open = false
        })
    }

    onChangeSystemPrompt(value: string) {
        runInAction(() => {
            this.system_prompt = value
        })
    }

    onChangePrompt(value: string) {
        runInAction(() => {
            this.prompt = value
        })
    }

    onChangeModel(model_id: string) {
        runInAction(() => {
            this.selected_model_id = model_id
        })
    }

    async onClickSend() {
        try {
            let new_chat = S.main.chat_id === 0;
            if (new_chat) {
                if (this.selected_model_id === "") {
                    alert("Select Model")
                    return
                }
                let chat = await S.main.create_chat(
                    S.main.account_id,
                    this.selected_model_id,
                    this.system_prompt,
                )
                let chat_id = chat.getChatId()
                runInAction(() => {
                    S.main.chat_id = chat_id
                })
            }

            let dialog = await S.main.create_dialog(S.main.chat_id, this.prompt)
            let dialog_id = dialog.getDialogId()
            let input = dialog.getInput()
            let dialog_data = new MainDialogData(dialog_id, input, "")

            runInAction(() => {
                S.main.right_drawer_is_open = false
                this.prompt = ""
                this.dialogs.unshift(dialog_data)
                this.streaming = true
                this.streaming_dialog_id = dialog_id
            })
            await this.listen_dialog_update(dialog_id)
            if (new_chat) {
                let req = new proto.abaoai.ChatSummaryPayload()
                req.setChatId(S.main.chat_id)
                let summary: proto.abaoai.ChatSummaryPayload = await CHAT.getChatSummary(req, S.getMetadataMap())
                runInAction(() => {
                    let summary_data = new MainChatSummaryData(S.main.chat_id, summary.getSummary(), summary.getTimestamp())
                    S.main.summaries.unshift(summary_data)
                })
            }
            let req = new proto.abaoai.AccountPayload()
            req.setAccountId(S.getMetadata().account_id);
            let account: proto.abaoai.AccountPayload = await ACCESS.getAccount(req, S.getMetadataMap());
            runInAction(() => {
                S.main.credits = account.getCredits()
            })
        } catch (e) {
            S.onError(e as grpcWeb.RpcError)
        }
    }

    onClickReply() {
        runInAction(() => {
            this.right_title = "Reply"
            S.main.right_drawer_is_open = true
        })
    }

    async loadModels() {
        let req = new proto.abaoai.ModelsPayload()
        let res: proto.abaoai.ModelsPayload = await ACCESS.getModels(req, S.getMetadataMap());
        runInAction(() => {
            for (let model of res.getModelsList()) {
                this.models[model.getModelId()] = model;
            }
        })
    }

    async listen_dialog_update(dialog_id: number) {
        let req = new proto.abaoai.DialogPayload()
        req.setDialogId(dialog_id)
        let stream: grpcWeb.ClientReadableStream<proto.abaoai.DialogPayload> = await CHAT.listenDialogToken(req, S.getMetadataMap())
        stream.on("error", (error) => {
            console.log("error", error)
        })
        stream.on("status", (status) => {
            console.log("status", status)
        })
        stream.on("data", (dialog: proto.abaoai.DialogPayload) => {
            let token = dialog.getToken()
            runInAction(() => {
                let found = S.chat.dialogs.find((dialog) => dialog.dialog_id === dialog_id)
                if (found) {
                    found.output += token
                }
            })
        })
        stream.on("end", () => {
            runInAction(() => {
                this.streaming = false
                this.streaming_dialog_id = 0
            })
        })
    }

}

export { ChatState }