import { runInAction } from "mobx"
import { makeObservable, observable } from "mobx"
import { CHAT } from "../Client"
import { S } from "../State"
import * as grpcWeb from 'grpc-web';
import { createClient, DeepgramClient, LiveSchema, LiveClient, LiveTranscriptionEvent } from "@deepgram/sdk";

let deepgram_api_key = "4e612d43163ac905d6de29c64b753822e621a9e4"

class VoiceChatState {
    right_title: string = ""
    status: string = "Click Call"
    stream: MediaStream | null = null
    microphone: MediaRecorder | null = null
    stream_started: boolean = false
    has_microphone: boolean = false
    is_recording: boolean = false
    is_generating: boolean = false
    is_speaking: boolean = false
    audioContext: AudioContext | null = null
    audioBufferQueue: AudioBuffer[] = [];
    selected_model_id: string = "openai/gpt-3.5-turbo"
    prompt: string = ""
    input: string = ""
    selected_language: string = "en"
    selected_voice: string = "alloy"
    deepgram: DeepgramClient = createClient(deepgram_api_key)
    socket: LiveClient | null = null
    socket_is_open: boolean = false
    model_options: any[] = [
        { key: "openai/gpt-3.5-turbo", text: "GPT-3.5" },
        { key: "openai/gpt-4", text: "GPT-4" },
        { key: "google/gemini-pro", text: "GEMINI-PRO" },
    ]

    language_options = [
        { key: "en-US", text: "English" },
        { key: "zh-CN", text: "Chinese" },
    ]

    voice_options = [
        { key: "alloy", text: "Alloy" },
        { key: "echo", text: "Echo" },
        { key: "fable", text: "Fable" },
        { key: "onyx", text: "Onyx" },
        { key: "nova", text: "Nova" },
        { key: "shimmer", text: "Shimmer" },
    ]

    constructor() {
        makeObservable(this, {
            right_title: observable,
            status: observable,
            prompt: observable,
            has_microphone: observable,
            is_recording: observable,
            is_generating: observable,
            is_speaking: observable,
            selected_model_id: observable,
            selected_voice: observable,
            selected_language: observable,
        })
    }

    onChangeModel(model_id: string) {
        runInAction(() => {
            this.selected_model_id = model_id
        })
    }

    onChangeLanguage(language: string) {
        runInAction(() => {
            this.selected_language = language
        })
    }

    onChangeVoice(voice: string) {
        runInAction(() => {
            this.selected_voice = voice
        })
    }

    onClickCallIcon() {
        runInAction(() => {
            S.main.right_drawer_is_open = true
            this.right_title = "New Voice Chat"
        })
    }

    onClickStopIcon() {
        if (this.socket !== null) {
            this.socket.finish()
        }
        runInAction(() => {
            this.status = "Click Call"
            this.is_recording = false
        })
    }

    onError(e: DOMException) {
        runInAction(() => {
            this.status = e.message
        })
    }

    async onClickCallButton() {
        let chat = await S.main.create_chat(
            S.main.account_id,
            this.selected_model_id,
            "",
        )
        runInAction(() => {
            S.main.right_drawer_is_open = false
            S.main.chat_id = chat.getChatId()
        })
        await this.start_recording()
    }

    async start_mic() {
        if (this.microphone === null) {
            let constraints = {
                audio: {
                    echoCancellation: false,
                    noiseSuppression: true,
                },
                channelCount: 1
            }
            this.stream = await window.navigator.mediaDevices.getUserMedia(constraints)
            this.microphone = new MediaRecorder(this.stream)
        }
        this.microphone.onstart = () => {
            console.log("start")
            runInAction(() => {
                this.status = "Recording"
                this.is_recording = true
                this.prompt = ""
                this.has_microphone = true
            })
        }
        this.microphone.ondataavailable = (e: BlobEvent) => {
            try {
                this.socket?.send(e.data)
            } catch (e) {
                console.log(e)
            }
        }
        this.microphone.onstop = () => {
            console.log("stop")
            runInAction(() => {
                this.is_recording = false
            })
        }
        this.microphone.start(200)
    }

    async start_recording() {
        let option: LiveSchema = {
            model: "base",
            language: this.selected_language,
            interim_results: true
        }
        this.socket = this.deepgram.listen.live(option)
        this.socket.on("open", () => {
            console.log("open")
            this.socket_is_open = true
            this.start_mic()
        })
        this.socket.on("Results", (data: LiveTranscriptionEvent) => {
            let text = data.channel.alternatives[0].transcript
            if (text.length === 0) {
                return
            }
            runInAction(() => {
                this.prompt = text
            })
            if (data.is_final) {
                this.socket?.finish()
                if (this.prompt.length !== 0) {
                    this.create_dialog()
                }
            }
        })
        this.socket.on("error", (e) => console.error(e));
        this.socket.on("warning", (e) => console.warn(e));
        this.socket.on("Metadata", (e) => console.log(e));
        this.socket.on("close", (e) => {
            console.log("close")
            this.socket_is_open = false
            if (this.microphone !== null) {
                this.microphone.stop()
            }
        });
    }

    async create_dialog() {
        this.input = ""
        let dialog = await S.main.create_dialog(S.main.chat_id, this.prompt);
        let dialog_id = dialog.getDialogId()
        let req = new proto.abaoai.DialogPayload()
        req.setDialogId(dialog_id)
        let count = 0;
        runInAction(() => {
            this.is_generating = true
        })
        let stream: grpcWeb.ClientReadableStream<proto.abaoai.DialogPayload> = await CHAT.listenDialogToken(req, S.getMetadataMap())
        stream.on("error", (error) => {
            console.log("error", error)
        })
        stream.on("status", (status) => {
            console.log("status", status)
        })
        stream.on("data", (dialog: proto.abaoai.DialogPayload) => {
            let token = dialog.getToken()
            this.input += token
            count += 1;
            runInAction(() => {
                this.status = "Genrating " + count
            })
        })
        stream.on("end", () => {
            runInAction(() => {
                this.status = "Speaking"
                this.is_generating = false
            })
            this.play()
        })
    }

    async play() {
        let req = new proto.abaoai.AudioPayload();
        req.setModelId("openai/tts-1")
        req.setInput(this.input)
        req.setVoice(this.selected_voice)
        req.setFormat("mp3")
        req.setSpeed(1.0)
        if (this.input.length === 0) {
            return;
        }
        try {
            runInAction(() => {
                this.is_speaking = true
            })
            let res: proto.abaoai.AudioPayload = await CHAT.createAudio(req, S.getMetadataMap());
            let url = res.getUrl()
            let audio = new Audio(url)
            audio.autoplay = true
            audio.onended = () => {
                console.log("ended")
                runInAction(() => {
                    this.is_speaking = false
                })
                this.start_recording()
            }
        } catch (e) {
            this.onError(e as DOMException)
        }
    }



}





export { VoiceChatState }