/**
 * @fileoverview gRPC-Web generated client stub for abaoai
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.28.0
// source: access.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.abaoai = require('./access_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.abaoai.AccessServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.abaoai.AccessServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.LoginRequest,
 *   !proto.abaoai.LoginResponse>}
 */
const methodDescriptor_AccessService_Login = new grpc.web.MethodDescriptor(
  '/abaoai.AccessService/Login',
  grpc.web.MethodType.UNARY,
  proto.abaoai.LoginRequest,
  proto.abaoai.LoginResponse,
  /**
   * @param {!proto.abaoai.LoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.abaoai.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.AccessServiceClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.AccessService/Login',
      request,
      metadata || {},
      methodDescriptor_AccessService_Login,
      callback);
};


/**
 * @param {!proto.abaoai.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.abaoai.AccessServicePromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.AccessService/Login',
      request,
      metadata || {},
      methodDescriptor_AccessService_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.SignupRequest,
 *   !proto.abaoai.SignupResponse>}
 */
const methodDescriptor_AccessService_Signup = new grpc.web.MethodDescriptor(
  '/abaoai.AccessService/Signup',
  grpc.web.MethodType.UNARY,
  proto.abaoai.SignupRequest,
  proto.abaoai.SignupResponse,
  /**
   * @param {!proto.abaoai.SignupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.SignupResponse.deserializeBinary
);


/**
 * @param {!proto.abaoai.SignupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.SignupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.SignupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.AccessServiceClient.prototype.signup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.AccessService/Signup',
      request,
      metadata || {},
      methodDescriptor_AccessService_Signup,
      callback);
};


/**
 * @param {!proto.abaoai.SignupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.SignupResponse>}
 *     Promise that resolves to the response
 */
proto.abaoai.AccessServicePromiseClient.prototype.signup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.AccessService/Signup',
      request,
      metadata || {},
      methodDescriptor_AccessService_Signup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.ResetRequest,
 *   !proto.abaoai.ResetResponse>}
 */
const methodDescriptor_AccessService_Reset = new grpc.web.MethodDescriptor(
  '/abaoai.AccessService/Reset',
  grpc.web.MethodType.UNARY,
  proto.abaoai.ResetRequest,
  proto.abaoai.ResetResponse,
  /**
   * @param {!proto.abaoai.ResetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.ResetResponse.deserializeBinary
);


/**
 * @param {!proto.abaoai.ResetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.ResetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.ResetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.AccessServiceClient.prototype.reset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.AccessService/Reset',
      request,
      metadata || {},
      methodDescriptor_AccessService_Reset,
      callback);
};


/**
 * @param {!proto.abaoai.ResetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.ResetResponse>}
 *     Promise that resolves to the response
 */
proto.abaoai.AccessServicePromiseClient.prototype.reset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.AccessService/Reset',
      request,
      metadata || {},
      methodDescriptor_AccessService_Reset);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.SendCodeRequest,
 *   !proto.abaoai.SendCodeResponse>}
 */
const methodDescriptor_AccessService_SendCode = new grpc.web.MethodDescriptor(
  '/abaoai.AccessService/SendCode',
  grpc.web.MethodType.UNARY,
  proto.abaoai.SendCodeRequest,
  proto.abaoai.SendCodeResponse,
  /**
   * @param {!proto.abaoai.SendCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.SendCodeResponse.deserializeBinary
);


/**
 * @param {!proto.abaoai.SendCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.SendCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.SendCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.AccessServiceClient.prototype.sendCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.AccessService/SendCode',
      request,
      metadata || {},
      methodDescriptor_AccessService_SendCode,
      callback);
};


/**
 * @param {!proto.abaoai.SendCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.SendCodeResponse>}
 *     Promise that resolves to the response
 */
proto.abaoai.AccessServicePromiseClient.prototype.sendCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.AccessService/SendCode',
      request,
      metadata || {},
      methodDescriptor_AccessService_SendCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.AccountPayload,
 *   !proto.abaoai.AccountPayload>}
 */
const methodDescriptor_AccessService_GetAccount = new grpc.web.MethodDescriptor(
  '/abaoai.AccessService/GetAccount',
  grpc.web.MethodType.UNARY,
  proto.abaoai.AccountPayload,
  proto.abaoai.AccountPayload,
  /**
   * @param {!proto.abaoai.AccountPayload} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.AccountPayload.deserializeBinary
);


/**
 * @param {!proto.abaoai.AccountPayload} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.AccountPayload)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.AccountPayload>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.AccessServiceClient.prototype.getAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.AccessService/GetAccount',
      request,
      metadata || {},
      methodDescriptor_AccessService_GetAccount,
      callback);
};


/**
 * @param {!proto.abaoai.AccountPayload} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.AccountPayload>}
 *     Promise that resolves to the response
 */
proto.abaoai.AccessServicePromiseClient.prototype.getAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.AccessService/GetAccount',
      request,
      metadata || {},
      methodDescriptor_AccessService_GetAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.ActiveSubscriptionPayload,
 *   !proto.abaoai.ActiveSubscriptionPayload>}
 */
const methodDescriptor_AccessService_GetActiveSubscription = new grpc.web.MethodDescriptor(
  '/abaoai.AccessService/GetActiveSubscription',
  grpc.web.MethodType.UNARY,
  proto.abaoai.ActiveSubscriptionPayload,
  proto.abaoai.ActiveSubscriptionPayload,
  /**
   * @param {!proto.abaoai.ActiveSubscriptionPayload} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.ActiveSubscriptionPayload.deserializeBinary
);


/**
 * @param {!proto.abaoai.ActiveSubscriptionPayload} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.ActiveSubscriptionPayload)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.ActiveSubscriptionPayload>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.AccessServiceClient.prototype.getActiveSubscription =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.AccessService/GetActiveSubscription',
      request,
      metadata || {},
      methodDescriptor_AccessService_GetActiveSubscription,
      callback);
};


/**
 * @param {!proto.abaoai.ActiveSubscriptionPayload} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.ActiveSubscriptionPayload>}
 *     Promise that resolves to the response
 */
proto.abaoai.AccessServicePromiseClient.prototype.getActiveSubscription =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.AccessService/GetActiveSubscription',
      request,
      metadata || {},
      methodDescriptor_AccessService_GetActiveSubscription);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.BillingPortalSessionPayload,
 *   !proto.abaoai.BillingPortalSessionPayload>}
 */
const methodDescriptor_AccessService_CreateBillingPortalSession = new grpc.web.MethodDescriptor(
  '/abaoai.AccessService/CreateBillingPortalSession',
  grpc.web.MethodType.UNARY,
  proto.abaoai.BillingPortalSessionPayload,
  proto.abaoai.BillingPortalSessionPayload,
  /**
   * @param {!proto.abaoai.BillingPortalSessionPayload} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.BillingPortalSessionPayload.deserializeBinary
);


/**
 * @param {!proto.abaoai.BillingPortalSessionPayload} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.BillingPortalSessionPayload)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.BillingPortalSessionPayload>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.AccessServiceClient.prototype.createBillingPortalSession =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.AccessService/CreateBillingPortalSession',
      request,
      metadata || {},
      methodDescriptor_AccessService_CreateBillingPortalSession,
      callback);
};


/**
 * @param {!proto.abaoai.BillingPortalSessionPayload} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.BillingPortalSessionPayload>}
 *     Promise that resolves to the response
 */
proto.abaoai.AccessServicePromiseClient.prototype.createBillingPortalSession =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.AccessService/CreateBillingPortalSession',
      request,
      metadata || {},
      methodDescriptor_AccessService_CreateBillingPortalSession);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.ModelsPayload,
 *   !proto.abaoai.ModelsPayload>}
 */
const methodDescriptor_AccessService_GetModels = new grpc.web.MethodDescriptor(
  '/abaoai.AccessService/GetModels',
  grpc.web.MethodType.UNARY,
  proto.abaoai.ModelsPayload,
  proto.abaoai.ModelsPayload,
  /**
   * @param {!proto.abaoai.ModelsPayload} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.ModelsPayload.deserializeBinary
);


/**
 * @param {!proto.abaoai.ModelsPayload} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.ModelsPayload)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.ModelsPayload>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.AccessServiceClient.prototype.getModels =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.AccessService/GetModels',
      request,
      metadata || {},
      methodDescriptor_AccessService_GetModels,
      callback);
};


/**
 * @param {!proto.abaoai.ModelsPayload} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.ModelsPayload>}
 *     Promise that resolves to the response
 */
proto.abaoai.AccessServicePromiseClient.prototype.getModels =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.AccessService/GetModels',
      request,
      metadata || {},
      methodDescriptor_AccessService_GetModels);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.ModelsPayload,
 *   !proto.abaoai.ModelsPayload>}
 */
const methodDescriptor_AccessService_GetImageModels = new grpc.web.MethodDescriptor(
  '/abaoai.AccessService/GetImageModels',
  grpc.web.MethodType.UNARY,
  proto.abaoai.ModelsPayload,
  proto.abaoai.ModelsPayload,
  /**
   * @param {!proto.abaoai.ModelsPayload} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.ModelsPayload.deserializeBinary
);


/**
 * @param {!proto.abaoai.ModelsPayload} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.ModelsPayload)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.ModelsPayload>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.AccessServiceClient.prototype.getImageModels =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.AccessService/GetImageModels',
      request,
      metadata || {},
      methodDescriptor_AccessService_GetImageModels,
      callback);
};


/**
 * @param {!proto.abaoai.ModelsPayload} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.ModelsPayload>}
 *     Promise that resolves to the response
 */
proto.abaoai.AccessServicePromiseClient.prototype.getImageModels =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.AccessService/GetImageModels',
      request,
      metadata || {},
      methodDescriptor_AccessService_GetImageModels);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.WorkerPayload,
 *   !proto.abaoai.WorkerPayload>}
 */
const methodDescriptor_AccessService_CreateWorker = new grpc.web.MethodDescriptor(
  '/abaoai.AccessService/CreateWorker',
  grpc.web.MethodType.UNARY,
  proto.abaoai.WorkerPayload,
  proto.abaoai.WorkerPayload,
  /**
   * @param {!proto.abaoai.WorkerPayload} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.WorkerPayload.deserializeBinary
);


/**
 * @param {!proto.abaoai.WorkerPayload} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.WorkerPayload)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.WorkerPayload>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.AccessServiceClient.prototype.createWorker =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.AccessService/CreateWorker',
      request,
      metadata || {},
      methodDescriptor_AccessService_CreateWorker,
      callback);
};


/**
 * @param {!proto.abaoai.WorkerPayload} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.WorkerPayload>}
 *     Promise that resolves to the response
 */
proto.abaoai.AccessServicePromiseClient.prototype.createWorker =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.AccessService/CreateWorker',
      request,
      metadata || {},
      methodDescriptor_AccessService_CreateWorker);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.ListWorkersRequest,
 *   !proto.abaoai.ListWorkersResponse>}
 */
const methodDescriptor_AccessService_ListWorkers = new grpc.web.MethodDescriptor(
  '/abaoai.AccessService/ListWorkers',
  grpc.web.MethodType.UNARY,
  proto.abaoai.ListWorkersRequest,
  proto.abaoai.ListWorkersResponse,
  /**
   * @param {!proto.abaoai.ListWorkersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.ListWorkersResponse.deserializeBinary
);


/**
 * @param {!proto.abaoai.ListWorkersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.ListWorkersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.ListWorkersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.AccessServiceClient.prototype.listWorkers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.AccessService/ListWorkers',
      request,
      metadata || {},
      methodDescriptor_AccessService_ListWorkers,
      callback);
};


/**
 * @param {!proto.abaoai.ListWorkersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.ListWorkersResponse>}
 *     Promise that resolves to the response
 */
proto.abaoai.AccessServicePromiseClient.prototype.listWorkers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.AccessService/ListWorkers',
      request,
      metadata || {},
      methodDescriptor_AccessService_ListWorkers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.abaoai.WorkerPayload,
 *   !proto.abaoai.WorkerPayload>}
 */
const methodDescriptor_AccessService_DeleteWorker = new grpc.web.MethodDescriptor(
  '/abaoai.AccessService/DeleteWorker',
  grpc.web.MethodType.UNARY,
  proto.abaoai.WorkerPayload,
  proto.abaoai.WorkerPayload,
  /**
   * @param {!proto.abaoai.WorkerPayload} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.abaoai.WorkerPayload.deserializeBinary
);


/**
 * @param {!proto.abaoai.WorkerPayload} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.abaoai.WorkerPayload)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.abaoai.WorkerPayload>|undefined}
 *     The XHR Node Readable Stream
 */
proto.abaoai.AccessServiceClient.prototype.deleteWorker =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/abaoai.AccessService/DeleteWorker',
      request,
      metadata || {},
      methodDescriptor_AccessService_DeleteWorker,
      callback);
};


/**
 * @param {!proto.abaoai.WorkerPayload} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.abaoai.WorkerPayload>}
 *     Promise that resolves to the response
 */
proto.abaoai.AccessServicePromiseClient.prototype.deleteWorker =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/abaoai.AccessService/DeleteWorker',
      request,
      metadata || {},
      methodDescriptor_AccessService_DeleteWorker);
};


module.exports = proto.abaoai;

