import { computed, makeObservable, observable, runInAction } from "mobx"
import { CHAT, ACCESS } from "../Client"
import { S } from "../State"
import grpcWeb from "grpc-web"

class CheckState {
    right_title: string = "New Check"
    drawer_name: string = ""
    drawer_email: string = ""
    recipient_name: string = ""
    recipient_email: string = ""
    code: string = ""
    amount: number = 0
    description: string = ""
    email_countdown: number = 0
    checks: proto.abaoai.CheckPayload[] = []

    columns = [
        { key: "date", label: "Date" },
        { key: "drawer", label: "Drawer" },
        { key: "recipient", label: "Recipient" },
        { key: "description", label: "Description" },
        { key: "amount", label: "Amount" },
    ]

    constructor() {
        makeObservable(this, {
            right_title: observable,
            drawer_name: observable,
            drawer_email: observable,
            recipient_name: observable,
            recipient_email: observable,
            code: observable,
            amount: observable,
            description: observable,
            email_countdown: observable,
            drawer_email_error_message: computed,
            drawer_name_error_message: computed,
            code_error_message: computed,
            recipient_name_error_message: computed,
            recipient_email_error_message: computed,
            amount_error_message: computed,
            description_error_message: computed,
            checks: observable,
        })
    }

    async onClickNewCheck() {
        runInAction(() => {
            S.main.view = "check"
            S.main.right_drawer_is_open = true
            this.drawer_name = S.main.name
            this.drawer_email = S.main.email
        })
        await this.loadChecks()
    }

    onChangeDrawerName(value: string) {
        runInAction(() => {
            this.drawer_name = value
        })
    }

    onChangeDrawerEmail(value: string) {
        runInAction(() => {
            this.drawer_email = value
        })

    }

    onChangeRecipientrName(value: string) {
        runInAction(() => {
            this.recipient_name = value
        })
    }

    onChangeRecipientEmail(value: string) {
        runInAction(() => {
            this.recipient_email = value
        })
    }

    onChangeAmount(value: number) {
        runInAction(() => {
            this.amount = value
        })
    }

    onChangeDescirption(value: string) {
        runInAction(() => {
            this.description = value
        })
    }

    onChangeCode(value: string) {
        runInAction(() => {
            this.code = value
        })
    }

    get code_error_message() {
        if (S.error_field === "code") {
            return S.error_message
        }
        return ""
    }

    get drawer_name_error_message() {
        if (S.error_field === "drawer_name") {
            return S.error_message
        }
        return ""
    }

    get drawer_email_error_message() {
        if (S.error_field === "drawer_email") {
            return S.error_message
        }
        return ""
    }

    get recipient_name_error_message() {
        if (S.error_field === "recipient_name") {
            return S.error_message
        }
        return ""
    }

    get recipient_email_error_message() {
        if (S.error_field === "recipient_email") {
            return S.error_message
        }
        return ""
    }

    get amount_error_message() {
        if (S.error_field === "amount") {
            return S.error_message
        }
        return ""
    }

    get description_error_message() {
        if (S.error_field === "description") {
            return S.error_message
        }
        return ""
    }

    async onClickPay() {
        S.clearError()
        try {
            let req = new proto.abaoai.CheckPayload()
            req.setDrawerName(this.drawer_name)
            req.setDrawerEmail(this.drawer_email)
            req.setCode(this.code)
            req.setRecipientName(this.recipient_name)
            req.setRecipientEmail(this.recipient_email)
            req.setAmount(this.amount)
            req.setDescription(this.description)
            let res: proto.abaoai.CheckPayload = await CHAT.createCheck(req, S.getMetadataMap())
            alert(`Check #${res.getCheckId()} ($${res.getAmount()}) is deposited`)
            this.loadChecks()
        } catch (e) {
            S.onError(e as grpcWeb.RpcError)
        }
    }

    async onClickSendCode() {
        S.clearError()
        try {
            let req = new proto.abaoai.SendCodeRequest()
            req.setUsage(3)
            req.setName(this.drawer_name)
            req.setEmail(this.drawer_email.toLowerCase())
            req.setAccountId(S.main.account_id)
            let res: proto.abaoai.SendCodeResponse = await ACCESS.sendCode(req)
            console.log(res.getTimestamp())
            this.start_email_countdown()
        } catch (e) {
            S.onError(e as grpcWeb.RpcError)
        }
    }

    async loadChecks() {
        try {
            let req = new proto.abaoai.ListChecksRequest()
            req.setAccountId(S.main.account_id)
            let res: proto.abaoai.ListChecksResponse = await CHAT.listChecks(req, S.getMetadataMap())
            runInAction(() => {
                this.checks = res.getChecksList()
            })
        } catch (e) {
            S.onError(e as grpcWeb.RpcError)
        }
    }

    start_email_countdown() {
        if (this.email_countdown !== 0) {
            return
        }
        runInAction(() => {
            this.email_countdown = 60
        })
        let email_interval = setInterval(() => {
            runInAction(() => {
                this.email_countdown -= 1
            })
            if (this.email_countdown === 0) {
                clearInterval(email_interval)
            }
        }, 1000)
    }

}

export { CheckState }