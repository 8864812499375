import { observer } from "mobx-react-lite"
import { S } from "../../State"
import {
  Field,
  Input,
  Select,
} from "@fluentui/react-components"

const ChatConfig = observer(() =>
  <>
    <div className="models_row">
      <Field
        className="models"
        label="Model"
        required
      >
        <Select
          value={S.chat.selected_model_id}
          onChange={(_, { value }) => S.chat.onChangeModel(value)}
        >
          {S.chat.model_options.map((option) => (
            <option key={option.key} value={option.key} >
              {option.text}
            </option>
          ))}
        </Select>
      </Field>
    </div>
    {
      S.chat.has_system_prompt ?
        <div className="system_prompt_row">
          <Field
            className="system_prompt"
            label="System Prompt"
          >
            <Input
              value={S.chat.system_prompt}
              onChange={(_, { value }) => S.chat.onChangeSystemPrompt(value!)}
            />
          </Field>
        </div>
        :
        null
    }
  </>
)

export { ChatConfig }