// source: chat.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var image_pb = require('./image_pb.js');
goog.object.extend(proto, image_pb);
goog.exportSymbol('proto.abaoai.AudioPayload', null, global);
goog.exportSymbol('proto.abaoai.ChatHistoryPayload', null, global);
goog.exportSymbol('proto.abaoai.ChatPayload', null, global);
goog.exportSymbol('proto.abaoai.ChatSummaryPayload', null, global);
goog.exportSymbol('proto.abaoai.CheckPayload', null, global);
goog.exportSymbol('proto.abaoai.CheckpointOption', null, global);
goog.exportSymbol('proto.abaoai.CheckpointPayload', null, global);
goog.exportSymbol('proto.abaoai.CheckpointVersionOption', null, global);
goog.exportSymbol('proto.abaoai.CheckpointVersionPayload', null, global);
goog.exportSymbol('proto.abaoai.DialogPayload', null, global);
goog.exportSymbol('proto.abaoai.GetCheckpointOptionsRequest', null, global);
goog.exportSymbol('proto.abaoai.GetCheckpointOptionsResponse', null, global);
goog.exportSymbol('proto.abaoai.GetCheckpointVersionFileRequest', null, global);
goog.exportSymbol('proto.abaoai.GetCheckpointVersionFileResponse', null, global);
goog.exportSymbol('proto.abaoai.GetCheckpointVersionOptionsRequest', null, global);
goog.exportSymbol('proto.abaoai.GetCheckpointVersionOptionsResponse', null, global);
goog.exportSymbol('proto.abaoai.ListChecksRequest', null, global);
goog.exportSymbol('proto.abaoai.ListChecksResponse', null, global);
goog.exportSymbol('proto.abaoai.ListImagesRequest', null, global);
goog.exportSymbol('proto.abaoai.ListImagesResponse', null, global);
goog.exportSymbol('proto.abaoai.PromptPayload', null, global);
goog.exportSymbol('proto.abaoai.SearchCheckpointImagesRequest', null, global);
goog.exportSymbol('proto.abaoai.SearchCheckpointImagesResponse', null, global);
goog.exportSymbol('proto.abaoai.SearchCheckpointsRequest', null, global);
goog.exportSymbol('proto.abaoai.SearchCheckpointsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.ChatPayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.abaoai.ChatPayload.repeatedFields_, null);
};
goog.inherits(proto.abaoai.ChatPayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.ChatPayload.displayName = 'proto.abaoai.ChatPayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.DialogPayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.abaoai.DialogPayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.DialogPayload.displayName = 'proto.abaoai.DialogPayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.PromptPayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.abaoai.PromptPayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.PromptPayload.displayName = 'proto.abaoai.PromptPayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.ChatHistoryPayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.abaoai.ChatHistoryPayload.repeatedFields_, null);
};
goog.inherits(proto.abaoai.ChatHistoryPayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.ChatHistoryPayload.displayName = 'proto.abaoai.ChatHistoryPayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.ChatSummaryPayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.abaoai.ChatSummaryPayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.ChatSummaryPayload.displayName = 'proto.abaoai.ChatSummaryPayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.ListImagesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.abaoai.ListImagesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.ListImagesRequest.displayName = 'proto.abaoai.ListImagesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.ListImagesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.abaoai.ListImagesResponse.repeatedFields_, null);
};
goog.inherits(proto.abaoai.ListImagesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.ListImagesResponse.displayName = 'proto.abaoai.ListImagesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.AudioPayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.abaoai.AudioPayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.AudioPayload.displayName = 'proto.abaoai.AudioPayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.SearchCheckpointsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.abaoai.SearchCheckpointsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.SearchCheckpointsRequest.displayName = 'proto.abaoai.SearchCheckpointsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.SearchCheckpointsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.abaoai.SearchCheckpointsResponse.repeatedFields_, null);
};
goog.inherits(proto.abaoai.SearchCheckpointsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.SearchCheckpointsResponse.displayName = 'proto.abaoai.SearchCheckpointsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.GetCheckpointOptionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.abaoai.GetCheckpointOptionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.GetCheckpointOptionsRequest.displayName = 'proto.abaoai.GetCheckpointOptionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.CheckpointOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.abaoai.CheckpointOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.CheckpointOption.displayName = 'proto.abaoai.CheckpointOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.GetCheckpointOptionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.abaoai.GetCheckpointOptionsResponse.repeatedFields_, null);
};
goog.inherits(proto.abaoai.GetCheckpointOptionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.GetCheckpointOptionsResponse.displayName = 'proto.abaoai.GetCheckpointOptionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.GetCheckpointVersionOptionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.abaoai.GetCheckpointVersionOptionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.GetCheckpointVersionOptionsRequest.displayName = 'proto.abaoai.GetCheckpointVersionOptionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.CheckpointVersionOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.abaoai.CheckpointVersionOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.CheckpointVersionOption.displayName = 'proto.abaoai.CheckpointVersionOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.GetCheckpointVersionOptionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.abaoai.GetCheckpointVersionOptionsResponse.repeatedFields_, null);
};
goog.inherits(proto.abaoai.GetCheckpointVersionOptionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.GetCheckpointVersionOptionsResponse.displayName = 'proto.abaoai.GetCheckpointVersionOptionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.GetCheckpointVersionFileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.abaoai.GetCheckpointVersionFileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.GetCheckpointVersionFileRequest.displayName = 'proto.abaoai.GetCheckpointVersionFileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.GetCheckpointVersionFileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.abaoai.GetCheckpointVersionFileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.GetCheckpointVersionFileResponse.displayName = 'proto.abaoai.GetCheckpointVersionFileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.CheckpointPayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.abaoai.CheckpointPayload.repeatedFields_, null);
};
goog.inherits(proto.abaoai.CheckpointPayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.CheckpointPayload.displayName = 'proto.abaoai.CheckpointPayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.CheckpointVersionPayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.abaoai.CheckpointVersionPayload.repeatedFields_, null);
};
goog.inherits(proto.abaoai.CheckpointVersionPayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.CheckpointVersionPayload.displayName = 'proto.abaoai.CheckpointVersionPayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.SearchCheckpointImagesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.abaoai.SearchCheckpointImagesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.SearchCheckpointImagesRequest.displayName = 'proto.abaoai.SearchCheckpointImagesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.SearchCheckpointImagesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.abaoai.SearchCheckpointImagesResponse.repeatedFields_, null);
};
goog.inherits(proto.abaoai.SearchCheckpointImagesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.SearchCheckpointImagesResponse.displayName = 'proto.abaoai.SearchCheckpointImagesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.CheckPayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.abaoai.CheckPayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.CheckPayload.displayName = 'proto.abaoai.CheckPayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.ListChecksRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.abaoai.ListChecksRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.ListChecksRequest.displayName = 'proto.abaoai.ListChecksRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.abaoai.ListChecksResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.abaoai.ListChecksResponse.repeatedFields_, null);
};
goog.inherits(proto.abaoai.ListChecksResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.abaoai.ListChecksResponse.displayName = 'proto.abaoai.ListChecksResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.abaoai.ChatPayload.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.ChatPayload.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.ChatPayload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.ChatPayload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.ChatPayload.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    modelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 4, 0),
    systemPrompt: jspb.Message.getFieldWithDefault(msg, 5, ""),
    maxNewTokens: jspb.Message.getFieldWithDefault(msg, 6, 0),
    temperature: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    topP: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    topK: jspb.Message.getFieldWithDefault(msg, 9, 0),
    dialogIdsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.ChatPayload}
 */
proto.abaoai.ChatPayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.ChatPayload;
  return proto.abaoai.ChatPayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.ChatPayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.ChatPayload}
 */
proto.abaoai.ChatPayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChatId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAccountId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemPrompt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxNewTokens(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTemperature(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTopP(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTopK(value);
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDialogIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.ChatPayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.ChatPayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.ChatPayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.ChatPayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getSystemPrompt();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMaxNewTokens();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getTemperature();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getTopP();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getTopK();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getDialogIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      10,
      f
    );
  }
};


/**
 * optional uint64 chat_id = 1;
 * @return {number}
 */
proto.abaoai.ChatPayload.prototype.getChatId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ChatPayload} returns this
 */
proto.abaoai.ChatPayload.prototype.setChatId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string model_id = 2;
 * @return {string}
 */
proto.abaoai.ChatPayload.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.ChatPayload} returns this
 */
proto.abaoai.ChatPayload.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 account_id = 3;
 * @return {number}
 */
proto.abaoai.ChatPayload.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ChatPayload} returns this
 */
proto.abaoai.ChatPayload.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 timestamp = 4;
 * @return {number}
 */
proto.abaoai.ChatPayload.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ChatPayload} returns this
 */
proto.abaoai.ChatPayload.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string system_prompt = 5;
 * @return {string}
 */
proto.abaoai.ChatPayload.prototype.getSystemPrompt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.ChatPayload} returns this
 */
proto.abaoai.ChatPayload.prototype.setSystemPrompt = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 max_new_tokens = 6;
 * @return {number}
 */
proto.abaoai.ChatPayload.prototype.getMaxNewTokens = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ChatPayload} returns this
 */
proto.abaoai.ChatPayload.prototype.setMaxNewTokens = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional double temperature = 7;
 * @return {number}
 */
proto.abaoai.ChatPayload.prototype.getTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ChatPayload} returns this
 */
proto.abaoai.ChatPayload.prototype.setTemperature = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double top_p = 8;
 * @return {number}
 */
proto.abaoai.ChatPayload.prototype.getTopP = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ChatPayload} returns this
 */
proto.abaoai.ChatPayload.prototype.setTopP = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional uint32 top_k = 9;
 * @return {number}
 */
proto.abaoai.ChatPayload.prototype.getTopK = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ChatPayload} returns this
 */
proto.abaoai.ChatPayload.prototype.setTopK = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated uint64 dialog_ids = 10;
 * @return {!Array<number>}
 */
proto.abaoai.ChatPayload.prototype.getDialogIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.abaoai.ChatPayload} returns this
 */
proto.abaoai.ChatPayload.prototype.setDialogIdsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.abaoai.ChatPayload} returns this
 */
proto.abaoai.ChatPayload.prototype.addDialogIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.abaoai.ChatPayload} returns this
 */
proto.abaoai.ChatPayload.prototype.clearDialogIdsList = function() {
  return this.setDialogIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.DialogPayload.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.DialogPayload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.DialogPayload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.DialogPayload.toObject = function(includeInstance, msg) {
  var f, obj = {
    dialogId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    chatId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    input: jspb.Message.getFieldWithDefault(msg, 3, ""),
    output: jspb.Message.getFieldWithDefault(msg, 4, ""),
    token: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.DialogPayload}
 */
proto.abaoai.DialogPayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.DialogPayload;
  return proto.abaoai.DialogPayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.DialogPayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.DialogPayload}
 */
proto.abaoai.DialogPayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDialogId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChatId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInput(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutput(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.DialogPayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.DialogPayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.DialogPayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.DialogPayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDialogId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getChatId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getInput();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOutput();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional uint64 dialog_id = 1;
 * @return {number}
 */
proto.abaoai.DialogPayload.prototype.getDialogId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.DialogPayload} returns this
 */
proto.abaoai.DialogPayload.prototype.setDialogId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 chat_id = 2;
 * @return {number}
 */
proto.abaoai.DialogPayload.prototype.getChatId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.DialogPayload} returns this
 */
proto.abaoai.DialogPayload.prototype.setChatId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string input = 3;
 * @return {string}
 */
proto.abaoai.DialogPayload.prototype.getInput = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.DialogPayload} returns this
 */
proto.abaoai.DialogPayload.prototype.setInput = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string output = 4;
 * @return {string}
 */
proto.abaoai.DialogPayload.prototype.getOutput = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.DialogPayload} returns this
 */
proto.abaoai.DialogPayload.prototype.setOutput = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string token = 5;
 * @return {string}
 */
proto.abaoai.DialogPayload.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.DialogPayload} returns this
 */
proto.abaoai.DialogPayload.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.PromptPayload.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.PromptPayload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.PromptPayload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.PromptPayload.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dialogId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    chatId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    prompt: jspb.Message.getFieldWithDefault(msg, 4, ""),
    maxNewTokens: jspb.Message.getFieldWithDefault(msg, 5, 0),
    temperature: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    topP: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    topK: jspb.Message.getFieldWithDefault(msg, 8, 0),
    systemPrompt: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.PromptPayload}
 */
proto.abaoai.PromptPayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.PromptPayload;
  return proto.abaoai.PromptPayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.PromptPayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.PromptPayload}
 */
proto.abaoai.PromptPayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDialogId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChatId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrompt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxNewTokens(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTemperature(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTopP(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTopK(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemPrompt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.PromptPayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.PromptPayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.PromptPayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.PromptPayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDialogId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getChatId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getPrompt();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMaxNewTokens();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTemperature();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getTopP();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getTopK();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getSystemPrompt();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string model_id = 1;
 * @return {string}
 */
proto.abaoai.PromptPayload.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.PromptPayload} returns this
 */
proto.abaoai.PromptPayload.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 dialog_id = 2;
 * @return {number}
 */
proto.abaoai.PromptPayload.prototype.getDialogId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.PromptPayload} returns this
 */
proto.abaoai.PromptPayload.prototype.setDialogId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 chat_id = 3;
 * @return {number}
 */
proto.abaoai.PromptPayload.prototype.getChatId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.PromptPayload} returns this
 */
proto.abaoai.PromptPayload.prototype.setChatId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string prompt = 4;
 * @return {string}
 */
proto.abaoai.PromptPayload.prototype.getPrompt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.PromptPayload} returns this
 */
proto.abaoai.PromptPayload.prototype.setPrompt = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 max_new_tokens = 5;
 * @return {number}
 */
proto.abaoai.PromptPayload.prototype.getMaxNewTokens = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.PromptPayload} returns this
 */
proto.abaoai.PromptPayload.prototype.setMaxNewTokens = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double temperature = 6;
 * @return {number}
 */
proto.abaoai.PromptPayload.prototype.getTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.PromptPayload} returns this
 */
proto.abaoai.PromptPayload.prototype.setTemperature = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double top_p = 7;
 * @return {number}
 */
proto.abaoai.PromptPayload.prototype.getTopP = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.PromptPayload} returns this
 */
proto.abaoai.PromptPayload.prototype.setTopP = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional uint32 top_k = 8;
 * @return {number}
 */
proto.abaoai.PromptPayload.prototype.getTopK = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.PromptPayload} returns this
 */
proto.abaoai.PromptPayload.prototype.setTopK = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string system_prompt = 9;
 * @return {string}
 */
proto.abaoai.PromptPayload.prototype.getSystemPrompt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.PromptPayload} returns this
 */
proto.abaoai.PromptPayload.prototype.setSystemPrompt = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.abaoai.ChatHistoryPayload.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.ChatHistoryPayload.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.ChatHistoryPayload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.ChatHistoryPayload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.ChatHistoryPayload.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    chatSummariesList: jspb.Message.toObjectList(msg.getChatSummariesList(),
    proto.abaoai.ChatSummaryPayload.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.ChatHistoryPayload}
 */
proto.abaoai.ChatHistoryPayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.ChatHistoryPayload;
  return proto.abaoai.ChatHistoryPayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.ChatHistoryPayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.ChatHistoryPayload}
 */
proto.abaoai.ChatHistoryPayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAccountId(value);
      break;
    case 2:
      var value = new proto.abaoai.ChatSummaryPayload;
      reader.readMessage(value,proto.abaoai.ChatSummaryPayload.deserializeBinaryFromReader);
      msg.addChatSummaries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.ChatHistoryPayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.ChatHistoryPayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.ChatHistoryPayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.ChatHistoryPayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getChatSummariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.abaoai.ChatSummaryPayload.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 account_id = 1;
 * @return {number}
 */
proto.abaoai.ChatHistoryPayload.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ChatHistoryPayload} returns this
 */
proto.abaoai.ChatHistoryPayload.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated ChatSummaryPayload chat_summaries = 2;
 * @return {!Array<!proto.abaoai.ChatSummaryPayload>}
 */
proto.abaoai.ChatHistoryPayload.prototype.getChatSummariesList = function() {
  return /** @type{!Array<!proto.abaoai.ChatSummaryPayload>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.abaoai.ChatSummaryPayload, 2));
};


/**
 * @param {!Array<!proto.abaoai.ChatSummaryPayload>} value
 * @return {!proto.abaoai.ChatHistoryPayload} returns this
*/
proto.abaoai.ChatHistoryPayload.prototype.setChatSummariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.abaoai.ChatSummaryPayload=} opt_value
 * @param {number=} opt_index
 * @return {!proto.abaoai.ChatSummaryPayload}
 */
proto.abaoai.ChatHistoryPayload.prototype.addChatSummaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.abaoai.ChatSummaryPayload, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.abaoai.ChatHistoryPayload} returns this
 */
proto.abaoai.ChatHistoryPayload.prototype.clearChatSummariesList = function() {
  return this.setChatSummariesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.ChatSummaryPayload.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.ChatSummaryPayload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.ChatSummaryPayload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.ChatSummaryPayload.toObject = function(includeInstance, msg) {
  var f, obj = {
    chatId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
    summary: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.ChatSummaryPayload}
 */
proto.abaoai.ChatSummaryPayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.ChatSummaryPayload;
  return proto.abaoai.ChatSummaryPayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.ChatSummaryPayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.ChatSummaryPayload}
 */
proto.abaoai.ChatSummaryPayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChatId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.ChatSummaryPayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.ChatSummaryPayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.ChatSummaryPayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.ChatSummaryPayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChatId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint64 chat_id = 1;
 * @return {number}
 */
proto.abaoai.ChatSummaryPayload.prototype.getChatId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ChatSummaryPayload} returns this
 */
proto.abaoai.ChatSummaryPayload.prototype.setChatId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 timestamp = 2;
 * @return {number}
 */
proto.abaoai.ChatSummaryPayload.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ChatSummaryPayload} returns this
 */
proto.abaoai.ChatSummaryPayload.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string summary = 3;
 * @return {string}
 */
proto.abaoai.ChatSummaryPayload.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.ChatSummaryPayload} returns this
 */
proto.abaoai.ChatSummaryPayload.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.ListImagesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.ListImagesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.ListImagesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.ListImagesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.ListImagesRequest}
 */
proto.abaoai.ListImagesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.ListImagesRequest;
  return proto.abaoai.ListImagesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.ListImagesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.ListImagesRequest}
 */
proto.abaoai.ListImagesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStart(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.ListImagesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.ListImagesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.ListImagesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.ListImagesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional uint32 start = 1;
 * @return {number}
 */
proto.abaoai.ListImagesRequest.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ListImagesRequest} returns this
 */
proto.abaoai.ListImagesRequest.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 account_id = 2;
 * @return {number}
 */
proto.abaoai.ListImagesRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ListImagesRequest} returns this
 */
proto.abaoai.ListImagesRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.abaoai.ListImagesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.ListImagesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.ListImagesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.ListImagesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.ListImagesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    image_pb.ImagePayload.toObject, includeInstance),
    nextStart: jspb.Message.getFieldWithDefault(msg, 3, 0),
    total: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.ListImagesResponse}
 */
proto.abaoai.ListImagesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.ListImagesResponse;
  return proto.abaoai.ListImagesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.ListImagesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.ListImagesResponse}
 */
proto.abaoai.ListImagesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new image_pb.ImagePayload;
      reader.readMessage(value,image_pb.ImagePayload.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNextStart(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.ListImagesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.ListImagesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.ListImagesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.ListImagesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      image_pb.ImagePayload.serializeBinaryToWriter
    );
  }
  f = message.getNextStart();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * repeated ImagePayload images = 1;
 * @return {!Array<!proto.abaoai.ImagePayload>}
 */
proto.abaoai.ListImagesResponse.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.abaoai.ImagePayload>} */ (
    jspb.Message.getRepeatedWrapperField(this, image_pb.ImagePayload, 1));
};


/**
 * @param {!Array<!proto.abaoai.ImagePayload>} value
 * @return {!proto.abaoai.ListImagesResponse} returns this
*/
proto.abaoai.ListImagesResponse.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.abaoai.ImagePayload=} opt_value
 * @param {number=} opt_index
 * @return {!proto.abaoai.ImagePayload}
 */
proto.abaoai.ListImagesResponse.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.abaoai.ImagePayload, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.abaoai.ListImagesResponse} returns this
 */
proto.abaoai.ListImagesResponse.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional uint32 next_start = 3;
 * @return {number}
 */
proto.abaoai.ListImagesResponse.prototype.getNextStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ListImagesResponse} returns this
 */
proto.abaoai.ListImagesResponse.prototype.setNextStart = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 total = 4;
 * @return {number}
 */
proto.abaoai.ListImagesResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ListImagesResponse} returns this
 */
proto.abaoai.ListImagesResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.AudioPayload.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.AudioPayload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.AudioPayload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.AudioPayload.toObject = function(includeInstance, msg) {
  var f, obj = {
    audioId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 3, 0),
    modelId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    input: jspb.Message.getFieldWithDefault(msg, 5, ""),
    voice: jspb.Message.getFieldWithDefault(msg, 6, ""),
    format: jspb.Message.getFieldWithDefault(msg, 7, ""),
    speed: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    url: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.AudioPayload}
 */
proto.abaoai.AudioPayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.AudioPayload;
  return proto.abaoai.AudioPayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.AudioPayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.AudioPayload}
 */
proto.abaoai.AudioPayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAudioId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAccountId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInput(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setVoice(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormat(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSpeed(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.AudioPayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.AudioPayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.AudioPayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.AudioPayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAudioId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInput();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getVoice();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFormat();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional uint64 audio_id = 1;
 * @return {number}
 */
proto.abaoai.AudioPayload.prototype.getAudioId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.AudioPayload} returns this
 */
proto.abaoai.AudioPayload.prototype.setAudioId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 account_id = 2;
 * @return {number}
 */
proto.abaoai.AudioPayload.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.AudioPayload} returns this
 */
proto.abaoai.AudioPayload.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 timestamp = 3;
 * @return {number}
 */
proto.abaoai.AudioPayload.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.AudioPayload} returns this
 */
proto.abaoai.AudioPayload.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string model_id = 4;
 * @return {string}
 */
proto.abaoai.AudioPayload.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.AudioPayload} returns this
 */
proto.abaoai.AudioPayload.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string input = 5;
 * @return {string}
 */
proto.abaoai.AudioPayload.prototype.getInput = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.AudioPayload} returns this
 */
proto.abaoai.AudioPayload.prototype.setInput = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string voice = 6;
 * @return {string}
 */
proto.abaoai.AudioPayload.prototype.getVoice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.AudioPayload} returns this
 */
proto.abaoai.AudioPayload.prototype.setVoice = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string format = 7;
 * @return {string}
 */
proto.abaoai.AudioPayload.prototype.getFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.AudioPayload} returns this
 */
proto.abaoai.AudioPayload.prototype.setFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional double speed = 8;
 * @return {number}
 */
proto.abaoai.AudioPayload.prototype.getSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.AudioPayload} returns this
 */
proto.abaoai.AudioPayload.prototype.setSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string url = 9;
 * @return {string}
 */
proto.abaoai.AudioPayload.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.AudioPayload} returns this
 */
proto.abaoai.AudioPayload.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.SearchCheckpointsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.SearchCheckpointsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.SearchCheckpointsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.SearchCheckpointsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: jspb.Message.getFieldWithDefault(msg, 1, 0),
    query: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sort: jspb.Message.getFieldWithDefault(msg, 3, ""),
    period: jspb.Message.getFieldWithDefault(msg, 4, ""),
    baseModelId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    nsfw: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.SearchCheckpointsRequest}
 */
proto.abaoai.SearchCheckpointsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.SearchCheckpointsRequest;
  return proto.abaoai.SearchCheckpointsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.SearchCheckpointsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.SearchCheckpointsRequest}
 */
proto.abaoai.SearchCheckpointsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStart(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSort(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeriod(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseModelId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNsfw(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.SearchCheckpointsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.SearchCheckpointsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.SearchCheckpointsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.SearchCheckpointsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSort();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPeriod();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBaseModelId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNsfw();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional uint32 start = 1;
 * @return {number}
 */
proto.abaoai.SearchCheckpointsRequest.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.SearchCheckpointsRequest} returns this
 */
proto.abaoai.SearchCheckpointsRequest.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string query = 2;
 * @return {string}
 */
proto.abaoai.SearchCheckpointsRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.SearchCheckpointsRequest} returns this
 */
proto.abaoai.SearchCheckpointsRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sort = 3;
 * @return {string}
 */
proto.abaoai.SearchCheckpointsRequest.prototype.getSort = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.SearchCheckpointsRequest} returns this
 */
proto.abaoai.SearchCheckpointsRequest.prototype.setSort = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string period = 4;
 * @return {string}
 */
proto.abaoai.SearchCheckpointsRequest.prototype.getPeriod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.SearchCheckpointsRequest} returns this
 */
proto.abaoai.SearchCheckpointsRequest.prototype.setPeriod = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string base_model_id = 5;
 * @return {string}
 */
proto.abaoai.SearchCheckpointsRequest.prototype.getBaseModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.SearchCheckpointsRequest} returns this
 */
proto.abaoai.SearchCheckpointsRequest.prototype.setBaseModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool nsfw = 6;
 * @return {boolean}
 */
proto.abaoai.SearchCheckpointsRequest.prototype.getNsfw = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.abaoai.SearchCheckpointsRequest} returns this
 */
proto.abaoai.SearchCheckpointsRequest.prototype.setNsfw = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.abaoai.SearchCheckpointsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.SearchCheckpointsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.SearchCheckpointsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.SearchCheckpointsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.SearchCheckpointsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    checkpointsList: jspb.Message.toObjectList(msg.getCheckpointsList(),
    proto.abaoai.CheckpointPayload.toObject, includeInstance),
    nextStart: jspb.Message.getFieldWithDefault(msg, 2, 0),
    total: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.SearchCheckpointsResponse}
 */
proto.abaoai.SearchCheckpointsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.SearchCheckpointsResponse;
  return proto.abaoai.SearchCheckpointsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.SearchCheckpointsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.SearchCheckpointsResponse}
 */
proto.abaoai.SearchCheckpointsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.abaoai.CheckpointPayload;
      reader.readMessage(value,proto.abaoai.CheckpointPayload.deserializeBinaryFromReader);
      msg.addCheckpoints(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNextStart(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.SearchCheckpointsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.SearchCheckpointsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.SearchCheckpointsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.SearchCheckpointsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCheckpointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.abaoai.CheckpointPayload.serializeBinaryToWriter
    );
  }
  f = message.getNextStart();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * repeated CheckpointPayload checkpoints = 1;
 * @return {!Array<!proto.abaoai.CheckpointPayload>}
 */
proto.abaoai.SearchCheckpointsResponse.prototype.getCheckpointsList = function() {
  return /** @type{!Array<!proto.abaoai.CheckpointPayload>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.abaoai.CheckpointPayload, 1));
};


/**
 * @param {!Array<!proto.abaoai.CheckpointPayload>} value
 * @return {!proto.abaoai.SearchCheckpointsResponse} returns this
*/
proto.abaoai.SearchCheckpointsResponse.prototype.setCheckpointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.abaoai.CheckpointPayload=} opt_value
 * @param {number=} opt_index
 * @return {!proto.abaoai.CheckpointPayload}
 */
proto.abaoai.SearchCheckpointsResponse.prototype.addCheckpoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.abaoai.CheckpointPayload, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.abaoai.SearchCheckpointsResponse} returns this
 */
proto.abaoai.SearchCheckpointsResponse.prototype.clearCheckpointsList = function() {
  return this.setCheckpointsList([]);
};


/**
 * optional uint32 next_start = 2;
 * @return {number}
 */
proto.abaoai.SearchCheckpointsResponse.prototype.getNextStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.SearchCheckpointsResponse} returns this
 */
proto.abaoai.SearchCheckpointsResponse.prototype.setNextStart = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 total = 3;
 * @return {number}
 */
proto.abaoai.SearchCheckpointsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.SearchCheckpointsResponse} returns this
 */
proto.abaoai.SearchCheckpointsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.GetCheckpointOptionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.GetCheckpointOptionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.GetCheckpointOptionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.GetCheckpointOptionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseModelId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.GetCheckpointOptionsRequest}
 */
proto.abaoai.GetCheckpointOptionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.GetCheckpointOptionsRequest;
  return proto.abaoai.GetCheckpointOptionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.GetCheckpointOptionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.GetCheckpointOptionsRequest}
 */
proto.abaoai.GetCheckpointOptionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseModelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.GetCheckpointOptionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.GetCheckpointOptionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.GetCheckpointOptionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.GetCheckpointOptionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseModelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string base_model_id = 1;
 * @return {string}
 */
proto.abaoai.GetCheckpointOptionsRequest.prototype.getBaseModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.GetCheckpointOptionsRequest} returns this
 */
proto.abaoai.GetCheckpointOptionsRequest.prototype.setBaseModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.CheckpointOption.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.CheckpointOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.CheckpointOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.CheckpointOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    checkpointId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    checkpointName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.CheckpointOption}
 */
proto.abaoai.CheckpointOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.CheckpointOption;
  return proto.abaoai.CheckpointOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.CheckpointOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.CheckpointOption}
 */
proto.abaoai.CheckpointOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCheckpointId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckpointName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.CheckpointOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.CheckpointOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.CheckpointOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.CheckpointOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCheckpointId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getCheckpointName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint64 checkpoint_id = 1;
 * @return {number}
 */
proto.abaoai.CheckpointOption.prototype.getCheckpointId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.CheckpointOption} returns this
 */
proto.abaoai.CheckpointOption.prototype.setCheckpointId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string checkpoint_name = 2;
 * @return {string}
 */
proto.abaoai.CheckpointOption.prototype.getCheckpointName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.CheckpointOption} returns this
 */
proto.abaoai.CheckpointOption.prototype.setCheckpointName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.abaoai.GetCheckpointOptionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.GetCheckpointOptionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.GetCheckpointOptionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.GetCheckpointOptionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.GetCheckpointOptionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    checkpointOptionsList: jspb.Message.toObjectList(msg.getCheckpointOptionsList(),
    proto.abaoai.CheckpointOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.GetCheckpointOptionsResponse}
 */
proto.abaoai.GetCheckpointOptionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.GetCheckpointOptionsResponse;
  return proto.abaoai.GetCheckpointOptionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.GetCheckpointOptionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.GetCheckpointOptionsResponse}
 */
proto.abaoai.GetCheckpointOptionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.abaoai.CheckpointOption;
      reader.readMessage(value,proto.abaoai.CheckpointOption.deserializeBinaryFromReader);
      msg.addCheckpointOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.GetCheckpointOptionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.GetCheckpointOptionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.GetCheckpointOptionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.GetCheckpointOptionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCheckpointOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.abaoai.CheckpointOption.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CheckpointOption checkpoint_options = 1;
 * @return {!Array<!proto.abaoai.CheckpointOption>}
 */
proto.abaoai.GetCheckpointOptionsResponse.prototype.getCheckpointOptionsList = function() {
  return /** @type{!Array<!proto.abaoai.CheckpointOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.abaoai.CheckpointOption, 1));
};


/**
 * @param {!Array<!proto.abaoai.CheckpointOption>} value
 * @return {!proto.abaoai.GetCheckpointOptionsResponse} returns this
*/
proto.abaoai.GetCheckpointOptionsResponse.prototype.setCheckpointOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.abaoai.CheckpointOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.abaoai.CheckpointOption}
 */
proto.abaoai.GetCheckpointOptionsResponse.prototype.addCheckpointOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.abaoai.CheckpointOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.abaoai.GetCheckpointOptionsResponse} returns this
 */
proto.abaoai.GetCheckpointOptionsResponse.prototype.clearCheckpointOptionsList = function() {
  return this.setCheckpointOptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.GetCheckpointVersionOptionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.GetCheckpointVersionOptionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.GetCheckpointVersionOptionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.GetCheckpointVersionOptionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseModelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    checkpointId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.GetCheckpointVersionOptionsRequest}
 */
proto.abaoai.GetCheckpointVersionOptionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.GetCheckpointVersionOptionsRequest;
  return proto.abaoai.GetCheckpointVersionOptionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.GetCheckpointVersionOptionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.GetCheckpointVersionOptionsRequest}
 */
proto.abaoai.GetCheckpointVersionOptionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseModelId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCheckpointId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.GetCheckpointVersionOptionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.GetCheckpointVersionOptionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.GetCheckpointVersionOptionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.GetCheckpointVersionOptionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseModelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCheckpointId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional string base_model_id = 1;
 * @return {string}
 */
proto.abaoai.GetCheckpointVersionOptionsRequest.prototype.getBaseModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.GetCheckpointVersionOptionsRequest} returns this
 */
proto.abaoai.GetCheckpointVersionOptionsRequest.prototype.setBaseModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 checkpoint_id = 2;
 * @return {number}
 */
proto.abaoai.GetCheckpointVersionOptionsRequest.prototype.getCheckpointId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.GetCheckpointVersionOptionsRequest} returns this
 */
proto.abaoai.GetCheckpointVersionOptionsRequest.prototype.setCheckpointId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.CheckpointVersionOption.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.CheckpointVersionOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.CheckpointVersionOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.CheckpointVersionOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    versionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    versionName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.CheckpointVersionOption}
 */
proto.abaoai.CheckpointVersionOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.CheckpointVersionOption;
  return proto.abaoai.CheckpointVersionOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.CheckpointVersionOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.CheckpointVersionOption}
 */
proto.abaoai.CheckpointVersionOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVersionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersionName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.CheckpointVersionOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.CheckpointVersionOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.CheckpointVersionOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.CheckpointVersionOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersionId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getVersionName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint64 version_id = 1;
 * @return {number}
 */
proto.abaoai.CheckpointVersionOption.prototype.getVersionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.CheckpointVersionOption} returns this
 */
proto.abaoai.CheckpointVersionOption.prototype.setVersionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string version_name = 2;
 * @return {string}
 */
proto.abaoai.CheckpointVersionOption.prototype.getVersionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.CheckpointVersionOption} returns this
 */
proto.abaoai.CheckpointVersionOption.prototype.setVersionName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.abaoai.GetCheckpointVersionOptionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.GetCheckpointVersionOptionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.GetCheckpointVersionOptionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.GetCheckpointVersionOptionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.GetCheckpointVersionOptionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    versionOptionsList: jspb.Message.toObjectList(msg.getVersionOptionsList(),
    proto.abaoai.CheckpointVersionOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.GetCheckpointVersionOptionsResponse}
 */
proto.abaoai.GetCheckpointVersionOptionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.GetCheckpointVersionOptionsResponse;
  return proto.abaoai.GetCheckpointVersionOptionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.GetCheckpointVersionOptionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.GetCheckpointVersionOptionsResponse}
 */
proto.abaoai.GetCheckpointVersionOptionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.abaoai.CheckpointVersionOption;
      reader.readMessage(value,proto.abaoai.CheckpointVersionOption.deserializeBinaryFromReader);
      msg.addVersionOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.GetCheckpointVersionOptionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.GetCheckpointVersionOptionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.GetCheckpointVersionOptionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.GetCheckpointVersionOptionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersionOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.abaoai.CheckpointVersionOption.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CheckpointVersionOption version_options = 1;
 * @return {!Array<!proto.abaoai.CheckpointVersionOption>}
 */
proto.abaoai.GetCheckpointVersionOptionsResponse.prototype.getVersionOptionsList = function() {
  return /** @type{!Array<!proto.abaoai.CheckpointVersionOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.abaoai.CheckpointVersionOption, 1));
};


/**
 * @param {!Array<!proto.abaoai.CheckpointVersionOption>} value
 * @return {!proto.abaoai.GetCheckpointVersionOptionsResponse} returns this
*/
proto.abaoai.GetCheckpointVersionOptionsResponse.prototype.setVersionOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.abaoai.CheckpointVersionOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.abaoai.CheckpointVersionOption}
 */
proto.abaoai.GetCheckpointVersionOptionsResponse.prototype.addVersionOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.abaoai.CheckpointVersionOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.abaoai.GetCheckpointVersionOptionsResponse} returns this
 */
proto.abaoai.GetCheckpointVersionOptionsResponse.prototype.clearVersionOptionsList = function() {
  return this.setVersionOptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.GetCheckpointVersionFileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.GetCheckpointVersionFileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.GetCheckpointVersionFileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.GetCheckpointVersionFileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    checkpointId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    versionId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.GetCheckpointVersionFileRequest}
 */
proto.abaoai.GetCheckpointVersionFileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.GetCheckpointVersionFileRequest;
  return proto.abaoai.GetCheckpointVersionFileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.GetCheckpointVersionFileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.GetCheckpointVersionFileRequest}
 */
proto.abaoai.GetCheckpointVersionFileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCheckpointId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVersionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.GetCheckpointVersionFileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.GetCheckpointVersionFileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.GetCheckpointVersionFileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.GetCheckpointVersionFileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCheckpointId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getVersionId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional uint64 checkpoint_id = 1;
 * @return {number}
 */
proto.abaoai.GetCheckpointVersionFileRequest.prototype.getCheckpointId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.GetCheckpointVersionFileRequest} returns this
 */
proto.abaoai.GetCheckpointVersionFileRequest.prototype.setCheckpointId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 version_id = 2;
 * @return {number}
 */
proto.abaoai.GetCheckpointVersionFileRequest.prototype.getVersionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.GetCheckpointVersionFileRequest} returns this
 */
proto.abaoai.GetCheckpointVersionFileRequest.prototype.setVersionId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.GetCheckpointVersionFileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.GetCheckpointVersionFileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.GetCheckpointVersionFileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.GetCheckpointVersionFileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isFile: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.GetCheckpointVersionFileResponse}
 */
proto.abaoai.GetCheckpointVersionFileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.GetCheckpointVersionFileResponse;
  return proto.abaoai.GetCheckpointVersionFileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.GetCheckpointVersionFileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.GetCheckpointVersionFileResponse}
 */
proto.abaoai.GetCheckpointVersionFileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.GetCheckpointVersionFileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.GetCheckpointVersionFileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.GetCheckpointVersionFileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.GetCheckpointVersionFileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsFile();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_file = 1;
 * @return {boolean}
 */
proto.abaoai.GetCheckpointVersionFileResponse.prototype.getIsFile = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.abaoai.GetCheckpointVersionFileResponse} returns this
 */
proto.abaoai.GetCheckpointVersionFileResponse.prototype.setIsFile = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.abaoai.CheckpointPayload.repeatedFields_ = [12,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.CheckpointPayload.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.CheckpointPayload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.CheckpointPayload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.CheckpointPayload.toObject = function(includeInstance, msg) {
  var f, obj = {
    checkpointId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    checkpointName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    checkpointDescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    modelType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    nsfw: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    timestamp: jspb.Message.getFieldWithDefault(msg, 6, 0),
    downloadCount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    favoriteCount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    commentCount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    ratingCount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    tippedAmountCount: jspb.Message.getFieldWithDefault(msg, 11, 0),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    versionsList: jspb.Message.toObjectList(msg.getVersionsList(),
    proto.abaoai.CheckpointVersionPayload.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.CheckpointPayload}
 */
proto.abaoai.CheckpointPayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.CheckpointPayload;
  return proto.abaoai.CheckpointPayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.CheckpointPayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.CheckpointPayload}
 */
proto.abaoai.CheckpointPayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCheckpointId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckpointName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckpointDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelType(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNsfw(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDownloadCount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFavoriteCount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCommentCount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRatingCount(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTippedAmountCount(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 13:
      var value = new proto.abaoai.CheckpointVersionPayload;
      reader.readMessage(value,proto.abaoai.CheckpointVersionPayload.deserializeBinaryFromReader);
      msg.addVersions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.CheckpointPayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.CheckpointPayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.CheckpointPayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.CheckpointPayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCheckpointId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getCheckpointName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCheckpointDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getModelType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNsfw();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getDownloadCount();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getFavoriteCount();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getCommentCount();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getRatingCount();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getTippedAmountCount();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getVersionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.abaoai.CheckpointVersionPayload.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 checkpoint_id = 1;
 * @return {number}
 */
proto.abaoai.CheckpointPayload.prototype.getCheckpointId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.CheckpointPayload} returns this
 */
proto.abaoai.CheckpointPayload.prototype.setCheckpointId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string checkpoint_name = 2;
 * @return {string}
 */
proto.abaoai.CheckpointPayload.prototype.getCheckpointName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.CheckpointPayload} returns this
 */
proto.abaoai.CheckpointPayload.prototype.setCheckpointName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string checkpoint_description = 3;
 * @return {string}
 */
proto.abaoai.CheckpointPayload.prototype.getCheckpointDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.CheckpointPayload} returns this
 */
proto.abaoai.CheckpointPayload.prototype.setCheckpointDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string model_type = 4;
 * @return {string}
 */
proto.abaoai.CheckpointPayload.prototype.getModelType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.CheckpointPayload} returns this
 */
proto.abaoai.CheckpointPayload.prototype.setModelType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool nsfw = 5;
 * @return {boolean}
 */
proto.abaoai.CheckpointPayload.prototype.getNsfw = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.abaoai.CheckpointPayload} returns this
 */
proto.abaoai.CheckpointPayload.prototype.setNsfw = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int64 timestamp = 6;
 * @return {number}
 */
proto.abaoai.CheckpointPayload.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.CheckpointPayload} returns this
 */
proto.abaoai.CheckpointPayload.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 download_count = 7;
 * @return {number}
 */
proto.abaoai.CheckpointPayload.prototype.getDownloadCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.CheckpointPayload} returns this
 */
proto.abaoai.CheckpointPayload.prototype.setDownloadCount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 favorite_count = 8;
 * @return {number}
 */
proto.abaoai.CheckpointPayload.prototype.getFavoriteCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.CheckpointPayload} returns this
 */
proto.abaoai.CheckpointPayload.prototype.setFavoriteCount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 comment_count = 9;
 * @return {number}
 */
proto.abaoai.CheckpointPayload.prototype.getCommentCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.CheckpointPayload} returns this
 */
proto.abaoai.CheckpointPayload.prototype.setCommentCount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 rating_count = 10;
 * @return {number}
 */
proto.abaoai.CheckpointPayload.prototype.getRatingCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.CheckpointPayload} returns this
 */
proto.abaoai.CheckpointPayload.prototype.setRatingCount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 tipped_amount_count = 11;
 * @return {number}
 */
proto.abaoai.CheckpointPayload.prototype.getTippedAmountCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.CheckpointPayload} returns this
 */
proto.abaoai.CheckpointPayload.prototype.setTippedAmountCount = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * repeated string tags = 12;
 * @return {!Array<string>}
 */
proto.abaoai.CheckpointPayload.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.abaoai.CheckpointPayload} returns this
 */
proto.abaoai.CheckpointPayload.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.abaoai.CheckpointPayload} returns this
 */
proto.abaoai.CheckpointPayload.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.abaoai.CheckpointPayload} returns this
 */
proto.abaoai.CheckpointPayload.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * repeated CheckpointVersionPayload versions = 13;
 * @return {!Array<!proto.abaoai.CheckpointVersionPayload>}
 */
proto.abaoai.CheckpointPayload.prototype.getVersionsList = function() {
  return /** @type{!Array<!proto.abaoai.CheckpointVersionPayload>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.abaoai.CheckpointVersionPayload, 13));
};


/**
 * @param {!Array<!proto.abaoai.CheckpointVersionPayload>} value
 * @return {!proto.abaoai.CheckpointPayload} returns this
*/
proto.abaoai.CheckpointPayload.prototype.setVersionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.abaoai.CheckpointVersionPayload=} opt_value
 * @param {number=} opt_index
 * @return {!proto.abaoai.CheckpointVersionPayload}
 */
proto.abaoai.CheckpointPayload.prototype.addVersions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.abaoai.CheckpointVersionPayload, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.abaoai.CheckpointPayload} returns this
 */
proto.abaoai.CheckpointPayload.prototype.clearVersionsList = function() {
  return this.setVersionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.abaoai.CheckpointVersionPayload.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.CheckpointVersionPayload.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.CheckpointVersionPayload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.CheckpointVersionPayload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.CheckpointVersionPayload.toObject = function(includeInstance, msg) {
  var f, obj = {
    versionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    checkpointId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    baseModelId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    versionName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    versionDescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
    fileId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    fileName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    downloadUrl: jspb.Message.getFieldWithDefault(msg, 8, ""),
    sha256: jspb.Message.getFieldWithDefault(msg, 9, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 10, 0),
    versionImagesList: jspb.Message.toObjectList(msg.getVersionImagesList(),
    image_pb.ImagePayload.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.CheckpointVersionPayload}
 */
proto.abaoai.CheckpointVersionPayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.CheckpointVersionPayload;
  return proto.abaoai.CheckpointVersionPayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.CheckpointVersionPayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.CheckpointVersionPayload}
 */
proto.abaoai.CheckpointVersionPayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVersionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCheckpointId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseModelId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersionName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersionDescription(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFileId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDownloadUrl(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSha256(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 11:
      var value = new image_pb.ImagePayload;
      reader.readMessage(value,image_pb.ImagePayload.deserializeBinaryFromReader);
      msg.addVersionImages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.CheckpointVersionPayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.CheckpointVersionPayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.CheckpointVersionPayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.CheckpointVersionPayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersionId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getCheckpointId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getBaseModelId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVersionName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVersionDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFileId();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDownloadUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSha256();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getVersionImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      image_pb.ImagePayload.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 version_id = 1;
 * @return {number}
 */
proto.abaoai.CheckpointVersionPayload.prototype.getVersionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.CheckpointVersionPayload} returns this
 */
proto.abaoai.CheckpointVersionPayload.prototype.setVersionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 checkpoint_id = 2;
 * @return {number}
 */
proto.abaoai.CheckpointVersionPayload.prototype.getCheckpointId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.CheckpointVersionPayload} returns this
 */
proto.abaoai.CheckpointVersionPayload.prototype.setCheckpointId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string base_model_id = 3;
 * @return {string}
 */
proto.abaoai.CheckpointVersionPayload.prototype.getBaseModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.CheckpointVersionPayload} returns this
 */
proto.abaoai.CheckpointVersionPayload.prototype.setBaseModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string version_name = 4;
 * @return {string}
 */
proto.abaoai.CheckpointVersionPayload.prototype.getVersionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.CheckpointVersionPayload} returns this
 */
proto.abaoai.CheckpointVersionPayload.prototype.setVersionName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string version_description = 5;
 * @return {string}
 */
proto.abaoai.CheckpointVersionPayload.prototype.getVersionDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.CheckpointVersionPayload} returns this
 */
proto.abaoai.CheckpointVersionPayload.prototype.setVersionDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint64 file_id = 6;
 * @return {number}
 */
proto.abaoai.CheckpointVersionPayload.prototype.getFileId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.CheckpointVersionPayload} returns this
 */
proto.abaoai.CheckpointVersionPayload.prototype.setFileId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string file_name = 7;
 * @return {string}
 */
proto.abaoai.CheckpointVersionPayload.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.CheckpointVersionPayload} returns this
 */
proto.abaoai.CheckpointVersionPayload.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string download_url = 8;
 * @return {string}
 */
proto.abaoai.CheckpointVersionPayload.prototype.getDownloadUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.CheckpointVersionPayload} returns this
 */
proto.abaoai.CheckpointVersionPayload.prototype.setDownloadUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string sha256 = 9;
 * @return {string}
 */
proto.abaoai.CheckpointVersionPayload.prototype.getSha256 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.CheckpointVersionPayload} returns this
 */
proto.abaoai.CheckpointVersionPayload.prototype.setSha256 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 timestamp = 10;
 * @return {number}
 */
proto.abaoai.CheckpointVersionPayload.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.CheckpointVersionPayload} returns this
 */
proto.abaoai.CheckpointVersionPayload.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated ImagePayload version_images = 11;
 * @return {!Array<!proto.abaoai.ImagePayload>}
 */
proto.abaoai.CheckpointVersionPayload.prototype.getVersionImagesList = function() {
  return /** @type{!Array<!proto.abaoai.ImagePayload>} */ (
    jspb.Message.getRepeatedWrapperField(this, image_pb.ImagePayload, 11));
};


/**
 * @param {!Array<!proto.abaoai.ImagePayload>} value
 * @return {!proto.abaoai.CheckpointVersionPayload} returns this
*/
proto.abaoai.CheckpointVersionPayload.prototype.setVersionImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.abaoai.ImagePayload=} opt_value
 * @param {number=} opt_index
 * @return {!proto.abaoai.ImagePayload}
 */
proto.abaoai.CheckpointVersionPayload.prototype.addVersionImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.abaoai.ImagePayload, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.abaoai.CheckpointVersionPayload} returns this
 */
proto.abaoai.CheckpointVersionPayload.prototype.clearVersionImagesList = function() {
  return this.setVersionImagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.SearchCheckpointImagesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.SearchCheckpointImagesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.SearchCheckpointImagesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.SearchCheckpointImagesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: jspb.Message.getFieldWithDefault(msg, 1, 0),
    baseModelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    checkpointId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    versionId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    nsfw: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.SearchCheckpointImagesRequest}
 */
proto.abaoai.SearchCheckpointImagesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.SearchCheckpointImagesRequest;
  return proto.abaoai.SearchCheckpointImagesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.SearchCheckpointImagesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.SearchCheckpointImagesRequest}
 */
proto.abaoai.SearchCheckpointImagesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStart(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseModelId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCheckpointId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVersionId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNsfw(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.SearchCheckpointImagesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.SearchCheckpointImagesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.SearchCheckpointImagesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.SearchCheckpointImagesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getBaseModelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCheckpointId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getVersionId();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getNsfw();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional uint32 start = 1;
 * @return {number}
 */
proto.abaoai.SearchCheckpointImagesRequest.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.SearchCheckpointImagesRequest} returns this
 */
proto.abaoai.SearchCheckpointImagesRequest.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string base_model_id = 2;
 * @return {string}
 */
proto.abaoai.SearchCheckpointImagesRequest.prototype.getBaseModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.SearchCheckpointImagesRequest} returns this
 */
proto.abaoai.SearchCheckpointImagesRequest.prototype.setBaseModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 checkpoint_id = 3;
 * @return {number}
 */
proto.abaoai.SearchCheckpointImagesRequest.prototype.getCheckpointId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.SearchCheckpointImagesRequest} returns this
 */
proto.abaoai.SearchCheckpointImagesRequest.prototype.setCheckpointId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 version_id = 4;
 * @return {number}
 */
proto.abaoai.SearchCheckpointImagesRequest.prototype.getVersionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.SearchCheckpointImagesRequest} returns this
 */
proto.abaoai.SearchCheckpointImagesRequest.prototype.setVersionId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool nsfw = 5;
 * @return {boolean}
 */
proto.abaoai.SearchCheckpointImagesRequest.prototype.getNsfw = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.abaoai.SearchCheckpointImagesRequest} returns this
 */
proto.abaoai.SearchCheckpointImagesRequest.prototype.setNsfw = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.abaoai.SearchCheckpointImagesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.SearchCheckpointImagesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.SearchCheckpointImagesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.SearchCheckpointImagesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.SearchCheckpointImagesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    image_pb.ImagePayload.toObject, includeInstance),
    nextStart: jspb.Message.getFieldWithDefault(msg, 2, 0),
    total: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.SearchCheckpointImagesResponse}
 */
proto.abaoai.SearchCheckpointImagesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.SearchCheckpointImagesResponse;
  return proto.abaoai.SearchCheckpointImagesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.SearchCheckpointImagesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.SearchCheckpointImagesResponse}
 */
proto.abaoai.SearchCheckpointImagesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new image_pb.ImagePayload;
      reader.readMessage(value,image_pb.ImagePayload.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNextStart(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.SearchCheckpointImagesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.SearchCheckpointImagesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.SearchCheckpointImagesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.SearchCheckpointImagesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      image_pb.ImagePayload.serializeBinaryToWriter
    );
  }
  f = message.getNextStart();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * repeated ImagePayload images = 1;
 * @return {!Array<!proto.abaoai.ImagePayload>}
 */
proto.abaoai.SearchCheckpointImagesResponse.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.abaoai.ImagePayload>} */ (
    jspb.Message.getRepeatedWrapperField(this, image_pb.ImagePayload, 1));
};


/**
 * @param {!Array<!proto.abaoai.ImagePayload>} value
 * @return {!proto.abaoai.SearchCheckpointImagesResponse} returns this
*/
proto.abaoai.SearchCheckpointImagesResponse.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.abaoai.ImagePayload=} opt_value
 * @param {number=} opt_index
 * @return {!proto.abaoai.ImagePayload}
 */
proto.abaoai.SearchCheckpointImagesResponse.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.abaoai.ImagePayload, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.abaoai.SearchCheckpointImagesResponse} returns this
 */
proto.abaoai.SearchCheckpointImagesResponse.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional uint32 next_start = 2;
 * @return {number}
 */
proto.abaoai.SearchCheckpointImagesResponse.prototype.getNextStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.SearchCheckpointImagesResponse} returns this
 */
proto.abaoai.SearchCheckpointImagesResponse.prototype.setNextStart = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 total = 3;
 * @return {number}
 */
proto.abaoai.SearchCheckpointImagesResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.SearchCheckpointImagesResponse} returns this
 */
proto.abaoai.SearchCheckpointImagesResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.CheckPayload.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.CheckPayload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.CheckPayload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.CheckPayload.toObject = function(includeInstance, msg) {
  var f, obj = {
    checkId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    drawerId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    recipientId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    drawerEmail: jspb.Message.getFieldWithDefault(msg, 4, ""),
    recipientEmail: jspb.Message.getFieldWithDefault(msg, 5, ""),
    drawerName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    recipientName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 8, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    description: jspb.Message.getFieldWithDefault(msg, 10, ""),
    code: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.CheckPayload}
 */
proto.abaoai.CheckPayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.CheckPayload;
  return proto.abaoai.CheckPayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.CheckPayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.CheckPayload}
 */
proto.abaoai.CheckPayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCheckId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDrawerId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRecipientId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrawerEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecipientEmail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrawerName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecipientName(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.CheckPayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.CheckPayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.CheckPayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.CheckPayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCheckId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getDrawerId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getRecipientId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getDrawerEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRecipientEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDrawerName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRecipientName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional uint64 check_id = 1;
 * @return {number}
 */
proto.abaoai.CheckPayload.prototype.getCheckId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.CheckPayload} returns this
 */
proto.abaoai.CheckPayload.prototype.setCheckId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 drawer_id = 2;
 * @return {number}
 */
proto.abaoai.CheckPayload.prototype.getDrawerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.CheckPayload} returns this
 */
proto.abaoai.CheckPayload.prototype.setDrawerId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 recipient_id = 3;
 * @return {number}
 */
proto.abaoai.CheckPayload.prototype.getRecipientId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.CheckPayload} returns this
 */
proto.abaoai.CheckPayload.prototype.setRecipientId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string drawer_email = 4;
 * @return {string}
 */
proto.abaoai.CheckPayload.prototype.getDrawerEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.CheckPayload} returns this
 */
proto.abaoai.CheckPayload.prototype.setDrawerEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string recipient_email = 5;
 * @return {string}
 */
proto.abaoai.CheckPayload.prototype.getRecipientEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.CheckPayload} returns this
 */
proto.abaoai.CheckPayload.prototype.setRecipientEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string drawer_name = 6;
 * @return {string}
 */
proto.abaoai.CheckPayload.prototype.getDrawerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.CheckPayload} returns this
 */
proto.abaoai.CheckPayload.prototype.setDrawerName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string recipient_name = 7;
 * @return {string}
 */
proto.abaoai.CheckPayload.prototype.getRecipientName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.CheckPayload} returns this
 */
proto.abaoai.CheckPayload.prototype.setRecipientName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 timestamp = 8;
 * @return {number}
 */
proto.abaoai.CheckPayload.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.CheckPayload} returns this
 */
proto.abaoai.CheckPayload.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 amount = 9;
 * @return {number}
 */
proto.abaoai.CheckPayload.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.CheckPayload} returns this
 */
proto.abaoai.CheckPayload.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string description = 10;
 * @return {string}
 */
proto.abaoai.CheckPayload.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.CheckPayload} returns this
 */
proto.abaoai.CheckPayload.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string code = 11;
 * @return {string}
 */
proto.abaoai.CheckPayload.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.abaoai.CheckPayload} returns this
 */
proto.abaoai.CheckPayload.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.ListChecksRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.ListChecksRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.ListChecksRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.ListChecksRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.ListChecksRequest}
 */
proto.abaoai.ListChecksRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.ListChecksRequest;
  return proto.abaoai.ListChecksRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.ListChecksRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.ListChecksRequest}
 */
proto.abaoai.ListChecksRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.ListChecksRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.ListChecksRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.ListChecksRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.ListChecksRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 account_id = 1;
 * @return {number}
 */
proto.abaoai.ListChecksRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.abaoai.ListChecksRequest} returns this
 */
proto.abaoai.ListChecksRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.abaoai.ListChecksResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.abaoai.ListChecksResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.abaoai.ListChecksResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.abaoai.ListChecksResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.ListChecksResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    checksList: jspb.Message.toObjectList(msg.getChecksList(),
    proto.abaoai.CheckPayload.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.abaoai.ListChecksResponse}
 */
proto.abaoai.ListChecksResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.abaoai.ListChecksResponse;
  return proto.abaoai.ListChecksResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.abaoai.ListChecksResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.abaoai.ListChecksResponse}
 */
proto.abaoai.ListChecksResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.abaoai.CheckPayload;
      reader.readMessage(value,proto.abaoai.CheckPayload.deserializeBinaryFromReader);
      msg.addChecks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.abaoai.ListChecksResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.abaoai.ListChecksResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.abaoai.ListChecksResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.abaoai.ListChecksResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChecksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.abaoai.CheckPayload.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CheckPayload checks = 1;
 * @return {!Array<!proto.abaoai.CheckPayload>}
 */
proto.abaoai.ListChecksResponse.prototype.getChecksList = function() {
  return /** @type{!Array<!proto.abaoai.CheckPayload>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.abaoai.CheckPayload, 1));
};


/**
 * @param {!Array<!proto.abaoai.CheckPayload>} value
 * @return {!proto.abaoai.ListChecksResponse} returns this
*/
proto.abaoai.ListChecksResponse.prototype.setChecksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.abaoai.CheckPayload=} opt_value
 * @param {number=} opt_index
 * @return {!proto.abaoai.CheckPayload}
 */
proto.abaoai.ListChecksResponse.prototype.addChecks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.abaoai.CheckPayload, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.abaoai.ListChecksResponse} returns this
 */
proto.abaoai.ListChecksResponse.prototype.clearChecksList = function() {
  return this.setChecksList([]);
};


goog.object.extend(exports, proto.abaoai);
