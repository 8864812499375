import { observer } from "mobx-react-lite"
import {
    Button,
    useToastController,
    Toast,
    ToastTitle,
    Spinner,
    Toaster,
} from "@fluentui/react-components";
import { S } from "../../State"
import { ImageConfig } from "./ImageConfig"

const ImagePanel = observer(() => {
    const { dispatchToast } = useToastController();
    return (
        <div className="image_panel">
            <Toaster position="top-start" />
            <div className="image_config_row">
                <ImageConfig />
            </div>
            <div className="image_action_row">
                <Button
                    disabled={S.image.generate_disabled}
                    className="image_action_button"
                    appearance="primary"
                    onClick={() => {
                        dispatchToast(
                            <Toast>
                                <ToastTitle media={<Spinner size="tiny" />}>
                                    Image is queued for generation.
                                </ToastTitle>
                            </Toast>,
                        )
                        S.image.onClickGenerate()
                    }}
                >
                    Generate
                </Button>
            </div >
        </div >
    )
})

export { ImagePanel }