import { observer } from "mobx-react-lite"
import { Select, Link, Input, Field, Button } from "@fluentui/react-components"
import { S } from "../State"
import {
    SendRegular,
} from "@fluentui/react-icons";

const SignupPage = observer(() =>
    <div className="signup_page">
        <div className="hero">

            <h2 className="title">abao.ai</h2>

            <h1 className="title">Create your account</h1>

            <Field
                className="textfield"
                label="Name"
                required={true}
                validationMessage={S.signup.name_error_message}
            >
                <Input
                    onChange={(_, { value }) => S.signup.onChangeName(value)}
                />
            </Field>

            <Field
                className="textfield"
                label="Email"
                required={true}
                validationMessage={S.signup.email_error_message}
            >
                <Input
                    onChange={(_, { value }) => S.signup.onChangeEmail(value)}
                    required={true}
                />
            </Field>

            <Field
                className="textfield"
                label="Password"
                required={true}
                validationMessage={S.signup.password_error_message}
            >
                <Input
                    type="password"
                    onChange={(_, { value }) => S.signup.onChangePassword(value)}
                    required={true}
                />
            </Field>

            <div className="email_code_row">
                <Field
                    className="code"
                    label="Email verification code"
                    required={true}
                    validationMessage={S.signup.code_error_message}
                >
                    <Input
                        type="number"
                        onChange={(_, { value }) => S.signup.onChangeCode(value)}
                        required={true}
                        contentAfter={
                            S.signup.email_countdown === 0 ?
                                <Button
                                    icon={<SendRegular />}
                                    appearance="transparent"
                                    onClick={() => S.signup.onClickSendCode()}
                                />
                                :
                                S.signup.email_countdown + "s"
                        }
                    />
                </Field>
            </div>

            <div className="phone_row">
                <Field
                    className="country"
                    label="Country"
                    required={true}
                    validationMessage={S.signup.country_error_message}
                >

                    <Select
                        className="country_select"
                        onChange={(_, { value }) => S.signup.onChangeCountry(value)}
                    >
                        {
                            S.signup.country_options.map(country =>
                                <option key={country.name} value={country.code} >
                                    {country.code} {country.name}
                                </option>
                            )
                        }
                    </Select>
                </Field>

            </div>

            <div className="continue_row">
                <Button
                    className="continue_button"
                    appearance="primary"
                    onClick={() => S.signup.onClickContinue()}
                >
                    Continue
                </Button>
            </div>

            <div className="login_row">
                <div className="question">Already have an account?</div>
                <Link className="login_link" onClick={() => S.onClickPage("LOGIN")}>Log in</Link>
            </div>

        </div>
    </div >
)


export { SignupPage }
