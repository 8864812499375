import { observer } from "mobx-react-lite"
import { S } from "../../../State"
import { Textarea, Field, Select } from "@fluentui/react-components"

const SD3Config = observer(() =>
    <>
        <div className="size_row">
            <Field
                className="size"
                label="Size"
                required
                validationMessage={S.image.size_error_message}
            >
                <Select
                    value={S.image.selected_size}
                    onChange={(_, { value }) => S.image.onChangeSize(value!)}
                >
                    {S.image.size_options.map((option) => (
                        <option key={option.key} value={option.key} >
                            {option.text}
                        </option>
                    ))}
                </Select>
            </Field>
        </div>
        <div className="prompt_row">
            <Field
                className="prompt"
                label="Prompt"
                required
                validationMessage={S.image.prompt_error_message}
            >
                <Textarea
                    className="prompt"
                    value={S.image.prompt}
                    onChange={(_, { value }) => S.image.onChangePrompt(value!)}
                    rows={12}
                    required
                >
                </Textarea>
            </Field>
        </div>
        <div className="negative_prompt_row">
            <Field
                className="negative_prompt"
                label="Negative Prompt"
                validationMessage={S.image.negative_prompt_error_message}
            >
                <Textarea
                    className="negative_prompt"
                    value={S.image.negative_prompt}
                    onChange={(_, { value }) => S.image.onChangeNegativePrompt(value!)}
                    rows={12}
                >
                </Textarea>
            </Field>
        </div>
    </>
)
export { SD3Config }