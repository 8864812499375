import { observer } from "mobx-react-lite"
import { S } from "../../State"
import { TopBar } from "../TopBar"
import { DeleteChatDialog } from "./DeleteChatDialog"
import { Caption2 } from "@fluentui/react-components"
import { ChatDialog, ChatSystem } from "./ChatDialog"
import { Input, Button } from "@fluentui/react-components"
import { SendRegular } from "@fluentui/react-icons"

const ChatView = observer(() =>
  <div className="main_chat">
    <DeleteChatDialog />
    <TopBar />
    <div id="chat_slider" className="chat_slider">
      <div className="blank_row"></div>
      <div id="dialogs" className="dialogs">
        {
          S.chat.dialogs.map((dialog) =>
            <ChatDialog
              key={dialog.dialog_id}
              dialog={dialog}
            />
          )
        }
        {
          S.chat.system_prompt.length === 0 ?
            null
            :
            <ChatSystem />
        }
      </div>
    </div>
    <div className="prompt_row">
      {
        S.main.chat_id === 0 ?
          <Button
            className="prompt"
            appearance="primary"
            onClick={() => S.main.onClickNewChat()}
          >
            New Chat
          </Button>
          :
          <Input
            className="prompt"
            onChange={(_, { value }) => S.chat.onChangePrompt(value)}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                S.chat.onClickSend()
              }
            }}
            value={S.chat.prompt}
            required={true}
            contentAfter={
              <Button
                icon={<SendRegular />}
                appearance="transparent"
                onClick={() => S.chat.onClickSend()}
              />
            }
          />
      }
    </div>

    <div className="warning_row">
      <Caption2>
        <b>abao.ai</b> may produce inaccurate information about people, places, or facts.
      </Caption2>
    </div>

  </div >
)

export { ChatView }