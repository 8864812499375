import { makeObservable, observable, runInAction, computed } from "mobx"
import { S } from "../State"
import { CHAT, ACCESS } from "../Client"
import grpcWeb from "grpc-web"
import React from "react"


function getRandom() {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    return array[0]; // Normalize the value to be between 0 and 1
}

class ImageState {
    right_title: string = ""
    checkpoint_id: number = 0
    creator_name: string = ""
    creators: Record<number, proto.abaoai.CreatorPayload> = {}
    gallery_ref: React.RefObject<HTMLDivElement> = React.createRef();
    images: proto.abaoai.ImagePayload[] = []
    is_civitai: boolean = false
    models: Record<string, proto.abaoai.ModelPayload> = {}
    negative_prompt: string = ""
    prompt: string = ""
    revised_prompt: string = ""
    selected_model_id: string = ""
    selected_quality: string = ""
    selected_size: string = ""
    selected_style: string = ""
    start: number = 0
    timeline_account_id: number = 0
    total: number = 0
    url: string = ""
    version_id: number = 0
    checkpoint_name: string = ""
    version_name: string = ""
    checkpoint_options: proto.abaoai.CheckpointOption[] = []
    version_options: proto.abaoai.CheckpointVersionOption[] = []
    selected_sampler = ""
    selected_checkpoint_id = 0
    selected_version_id: number = 0
    selected_image_id: number = 0
    selected_creator_id: number = 0
    selected_steps: number = 0
    selected_cfg_scale: number = 0
    is_file: boolean = false
    title: string = "Images"

    quality_options = [
        { key: "standard", text: "Standard" },
        { key: "hd", text: "HD" },
    ]

    dalle3_size_options = [
        { key: "1024x1024", text: "1024x1024" },
        { key: "1792x1024", text: "1792x1024" },
        { key: "1024x1792", text: "1024x1792" },
    ]

    sdxl_size_options = [
        { key: "1024x1024", text: "1024x1024" },
        { key: "1216x832", text: "1216x832" },
        { key: "832x1216", text: "832x1216" },
    ]

    sd3_size_options = [
        { key: "1024x1024", text: "1024x1024" },
        { key: "1216x832", text: "1216x832" },
        { key: "832x1216", text: "832x1216" },
    ]

    sd15_size_options = [
        { key: "512x512", text: "512x512" },
        { key: "768x512", text: "768x512" },
        { key: "512x768", text: "512x768" },
    ]


    dalle3_style_options = [
        { key: "vivid", text: "Vivid" },
        { key: "natural", text: "Natural" },
    ]

    sdxl_style_options = [
        { key: "", text: "-" },
        { key: "3d-model", text: "3D Model" },
        { key: "analog-film", text: "Analog Film" },
        { key: "anime", text: "Anime" },
        { key: "cinematic", text: "Cinematic" },
        { key: "comic-book", text: "Comic Book" },
        { key: "digital-art", text: "Digital Art" },
        { key: "enhance", text: "Enhance" },
        { key: "fantasy-art", text: "Fantasy Art" },
        { key: "isometric", text: "Isometric" },
        { key: "line-art", text: "Line Art" },
        { key: "low-poly", text: "Low Poly" },
        { key: "modeling-compound", text: "Modeling Compound" },
        { key: "neon-punk", text: "Neon Punk" },
        { key: "origami", text: "Origami" },
        { key: "photographic", text: "Photographic" },
        { key: "pixel-art", text: "Pixel Art" },
        { key: "tile-texture", text: "Tile Texture" },
    ]

    sampler_options = [
        { key: "", text: "-" },
        { key: "DDIM", text: "DDIM" },
        { key: "DDPM", text: "DDPM" },
        { key: "DPM_2_ANCESTRAL", text: "DPM_2_ANCESTRAL" },
        { key: "DPM_2", text: "DPM_2" },
        { key: "DPM_ADAPTIVE", text: "DPM_ADAPTIVE" },
        { key: "DPM_FAST", text: "DPM_FAST" },
        { key: "DPMPP_2M_SDE_GPU", text: "DPMPP_2M_SDE_GPU" },
        { key: "DPMPP_2M_SDE", text: "DPMPP_2M_SDE" },
        { key: "DPMPP_2M", text: "DPMPP_2M" },
        { key: "DPMPP_2S_ANCESTRAL", text: "DPMPP_2S_ANCESTRAL" },
        { key: "DPMPP_3M_SDE_GPU", text: "DPMPP_3M_SDE_GPU" },
        { key: "DPMPP_3M_SDE", text: "DPMPP_3M_SDE" },
        { key: "DPMPP_SDE_GPU", text: "DPMPP_SDE_GPU" },
        { key: "DPMPP_SDE", text: "DPMPP_SDE" },
        { key: "EULER_ANCESTRAL", text: "EULER_ANCESTRAL" },
        { key: "EULER", text: "EULER" },
        { key: "HEUN", text: "HEUN" },
        { key: "HEUNPP2", text: "HEUNPP2" },
        { key: "LCM", text: "LCM" },
        { key: "LMS", text: "LMS" },
        { key: "UNI_PC_BH2", text: "UNI_PC_BH2" },
        { key: "UNI_PC", text: "UNI_PC" },
    ]

    steps_options = [
        { key: "", text: "-" }
    ]

    cfg_scale_options = [
        { key: "", text: "-" }
    ]

    constructor() {
        for (let i = 10; i <= 50; i++) {
            this.steps_options.push({ key: String(i), text: String(i) })
        }
        for (let i = 0; i <= 35; i++) {
            this.cfg_scale_options.push({ key: String(i), text: String(i) })
        }
        makeObservable(this, {
            cfg_scale_error_message: computed,
            checkpoint_id: observable,
            checkpoint_name: observable,
            checkpoint_options: observable,
            creator_name: observable,
            creators: observable,
            end: computed,
            generate_disabled: computed,
            images: observable,
            is_civitai: observable,
            loader: computed,
            negative_prompt_error_message: computed,
            negative_prompt: observable,
            prompt_error_message: computed,
            prompt: observable,
            revised_prompt: observable,
            right_title: observable,
            selected_cfg_scale: observable,
            selected_checkpoint_id: observable,
            selected_creator_id: observable,
            selected_creator: computed,
            selected_model_id: observable,
            selected_quality: observable,
            selected_sampler: observable,
            selected_size: observable,
            selected_steps: observable,
            selected_style: observable,
            selected_version_id: observable,
            size_error_message: computed,
            size_options: computed,
            start: observable,
            steps_error_message: computed,
            style_error_message: computed,
            style_options: computed,
            timeline_account_id: observable,
            title: observable,
            total: observable,
            url: observable,
            version_id: observable,
            version_name: observable,
            version_options: observable,
            is_file: observable,
            model_id_error_message: computed,
            checkpoint_id_error_message: computed,
            version_id_error_message: computed,
            sampler_error_message: computed,
        })
    }

    get generate_disabled(): boolean {
        let selected_model = this.models[this.selected_model_id]
        if (!selected_model) {
            return true
        }
        if (this.selected_model_id === "runwayml/stable-diffusion-v1-5" && !this.is_file) {
            return true
        }
        if (this.selected_model_id === "stabilityai/stable-diffusion-xl-base-1.0" && !this.is_file) {
            return true
        }
        return false
    }

    get selected_creator() {
        return this.creators[this.selected_creator_id]
    }

    get model_options() {
        let models = []
        for (let model of Object.values(this.models)) {
            models.push(model)
        }
        models.sort((a, b) => a.getFunctionId() - b.getFunctionId())
        let options = [{ key: "", text: "-" }]
        for (let model of models) {
            let option = { key: model.getModelId(), text: model.getModelName() }
            options.push(option)
        }
        return options
    }

    get size_options() {
        if (this.selected_model_id === "openai/dall-e-3") {
            return this.dalle3_size_options
        }
        if (this.selected_model_id === "stabilityai/stable-diffusion-xl-1024-v1-0") {
            return this.sdxl_size_options
        }
        if (this.selected_model_id === "stabilityai/sd3") {
            return this.sdxl_size_options
        }
        if (this.selected_model_id === "runwayml/stable-diffusion-v1-5") {
            return this.sd15_size_options
        }
        if (this.selected_model_id === "stabilityai/stable-diffusion-xl-base-1.0") {
            return this.sdxl_size_options
        }
        return []
    }

    get style_options() {
        if (this.selected_model_id === "openai/dall-e-3") {
            return this.dalle3_style_options
        }
        if (this.selected_model_id === "stabilityai/stable-diffusion-xl-1024-v1-0") {
            return this.sdxl_style_options
        }
        return []
    }

    get has_more() {
        return this.start === 0 || this.start < this.total
    }

    get loader() {
        return "Loading..." + this.start + "/" + this.total
    }

    get end() {
        return "End..." + this.start + "/" + this.total
    }

    get checkpoint_id_error_message() {
        if (S.error_field === "checkpoint_id") {
            return S.error_message
        }
        return ""
    }

    get version_id_error_message() {
        if (S.error_field === "version_id") {
            return S.error_message
        }
        return ""
    }

    get sampler_error_message() {
        if (S.error_field === "sampler") {
            return S.error_message
        }
        return ""
    }

    get quality_error_message() {
        if (S.error_field === "quality") {
            return S.error_message
        }
        return ""
    }

    get model_id_error_message() {
        if (S.error_field === "model_id") {
            return S.error_message
        }
        return ""
    }

    get size_error_message() {
        if (S.error_field === "size") {
            return S.error_message
        }
        return ""
    }

    get style_error_message() {
        if (S.error_field === "style") {
            return S.error_message
        }
        return ""
    }

    get prompt_error_message() {
        if (S.error_field === "prompt") {
            return S.error_message
        }
        return ""
    }

    get negative_prompt_error_message() {
        if (S.error_field === "negative_prompt") {
            return S.error_message
        }
        return ""
    }

    get steps_error_message() {
        if (S.error_field === "steps") {
            return S.error_message
        }
        return ""
    }

    get cfg_scale_error_message() {
        if (S.error_field === "cfg_scale") {
            return S.error_message
        }
        return ""
    }


    onChangeCfgScale(cfg_scale: number) {
        runInAction(() => {
            this.selected_cfg_scale = cfg_scale
        })
    }

    onChangeSteps(steps: number) {
        runInAction(() => {
            this.selected_steps = steps
        })
    }

    onChangeSampler(sampler: string) {
        runInAction(() => {
            this.selected_sampler = sampler
        })
    }

    onChangePrompt(value: string) {
        runInAction(() => {
            this.prompt = value
        })
    }

    onChangeNegativePrompt(value: string) {
        runInAction(() => {
            this.negative_prompt = value
        })
    }

    onChangeStyle(style: string) {
        runInAction(() => {
            this.selected_style = style
        })
    }

    onChangeSize(size: string) {
        runInAction(() => {
            this.selected_size = size
        })
    }

    onChangeQuality(quality: string) {
        runInAction(() => {
            this.selected_quality = quality
        })
    }


    async onChangeModel(model_id: string) {
        runInAction(() => {
            this.selected_model_id = model_id
            if (model_id === "openai/dall-e-3") {
                this.selected_quality = "standard"
                this.selected_size = "1024x1024"
                this.selected_style = "vivid"
            }
            if (model_id === "stabilityai/stable-diffusion-xl-1024-v1-0") {
                this.selected_size = "832x1216"
                const ri = Math.floor(Math.random() * this.style_options.length);
                this.selected_style = this.style_options[ri].key
                this.selected_steps = 30
                this.selected_cfg_scale = 7
            }
            if (model_id === "stabilityai/sd3") {
                this.selected_size = "832x1216"
            }
            if (model_id === "stabilityai/stable-diffusion-xl-base-1.0") {
                this.selected_size = "832x1216"
                this.selected_steps = 30
                this.selected_cfg_scale = 7
                this.checkpoint_id = 0
                this.version_id = 0
                this.is_file = false
            }
            if (model_id === "runwayml/stable-diffusion-v1-5") {
                this.selected_size = "512x768"
                this.selected_steps = 30
                this.selected_cfg_scale = 7
                this.selected_checkpoint_id = 0
                this.selected_version_id = 0
                this.is_file = false
            }
        })
        if (model_id === "runwayml/stable-diffusion-v1-5" || model_id === "stabilityai/stable-diffusion-xl-base-1.0") {
            await this.load_checkpoint_options(model_id)
        }
    }

    async onChangeVersion(version_id: number) {
        runInAction(() => {
            this.selected_version_id = version_id
        })
        await this.load_file(this.selected_checkpoint_id, version_id)
    }

    async onChangeCheckpoint(checkpoint_id: number) {
        runInAction(() => {
            this.selected_checkpoint_id = checkpoint_id
        })
        await this.load_version_options(this.selected_model_id, checkpoint_id)
    }

    async onClickImage(image: proto.abaoai.ImagePayload) {
        runInAction(() => {
            this.selected_image_id = image.getImageId()
            S.main.right_drawer_is_open = true
            this.right_title = image.getImageId() + '.' + image.getExtension$()
            this.selected_model_id = image.getModelId()
            this.selected_size = image.getSize()
            this.selected_quality = image.getQuality()
            this.selected_style = image.getStyle()
            this.prompt = image.getPrompt()
            this.revised_prompt = image.getRevisedPrompt()
            this.negative_prompt = image.getNegativePrompt()
            this.url = image.getUrl()
            this.selected_sampler = image.getSampler()
            this.selected_checkpoint_id = image.getCheckpointId()
            this.selected_version_id = image.getVersionId()
            this.selected_creator_id = image.getAccountId()
            this.selected_steps = Math.max(10, Math.min(50, image.getSteps()))
            this.selected_cfg_scale = Math.round(Math.min(35, image.getCfgScale()))
        })
        if (image.getModelId() === "runwayml/stable-diffusion-v1-5") {
            await this.load_checkpoint_options(image.getModelId())
            await this.load_version_options(image.getModelId(), image.getCheckpointId())
            await this.load_file(image.getCheckpointId(), image.getVersionId())
            runInAction(() => {
                if (this.selected_size.length === 0) {
                    this.selected_size = "512x768"
                }
            })
        }
        if (image.getModelId() === "stabilityai/stable-diffusion-xl-base-1.0") {
            await this.load_checkpoint_options(image.getModelId())
            await this.load_version_options(image.getModelId(), image.getCheckpointId())
            await this.load_file(image.getCheckpointId(), image.getVersionId())
            runInAction(() => {
                if (this.selected_size.length === 0) {
                    this.selected_size = "832x1216"
                }
            })
        }
    }


    async onClickDownload(url: string) {
        const image = await fetch(url)
        const imageBlog = await image.blob()
        const imageURL = URL.createObjectURL(imageBlog)

        const link = document.createElement('a')
        link.href = imageURL
        let filename: string = url.split('/').pop()!
        link.download = filename
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    async onClickGenerate() {
        try {
            let placeholder: proto.abaoai.ImagePayload = await this.create_image()
            runInAction(() => {
                this.images.unshift(placeholder)
            })
            window.document.getElementById("images_gallery_slider")?.scrollTo({ top: 0, behavior: 'smooth' })
        } catch (e) {
            S.onError(e as grpcWeb.RpcError)
        }
    }

    async load_models() {
        try {
            let req = new proto.abaoai.ModelsPayload()
            let res: proto.abaoai.ModelsPayload = await ACCESS.getImageModels(req, S.getMetadataMap());
            runInAction(() => {
                for (let model of res.getModelsList()) {
                    this.models[model.getModelId()] = model;
                }
            })
        } catch (e) {
            S.onError(e as grpcWeb.RpcError)
        }
    }

    async load_checkpoint_options(base_model_id: string) {
        try {
            let req = new proto.abaoai.GetCheckpointOptionsRequest()
            req.setBaseModelId(base_model_id)
            let res: proto.abaoai.GetCheckpointOptionsResponse = await CHAT.getCheckpointOptions(req, S.getMetadataMap());
            let options = res.getCheckpointOptionsList()
            options.sort((a, b) => a.getCheckpointName() < b.getCheckpointName() ? -1 : 1)
            let option = new proto.abaoai.CheckpointOption()
            option.setCheckpointName("-")
            options.unshift(option)
            runInAction(() => {
                this.checkpoint_options = options
            })
        } catch (e) {
            S.onError(e as grpcWeb.RpcError)
        }
    }

    async load_version_options(base_model_id: string, checkpoint_id: number) {
        try {
            let req = new proto.abaoai.GetCheckpointVersionOptionsRequest()
            req.setBaseModelId(base_model_id)
            req.setCheckpointId(checkpoint_id)
            let res: proto.abaoai.GetCheckpointVersionOptionsResponse = await CHAT.getCheckpointVersionOptions(req, S.getMetadataMap());
            let options = res.getVersionOptionsList()
            let option = new proto.abaoai.CheckpointVersionOption()
            option.setVersionName("-")
            options.unshift(option)
            runInAction(() => {
                this.version_options = options
            })
        } catch (e) {
            S.onError(e as grpcWeb.RpcError)
        }
    }

    async load_file(checkpoint_id: number, version_id: number) {
        let req = new proto.abaoai.GetCheckpointVersionFileRequest()
        req.setCheckpointId(checkpoint_id)
        req.setVersionId(version_id)
        let res: proto.abaoai.GetCheckpointVersionFileResponse = await CHAT.getCheckpointVersionFile(req, S.getMetadataMap());
        runInAction(() => {
            this.is_file = res.getIsFile()
        })
    }

    async fetchMoreImages(empty: boolean) {
        if (this.is_civitai) {
            this.search_more_images(empty)
        } else {
            this.list_more_images(empty)
        }
    }

    async list_more_images(empty: boolean) {
        try {
            if (empty) {
                runInAction(() => {
                    this.start = 0
                    this.total = 0
                    this.images = []
                })
                window.document.getElementById("images_gallery_slider")?.scrollTo({ top: 0, behavior: 'smooth' })
            }
            let req = new proto.abaoai.ListImagesRequest()
            req.setAccountId(this.selected_creator_id)
            req.setStart(this.start)
            console.log(this.start)
            let res: proto.abaoai.ListImagesResponse = await CHAT.listImages(req, S.getMetadataMap())
            runInAction(() => {
                this.start = res.getNextStart()
                this.total = res.getTotal()
                this.title = this.selected_creator.getName()
                for (let image of res.getImagesList()) {
                    this.images.push(image)
                }
                // for (let creator of res.getCreatorsList()) {
                //     this.creators[creator.getAccountId()] = creator
                // }
            })
        } catch (e) {
            S.onError(e as grpcWeb.RpcError)
        }
    }

    async search_more_images(empty: boolean) {
        try {
            if (empty) {
                runInAction(() => {
                    this.selected_creator_id = 0
                    this.start = 0
                    this.total = 0
                    this.images = []
                })
                window.document.getElementById("images_gallery_slider")?.scrollTo({ top: 0, behavior: 'smooth' })
            }
            let req = new proto.abaoai.SearchCheckpointImagesRequest()
            req.setBaseModelId(this.selected_model_id)
            req.setCheckpointId(this.checkpoint_id)
            req.setVersionId(this.version_id)
            req.setNsfw(S.main.nsfw)
            req.setStart(this.start)
            console.log(req.toObject())
            let res: proto.abaoai.SearchCheckpointImagesResponse = await CHAT.searchCheckpointImages(req, S.getMetadataMap())
            runInAction(() => {
                this.start = res.getNextStart()
                this.total = res.getTotal()
                for (let image of res.getImagesList()) {
                    this.images.push(image)
                }
            })
        } catch (e) {
            S.onError(e as grpcWeb.RpcError)
        }
    }

    async create_image() {
        let req = new proto.abaoai.ImagePayload()
        req.setAccountId(S.main.account_id)
        req.setModelId(this.selected_model_id)
        if (this.selected_model_id === "openai/dall-e-3") {
            req.setQuality(this.selected_quality)
            req.setSize(this.selected_size)
            req.setStyle(this.selected_style)
            req.setPrompt(this.prompt)
        }
        if (this.selected_model_id === "stabilityai/stable-diffusion-xl-1024-v1-0") {
            req.setSize(this.selected_size)
            req.setStyle(this.selected_style)
            req.setPrompt(this.prompt)
            req.setNegativePrompt(this.negative_prompt)
            req.setSteps(this.selected_steps)
            req.setCfgScale(this.selected_cfg_scale)
        }
        if (this.selected_model_id === "stabilityai/sd3") {
            req.setSize(this.selected_size)
            req.setPrompt(this.prompt)
            req.setNegativePrompt(this.negative_prompt)
        }
        if (this.selected_model_id === "stabilityai/stable-diffusion-xl-base-1.0") {
            req.setCheckpointId(this.selected_checkpoint_id)
            req.setVersionId(this.selected_version_id)
            req.setSize(this.selected_size)
            req.setPrompt(this.prompt)
            req.setNegativePrompt(this.negative_prompt)
            req.setSampler(this.selected_sampler)
            req.setSteps(this.selected_steps)
            req.setCfgScale(this.selected_cfg_scale)
            req.setSeed(getRandom())
        }
        console.log(req.toObject())
        let image: proto.abaoai.ImagePayload = await CHAT.createImage(req, S.getMetadataMap())
        console.log(image.toObject())
        return image;
    }

    async listen_images(account_id: number) {
        while (true) {
            try {
                let req = new proto.abaoai.ImagePayload()
                req.setAccountId(account_id)
                let image: proto.abaoai.ImagePayload = await CHAT.listenImage(req, S.getMetadataMap())
                console.log(image.toObject())
                let image_id = image.getImageId()
                runInAction(() => {
                    let i = this.images.findIndex((image) => image.getImageId() === image_id)
                    this.images[i] = image
                })
            } catch (e) {
                console.log(e)
            }
        }
    }

}

export { ImageState }