import React from "react"
import ReactDOM from "react-dom/client"
import { App } from "./view/App"
import { FluentProvider, teamsDarkTheme } from '@fluentui/react-components';

import { S } from "./State"

import "./css/index.scss"
import "react-image-gallery/styles/css/image-gallery.css";
import 'simplebar-react/dist/simplebar.min.css';

import { runInAction } from "mobx"

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "stripe-pricing-table": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
            "stripe-buy-button": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
)


root.render(
    <React.StrictMode>
        <FluentProvider theme={teamsDarkTheme}>
            <div className="app">
                <App />
            </div>
        </FluentProvider>
    </React.StrictMode>
)

S.onPageLoad()

window.addEventListener("resize", resize)

function resize() {
    let is_mobile = window.matchMedia("(max-width: 600px)").matches;
    runInAction(() => {
        S.is_mobile = is_mobile
    })
}

resize()
