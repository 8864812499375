import { computed, makeObservable, observable, runInAction } from "mobx"
import { ACCESS } from "../Client"
import { S } from "../State"
import grpcWeb from "grpc-web"
import { COUNTRY_CODES } from "../data/CountryCodes"

class SignupState {
    name: string = ""

    email: string = ""

    password: string = ""

    code: string = ""

    country: string = ""

    email_countdown: number = 0

    country_options = [{ code: "", name: "-" }]

    constructor() {
        for (let item of COUNTRY_CODES) {
            this.country_options.push(item)
        }
        makeObservable(this, {
            name_error_message: computed,
            email_error_message: computed,
            password_error_message: computed,
            code_error_message: computed,
            country_error_message: computed,
            email_countdown: observable,
        })
    }

    get name_error_message() {
        if (S.error_field === "name") {
            return S.error_message
        }
        return ""
    }


    get email_error_message() {
        if (S.error_field === "email") {
            return S.error_message
        }
        return ""
    }

    get password_error_message() {
        if (S.error_field === "password") {
            return S.error_message
        }
        return ""
    }

    get code_error_message() {
        if (S.error_field === "code") {
            return S.error_message
        }
        return ""
    }

    get country_error_message() {
        if (S.error_field === "country") {
            return S.error_message
        }
        return ""
    }

    async onClickSendCode() {
        S.clearError()
        try {
            let req = new proto.abaoai.SendCodeRequest()
            req.setUsage(1)
            req.setName(this.name)
            req.setEmail(this.email.toLowerCase())
            let res: proto.abaoai.SendCodeResponse = await ACCESS.sendCode(req)
            console.log(res.getTimestamp())
            this.start_email_countdown()
        } catch (e) {
            S.onError(e as grpcWeb.RpcError)
        }
    }


    async onClickContinue() {
        S.clearError()
        try {
            let req = new proto.abaoai.SignupRequest()
            req.setName(this.name)
            req.setEmail(this.email.toLowerCase())
            req.setPassword(this.password)
            req.setCode(this.code)
            req.setCountry(this.country)
            console.log(req.toObject())
            let res: proto.abaoai.SignupResponse = await ACCESS.signup(req)
            console.log(res.getAccountId())
            runInAction(() => {
                S.page = "LOGIN"
            })
        } catch (e) {
            S.onError(e as grpcWeb.RpcError)
        }
    }

    onChangeName(value: string) {
        this.name = value
    }

    onChangeEmail(value: string) {
        this.email = value
    }

    onChangePassword(value: string) {
        this.password = value
    }

    onChangeCode(value: string) {
        this.code = value
    }

    onChangeCountry(value: string) {
        this.country = value
    }


    start_email_countdown() {
        if (this.email_countdown !== 0) {
            return
        }
        runInAction(() => {
            this.email_countdown = 60
        })
        let email_interval = setInterval(() => {

            runInAction(() => {
                this.email_countdown -= 1
            })
            if (this.email_countdown === 0) {
                clearInterval(email_interval)
            }
        }, 1000)
    }

}

export { SignupState }
