import { observer } from "mobx-react-lite"
import { S } from "../../State"
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Button,
} from "@fluentui/react-components";


const DeleteChatDialog = observer(() =>
    <Dialog
        open={S.chat.delete_dialog_is_open}
        onOpenChange={() => S.chat.onDismissDeleteDialog()}
    >
        <DialogSurface>
            <DialogBody>
                <DialogTitle>{"Delete Chat #" + S.main.chat_id + "?"}</DialogTitle>
                <DialogContent>
                    {"This will delete " + S.chat.delete_dialog_summary}
                </DialogContent>
                <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary">Cancel</Button>
                    </DialogTrigger>
                    <Button appearance="primary" onClick={() => S.main.onClickDeleteConfirm()}>
                        Confirm
                    </Button>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog>
)


export { DeleteChatDialog }