import { observer } from "mobx-react-lite"
import { action } from "mobx"
import { S } from "../State"
import { Button } from "@fluentui/react-components"

const AuthPage = observer(() =>
    <div className="auth_page">
        <div className="hero">
            <h1 className="title">abao.ai</h1>
            <h3 className="title">Chat with Transformers</h3>
            <ul>
                <li><span className="model">GPT-4</span> / <span className="model">GPT-3</span> by OpenAI</li>
                <li><span className="model">Gemini-Pro</span> by Google</li>
                <li><span className="model">Claude-3</span> by Anthropic</li>
            </ul>
            <h3 className="title">Generate with Diffusers</h3>
            <ul>
                <li><span className="model">Dall-E-3</span> by OpenAI</li>
                <li><span className="model">SD-3 / SDXL</span> by StabilityAI</li>
                <li><span className="model">SDXL / SD-1.5</span> by CivitAI</li>
            </ul>
            <div className="buttons">
                <Button
                    appearance="primary"
                    className="login_button"
                    onClick={action(() => S.onClickPage("LOGIN"))}
                >
                    Log in
                </Button>
                <Button
                    appearance="secondary"
                    className="signup_button"
                    onClick={action(() => S.onClickPage("SIGNUP"))}
                >
                    Sign up
                </Button>
            </div>
        </div>
    </div >
)

export { AuthPage }
